import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useTranslation} from 'react-i18next';

import cn from 'classnames';

import {TradingPosition, TradingPositionState} from '../../../../gateways/RfpGateway/rfp.types';

import {default as AppContext} from '../../../../contexts/AppContext';
import {default as DashboardContext} from '../../../../contexts/DashboardContext';

import {default as usePromiseFactory} from '../../../../utils/hooks/usePromiseFactory';
import {default as useForceRerender} from '../../../../utils/hooks/useForceRerender';
import {default as useObservable} from '../../../../utils/hooks/useObservable';
import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import darkLoader from '../../../../images/loader_dark.svg';
import lightLoader from '../../../../images/loader_light.svg';

import useSubscribeTradeEvent from '../../../../utils/hooks/useSubscribeTradeEvent';

import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';

import ClosedGrid from './ClosedGrid/ClosedGrid';
import styles from './PositionsPanel.module.scss';
import OrdersGrid from './OrdersGrid/OrdersGrid';
import PositionGrid from './PositionGrid/PositionGrid';

type TGridLengths = {
    open: number;
    pending: number;
};

type TCloseDateOptions = {
    text: string;
    value: string;
};

const PositionsPanel = () => {
    const appContext = useContext(AppContext);
    const dashboardContext = useContext(DashboardContext);
    const promiseFactory = usePromiseFactory();
    const forceRerender = useForceRerender();
    const {t} = useTranslation();
    const tt = useShortTranslation('wtr:');

    const isLoggedIn = appContext.isLoggedIn;

    const tradingPositions = dashboardContext.getTradingPositions();
    const closeTrade = dashboardContext.closeTrade;
    const closeLength = dashboardContext.closeLength;
    const tradingAccount = dashboardContext.tradingAccount;
    const activeTradingAccount = useSelectedTradingAccount();

    const theme = appContext.appTheme;

    useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

    // useObservable(dashboardContext.getPropertyChangeStream('tradingPositionsLength'), () => forceRerender());

    useObservable(
        dashboardContext.getPropertyChangeStream(
            'tradingPositions',
            'orderConfirm',
            'positionProfitLosses',
            'closeLength',
            'closeTrade',
            'tradingAccount',
            'applicationStatus',
            'accountValues'
        ),
        async () => {
            await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
            forceRerender();
        }
    );

    const [showPositions, setShowPositions] = useState<boolean>(false);
    const [positionTab, setPositionTab] = useState<number>(0);
    const [gridLengths, setGridLengths] = useState<TGridLengths>({open: 0, pending: 0});

    const closeDateOptions: TCloseDateOptions[] = [
        {text: t('en:TODAY'), value: 'Today'},
        {text: tt('1_WEEK_TP'), value: '1 Week'},
        {text: tt('1_MONTH_TP'), value: '1 Month'},
        {text: tt('3_MONTHS'), value: '3 Months'},
        {text: tt('YTD'), value: 'YTD'},
        {text: tt('1_YEAR'), value: '1 Year'},
        {text: t('en:All'), value: 'All'},
    ];

    // FOR DELETEION HE he
    useEffect(() => {
        dashboardContext.gridButton = '';
    }, [positionTab]);

    useEffect(() => {
        if (closeTrade === 'Today') {
            const todayDate = moment().format('D/MMM/YYYY');
            const todaysPositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    moment(position.cT).format('D/MMM/YYYY') === todayDate && position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = todaysPositions.length;
        } else if (closeTrade === '1 Week') {
            const todayDate = moment();
            const lastWeek = moment().subtract(1, 'week');
            const weeklyPositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    moment(position.cT).isBetween(lastWeek, todayDate, undefined, '[]') &&
                    position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = weeklyPositions.length;
        } else if (closeTrade === '1 Month') {
            const todayDate = moment();
            const lastMonth = moment().subtract(1, 'month');
            const monthlyPositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    moment(position.cT).isBetween(lastMonth, todayDate, undefined, '[]') &&
                    position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = monthlyPositions.length;
        } else if (closeTrade === '3 Months') {
            const todayDate = moment();
            const lastThreeMonths = moment().subtract(3, 'months');
            const lastThreeMonthPositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    moment(position.cT).isBetween(lastThreeMonths, todayDate, undefined, '[]') &&
                    position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = lastThreeMonthPositions.length;
        } else if (closeTrade === '1 Year') {
            const todayDate = moment();
            const year = moment().subtract(1, 'year');
            const lastYearPositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    moment(position.cT).isBetween(year, todayDate, undefined, '[]') && position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = lastYearPositions.length;
        } else if (closeTrade === 'YTD') {
            const todayDate = moment();
            const ytd = moment().startOf('year');
            const yearToDatePositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    moment(position.cT).isBetween(ytd, todayDate, undefined, '[]') && position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = yearToDatePositions.length;
        } else if (closeTrade === 'All') {
            const allPositions = tradingPositions.filter(
                (position: TradingPosition) =>
                    position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id
            );
            dashboardContext.closeLength = allPositions.length;
        }
    }, [tradingPositions, closeTrade, activeTradingAccount]);

    useEffect(() => {
        if (!showPositions && tradingPositions.length > 0) {
            setShowPositions(true);
            dashboardContext.ticketLoad = true;
        }
    }, [tradingPositions.length]);

    /**
     * Currently, there is no option to find out when there is no open position,
     * that's why will present loading indicator for only 4 seconds
     */
    useEffect(() => {
        if (isLoggedIn && !showPositions) {
            setTimeout(() => {
                if (!showPositions) {
                    setShowPositions(true);
                    dashboardContext.ticketLoad = true;
                }
            }, 4000);
        }
    }, [tradingAccount, isLoggedIn]);

    useSubscribeTradeEvent();

    const handleCloseFilter = (_: any, {value}: any) => {
        dashboardContext.closeTrade = value;
    };

    useEffect(() => {
        const openLength = tradingPositions.filter(
            (position: TradingPosition) =>
                position.state === TradingPositionState.open && position.aId === activeTradingAccount?.id
        ).length;
        const pendingLength = tradingPositions.filter(
            (position: TradingPosition) =>
                position.state === TradingPositionState.pending && position.aId === activeTradingAccount?.id
        ).length;
        setGridLengths({open: openLength, pending: pendingLength});
    }, [activeTradingAccount, tradingPositions]);

    const closedPositionsPeriodDropdown = useRef<any>(null);

    const [searchTerm, setSearchTerm] = useState('');

    const setTab = (tabIndex: number) => {
        setPositionTab(tabIndex);
        setSearchTerm('');
    };

    return (
        <div className={styles.gridLayout}>
            <div className={styles.header}>
                <div className={styles.tabs}>
                    <div
                        className={`${styles.positionTab} ${positionTab === 0 && styles.active}`}
                        onClick={() => setTab(0)}
                    >
                        <span className={styles.reserveSpaceHelper}>
                            {`${tt('POSITIONS')} (${gridLengths.open})`}
                        </span>
                        <span className={styles.tabLabel}>{`${tt('POSITIONS')} (${gridLengths.open})`}</span>
                    </div>
                    <div
                        className={`${styles.positionTab} ${positionTab === 1 && styles.active}`}
                        onClick={() => setTab(1)}
                    >
                        <span className={styles.reserveSpaceHelper}>
                            {`${tt('ORDERS_TAB_TITLE')} (${gridLengths.pending})`}
                        </span>
                        <span className={styles.tabLabel}>
                            {`${tt('ORDERS_TAB_TITLE')} (${gridLengths.pending})`}
                        </span>
                    </div>
                    <div
                        className={`${styles.positionTab} ${positionTab === 2 && styles.active}`}
                        onClick={() => setTab(2)}
                    >
                        <span className={styles.reserveSpaceHelper}>
                            {`${tt('CLOSED_TAB_TITLE')} (${closeLength})`}
                        </span>
                        <span className={styles.tabLabel}>{`${tt('CLOSED_TAB_TITLE')} (${closeLength})`}</span>
                    </div>
                </div>
                <div className={styles.tools}>
                    {positionTab === 2 && (
                        <Dropdown
                            ref={closedPositionsPeriodDropdown}
                            selection
                            options={closeDateOptions}
                            onChange={handleCloseFilter}
                            value={closeTrade}
                            className={styles.closeDropdown}
                        />
                    )}
                    <div className={cn(styles.searchBox, searchTerm.length > 0 && styles.notEmpty)}>
                        <FontAwesomeIcon className={styles.searchIcon} icon={['fas', 'search']}/>
                        <input
                            placeholder={t('wtr:SEARCH_FOR_POSITION')}
                            onChange={({target}) => setSearchTerm(target.value)}
                            value={searchTerm}
                            width={200}
                        />
                        <FontAwesomeIcon
                            className={styles.clearSearchIcon}
                            icon={['fas', 'times-circle']}
                            onClick={() => setSearchTerm('')}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                {positionTab === 0 &&
                    (showPositions ? (
                        <PositionGrid searchTerm={searchTerm} setSearchTerm={setSearchTerm} key={gridLengths.open}/>
                    ) : (
                        <div className={styles.loadingIcon}>
                            <img
                                className={styles.loadingImage}
                                src={theme === 'dark' ? lightLoader : darkLoader}
                                alt="loader"
                            ></img>
                            <div className={styles.loadingText}>{t('en:LOADING')}</div>
                        </div>
                    ))}
                {positionTab === 1 &&
                    (showPositions ? (
                        <OrdersGrid searchTerm={searchTerm} setSearchTerm={setSearchTerm} key={gridLengths.pending}/>
                    ) : (
                        <div className={styles.loadingIcon}>
                            <img
                                className={styles.loadingImage}
                                src={theme === 'dark' ? lightLoader : darkLoader}
                                alt="loader"
                            ></img>
                            <div className={styles.loadingText}>{t('en:LOADING')}</div>
                        </div>
                    ))}
                {positionTab === 2 &&
                    (showPositions ? (
                        <ClosedGrid
                            periodDropdownRef={closedPositionsPeriodDropdown}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            key={closeLength}
                        />
                    ) : (
                        <div className={styles.loadingIcon}>
                            <img
                                className={styles.loadingImage}
                                src={theme === 'dark' ? lightLoader : darkLoader}
                                alt="loader"
                            ></img>
                            <div className={styles.loadingText}>{t('en:LOADING')}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default React.memo(PositionsPanel);
