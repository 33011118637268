import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';

import authStore from '../../store/authStore';
import { getAnyLiveUserAccount } from '../functions/subscriptionUtils';

import useSelectedTradingAccount from './useSelectedTradingAccount';
// >>>>>>> origin/development

const useJPAcctIdForTierInfoRequest = (): string | null | undefined => {
	const appContext = useContext(AppContext);

	const selectedTradingAccount = useSelectedTradingAccount();
	const isDemo = authStore.use.isDemoMode();

	if (appContext.isJapanAccount && isDemo) {
		return getAnyLiveUserAccount(appContext);
	}

	return selectedTradingAccount?.accountNumber?.toString();
};
export default useJPAcctIdForTierInfoRequest;
