import React, { useContext, useMemo } from 'react';

import AppContext from '../../../contexts/AppContext';

import tradingAccountStore from '../../../store/tradingAccountStore';

import { tierIcons } from '../../../setup/subscriptionsConfig';

import { IGridRecord } from '../../features/Markets/MarketsGrid';
import { MarketItem } from '../../../gateways/RfpGateway/rfp.types';

interface InstrumentIcon {
  marketItem: IGridRecord | MarketItem;
}

const images: {
  [key: string]: {
    default: string,
    [key: string]: string
  }
} = {
  unknown: {
    default: '/images/unknown.svg'
  },
  etf: {
    default: '/images/etf.svg'
  },
  commodities: {
    default: '/images/commodities.svg',
    CORN: '/images/commodities/CORN.svg',
    SOYBEAN: '/images/commodities/SOYBEAN.svg',
    WHEAT: '/images/commodities/WHEAT.svg'
  },
  crypto: {
    default: '/images/crypto.svg',
    ADAUSD: '/images/crypto/ADAUSD.svg',
    ALGOUSD: '/images/crypto/ALGOUSD.svg',
    ATOMUSD: '/images/crypto/ATOMUSD.svg',
    AVAXUSD: '/images/crypto/AVAXUSD.svg',
    BATUSD: '/images/crypto/BATUSD.svg',
    BCHUSD: '/images/crypto/BCHUSD.svg',
    BNBUSD: '/images/crypto/BNBUSD.svg',
    BTCUSD: '/images/crypto/BTCUSD.svg',
    DASHUSD: '/images/crypto/DASHUSD.svg',
    DOGEUSD: '/images/crypto/DOGEUSD.svg',
    DOTUSD: '/images/crypto/DOTUSD.svg',
    EOSUSD: '/images/crypto/EOSUSD.svg',
    ETHUSD: '/images/crypto/ETHUSD.svg',
    LINKUSD: '/images/crypto/LINKUSD.svg',
    LTCUSD: '/images/crypto/LTCUSD.svg',
    MANAUSD: '/images/crypto/MANAUSD.svg',
    MATICUSD: '/images/crypto/MATICUSD.svg',
    OMGUSD: '/images/crypto/OMGUSD.svg',
    SANDUSD: '/images/crypto/SANDUSD.svg',
    SHIBUSD: '/images/crypto/SHIBUSD.svg',
    SOLUSD: '/images/crypto/SOLUSD.svg',
    UNIUSD: '/images/crypto/UNIUSD.svg',
    USDC: '/images/crypto/USDC.svg',
    USDT: '/images/crypto/USDT.svg',
    XLMUSD: '/images/crypto/XLMUSD.svg',
    XMRUSD: '/images/crypto/XMRUSD.svg',
    XRPUSD: '/images/crypto/XRPUSD.svg',
    XTZUSD: '/images/crypto/XTZUSD.svg',
    ZECUSD: '/images/crypto/ZECUSD.svg'
  },
  energy: {
    default: '/images/energy.svg',
    BRENT: '/images/energy/BRENT.svg',
    NGAS: '/images/energy/NGAS.svg',
    WTI: '/images/energy/WTI.svg'
  },
  equities: {
    default: '/images/equities.svg',
    AAPL: '/images/equities/AAPL.svg',
    ABNB: '/images/equities/ABNB.svg',
    AMZN: '/images/equities/AMZN.svg',
    BABA: '/images/equities/BABA.svg',
    GOOG: '/images/equities/GOOG.svg',
    GS: '/images/equities/GS.svg',
    META: '/images/equities/META.svg',
    MSFT: '/images/equities/MSFT.svg',
    TSLA: '/images/equities/TSLA.svg',
    UBER: '/images/equities/UBER.svg',
    V: '/images/equities/V.svg'
  },
  forex: {
    default: '/images/forex.svg',
    AUDCAD: '/images/forex/AUDCAD.svg',
    AUDCHF: '/images/forex/AUDCHF.svg',
    AUDJPY: '/images/forex/AUDJPY.svg',
    AUDNZD: '/images/forex/AUDNZD.svg',
    AUDUSD: '/images/forex/AUDUSD.svg',
    CADCHF: '/images/forex/CADCHF.svg',
    CADJPY: '/images/forex/CADJPY.svg',
    CHFJPY: '/images/forex/CHFJPY.svg',
    EURAUD: '/images/forex/EURAUD.svg',
    EURCAD: '/images/forex/EURCAD.svg',
    EURCHF: '/images/forex/EURCHF.svg',
    EURCZK: '/images/forex/EURCZK.svg',
    EURGBP: '/images/forex/EURGBP.svg',
    EURHUF: '/images/forex/EURHUF.svg',
    EURJPY: '/images/forex/EURJPY.svg',
    EURNOK: '/images/forex/EURNOK.svg',
    EURNZD: '/images/forex/EURNZD.svg',
    EURPLN: '/images/forex/EURPLN.svg',
    EURSEK: '/images/forex/EURSEK.svg',
    EURSGD: '/images/forex/EURSGD.svg',
    EURTRY: '/images/forex/EURTRY.svg',
    EURUSD: '/images/forex/EURUSD.svg',
    EURZAR: '/images/forex/EURZAR.svg',
    GBPAUD: '/images/forex/GBPAUD.svg',
    GBPCAD: '/images/forex/GBPCAD.svg',
    GBPCHF: '/images/forex/GBPCHF.svg',
    GBPHUF: '/images/forex/GBPHUF.svg',
    GBPJPY: '/images/forex/GBPJPY.svg',
    GBPNZD: '/images/forex/GBPNZD.svg',
    GBPUSD: '/images/forex/GBPUSD.svg',
    GBPZAR: '/images/forex/GBPZAR.svg',
    NZDCAD: '/images/forex/NZDCAD.svg',
    NZDCHF: '/images/forex/NZDCHF.svg',
    NZDJPY: '/images/forex/NZDJPY.svg',
    NZDUSD: '/images/forex/NZDUSD.svg',
    USDBRL: '/images/forex/USDBRL.svg',
    USDCAD: '/images/forex/USDCAD.svg',
    USDCHF: '/images/forex/USDCHF.svg',
    USDCNH: '/images/forex/USDCNH.svg',
    USDHKD: '/images/forex/USDHKD.svg',
    USDHUF: '/images/forex/USDHUF.svg',
    USDJPY: '/images/forex/USDJPY.svg',
    USDMXN: '/images/forex/USDMXN.svg',
    USDNOK: '/images/forex/USDNOK.svg',
    USDSEK: '/images/forex/USDSEK.svg',
    USDSGD: '/images/forex/USDSGD.svg',
    USDTRY: '/images/forex/USDTRY.svg',
    USDZAR: '/images/forex/USDZAR.svg'
  },
  indices: {
    default: '/images/indices.svg',
    AUS200: '/images/indices/AUS200.svg',
    CHINA50: '/images/indices/CHINA50.svg',
    ESH24: '/images/indices/ESH24.svg',
    ESTX50: '/images/indices/ESTX50.svg',
    FDXH4: '/images/indices/FDXH4.svg',
    FFIH4: '/images/indices/FFIH4.svg',
    FRA40: '/images/indices/FRA40.svg',
    GER40: '/images/indices/GER40.svg',
    HK50: '/images/indices/HK50.svg',
    JPN225: '/images/indices/JPN225.svg',
    NAS100: '/images/indices/NAS100.svg',
    NQH24: '/images/indices/NQH24.svg',
    SPAIN35: '/images/indices/SPAIN35.svg',
    SPX500: '/images/indices/SPX500.svg',
    TAIEX: '/images/indices/TAIEX.svg',
    UK100: '/images/indices/UK100.svg',
    US30: '/images/indices/US30.svg',
    US2000: '/images/indices/US2000.svg',
    USDINDEX: '/images/indices/USDINDEX.svg',
    VIX: '/images/indices/VIX.svg',
    YMH24: '/images/indices/YMH24.svg'
  },
  metals: {
    default: '/images/metals.svg',
    COOPER: '/images/metals/COOPER.svg',
    XAGUSD: '/images/metals/XAGUSD.svg',
    XAUEUR: '/images/metals/XAUEUR.svg',
    XAUUSD: '/images/metals/XAUUSD.svg',
    XPTUSD: '/images/metals/XPTUSD.svg'
  }
};

const MarketInstrumentIcon = ({ marketItem }: InstrumentIcon) => {
  const { isJapanAccount } = useContext(AppContext);

  let group = (isJapanAccount ? marketItem.minTier : marketItem.grp) || marketItem.category;
  let name = marketItem.displayName || marketItem.code;
  const isSpreadBettingAcc = tradingAccountStore.use.isSpreadBetting();

  if (isSpreadBettingAcc) {
    if (name.endsWith('_SB')) {
      name = name.replace('_SB', '');
    }
    if (group && typeof group === 'string' && group.endsWith('SB')) {
      group = group.replace('SB', '');
    }
  }

  return useMemo(() => {
    if (isJapanAccount) {
      return (
        <img
          src={tierIcons[`tier${group}`]}
          alt="badge"
        />
      );
    }

    const groupLowerCased = String(group).toLowerCase();

    if (images[groupLowerCased] && Object.keys(images[groupLowerCased]).includes(name)) {
      return (
        <img
          src={images[String(group).toLowerCase()][name]}
          alt="badge"
        />
      );
    }

    if (!images[groupLowerCased]) {
      return (
        <img
          src={images.unknown.default}
          alt="badge"
        />
      );
    }

    return (
      <img
        src={images[groupLowerCased].default}
        alt="badge"
      />
    );
  }, [group, name, isJapanAccount]);
};

export default MarketInstrumentIcon;
