import React, { useContext, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { tierIcons } from '../../../../../setup/subscriptionsConfig';

import { getActiveTier } from '../../../../../utils/functions/subscriptionUtils';

import authStore from '../../../../../store/authStore';

import AppContext from '../../../../../contexts/AppContext';

import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../../utils/hooks/useObservable';

import styles from './AccountCurrency.module.scss';

interface AccountIconAndTierProps {
	accountPopupIsOpen?: boolean;
	size?: 'small' | 'large';
	outline?: boolean;
}

const AccountIconAndTier = ({ accountPopupIsOpen, size = 'small', outline = false }: AccountIconAndTierProps) => {
	const isLiveMode = authStore.use.isLiveMode();
	const isJapanAccount = authStore.use.isJapanAccount();
	const appContext = useContext(AppContext);
	const activeTier = isJapanAccount && isLiveMode && getActiveTier(appContext.subscriptionInfo);

	const forceRerender = useForceRerender();
	const iconStyle = accountPopupIsOpen ? 'fas' : 'fal';

	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), () => {
		forceRerender();
	});

	const icon = useMemo(
		() => (
			<>
				{!activeTier && <FontAwesomeIcon className={styles[size]} icon={[iconStyle, 'user']} />}
				{activeTier && <img src={tierIcons[activeTier.toLowerCase()]} alt="Tier" className={styles[size]} />}
			</>
		),
		[activeTier, iconStyle]
	);

	if (!outline) return icon;

	return (
		<div className={styles.imageWrapper}>
			<div className={cn(styles.imageOutline, styles.outline)}>{icon}</div>
		</div>
	);
};

export default AccountIconAndTier;
