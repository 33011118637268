import { Instrument, MarketItem, PriceQuote, TradingPosition } from '../../gateways/RfpGateway/rfp.types';

import { TradingPositionLimitType, TradingPositionSide, TradingPositionType } from '../../utils/functions/enums';

export interface IPriceInfo {
	price?: number;
	isActive?: boolean;
	potentialProfitLoss?: string;
	error?: string;
	isTriggered?: boolean;
}

export interface IAmountInfo {
	amount?: number;
	min?: number;
	max?: number;
	step?: number;
	rndLot?: number; // TODO:use tradingInstrument
	error?: string;
}

export interface ITradeObjProps {
	stopLevel: number | null;
	limitLevel: number | null;
	reqId: number;
	acctId: number;
}

export enum ActionType {
	unknown = 'UNKNOWN',
	confirm = 'CONFIRM',
	cancel = 'CANCEL',
	close = 'CLOSE',
}

export interface Order {
	accountId?: number;
	side?: TradingPositionSide;
	type?: TradingPositionType;
	isPreservedValue?: boolean;

	// Modify only Take Profit / Stop Loss prices
	limitsOnly?: TradingPositionLimitType[];
	date?: any;

	// TODO: - Remove quantity and replace with amountInfo
	quantity?: number;

	amountInfo?: IAmountInfo;
	openPriceInfo?: IPriceInfo;
	stopLossInfo?: IPriceInfo;
	takeProfitInfo?: IPriceInfo;
	isPositionDraggingDisabled?: boolean;
}

export interface OrderStoreActions {
	setAction: (action: ActionType) => void;
	setTradingPosition: (position: TradingPosition | undefined) => void;
	setTradingInstrument: (instrument: Instrument | undefined) => void;
	setCurrentQuote: (priceQuote: PriceQuote | undefined) => void;
	setMarketItem: (marketItem: MarketItem | undefined) => void;
	setTradeProps: (values: Order) => void;
	setAmountInfo: (values: IAmountInfo) => void;
	setOpenPriceInfo: (values: IPriceInfo) => void;
	setTakeProfitInfo: (values: IPriceInfo) => void;
	setStopLossInfo: (values: IPriceInfo) => void;
	fillStoreFromPosition: (
		position: TradingPosition,
		limitsType?: TradingPositionLimitType[],
		isPositionDraggingDisabled?: boolean,
		pips?: { takeProfitPips: number; stopLossPips: number }
	) => void;
	createTradeRequestObject: () => any;
	setIsSignalOrder: (value: boolean) => any;
	reset: () => void;
	resetExceptSide: () => void;
}

export interface OrderStoreValues {
	current: Order;
	action: ActionType;
	// Read only purposes
	tradingPosition?: TradingPosition;
	tradingInstrument?: Instrument;
	currentQuote?: PriceQuote;
	marketItem?: MarketItem;
	isSignalOrder: boolean;
}

export type OrderStore = OrderStoreValues & OrderStoreActions;
