import React, { useContext } from 'react';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import { default as AppContext } from '../../../contexts/AppContext';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as usePromiseFactory } from '../../../utils/hooks/usePromiseFactory';

import Button from '../../../shared/Button/Button';

import styles from './NotificationModal.module.scss';

export interface NotificationModalConfig {
	backdrop?: 'static';
	onConfirm?: (event: React.MouseEvent) => void;
	id: string;
	message: string;
	showActions?: boolean;
	title: string;
	variant?: 'dialog' | 'error';
}
interface NotifcationModalProps {
	config: NotificationModalConfig;
}

const NotifcationModal: React.FC<NotifcationModalProps> = ({
	config: {
		backdrop,
		onConfirm,
		message = 'Message goes here',
		id,
		showActions = false,
		title = 'Web Trader',
		variant = 'dialog',
	},
}) => {
	const appContext = useContext(AppContext);
	const forceRerender = useForceRerender();
	const promiseFactory = usePromiseFactory();
	const modalId = appContext.modalId;
	const { t } = useTranslation();

	useObservable(appContext.getPropertyChangeStream('appTheme', 'modalId'), (change) => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => forceRerender());
	});

	const handleCloseClick = (isBackdrop: boolean) => {
		if (backdrop === 'static' && isBackdrop) return;
		appContext.modalId = null;
	};

	const handleLogoutClick = (event: React.MouseEvent<Element, MouseEvent>) => {
		if (onConfirm) {
			onConfirm(event);
		}
		appContext.modalId = null;
	};

	const _headerClassName = cn(styles.header, styles.dialog, styles[variant]);

	return (
		<>
			{modalId === id && (
				<div id={id} className={styles.container}>
					<div className={styles.backdrop} onClick={() => handleCloseClick(true)}></div>
					<div className={styles.notificationModal}>
						<div>
							<header className={_headerClassName}>
								<FontAwesomeIcon
									icon={['fas', 'times']}
									className={styles.closeIcon}
									onClick={() => handleCloseClick(false)}
								/>
							</header>

							<div className={styles.content}>{message}</div>
						</div>
						{showActions && (
							<footer className={styles.footer}>
								<Button
									className={styles.button}
									size="lg"
									variant="outline"
									label={t('en:CANCEL')}
									onClick={() => handleCloseClick(false)}
								/>
								<Button className={styles.button} size="lg" label={t('en:YES')} onClick={handleLogoutClick} />
							</footer>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default NotifcationModal;
