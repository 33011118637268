import React, { useState, useMemo, useRef, useCallback, useContext, useEffect } from 'react';

import cn from 'classnames';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import {
	TreeList,
	Column,
	Scrolling,
	Selection,
	SearchPanel,
	StateStoring,
	LoadPanel,
	ColumnChooser,
} from 'devextreme-react/tree-list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { TFunctionResult } from 'i18next';

import { OverlayTrigger } from 'react-bootstrap';

import StringUtils from '../../../../../utils/functions/StringUtils';
import { AppComponentType, QUANTITY_TYPE } from '../../../../../utils/functions/enums';
import {
	getInstrumentDetails,
	convertAmountToLot,
	formatAsMoney,
	getPositionPipsChange,
} from '../../../../../utils/functions/calculations';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as usePromiseFactory } from '../../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import { default as Nullable, Optional } from '../../../../../utils/functions/Nullable';
import {
	formatNumberWithCommas,
	revertToTraditionalNumberFormat,
} from '../../Watchlist/Instrument/formattedQuoteNumber';
import ClosedTicketModal from '../../ChartPanel/NewOrderModals/ClosedTicketModal/ClosedTicketModal';
import {
	TradingPositionState,
	TradingPosition,
	TradingAccount,
	MarketItem,
} from '../../../../../gateways/RfpGateway/rfp.types';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { getGeneralFormatDate } from '../../../../../utils/functions/subscriptionUtils';

import { DX_COLUMN_CHOOSER_SIZE } from '../../../../../setup/config';

import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';

import WtrTooltip from '../../../../../shared/WtrTooltip/WtrTooltip';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';
import MoneyFormatter from '../../../../components/MoneyFormatter';

import styles from './ClosedGrid.module.scss';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import {CALENDAR_MONTHS_SHORT_EN} from "../../../../../utils/functions/constants";

type GridRecord = {
	id: string | number;
	posId?: Optional<number>;
	headID: string | number;
	Instrument: string;
	Side: string;
	SideTranslated: string;
	Type: string;
	Amount: string;
	Lots?: string;
	TakeProfit?: Optional<string | number>;
	StopLoss?: Optional<string | number>;
	'Open @'?: Optional<string | number>;
	'Close @'?: Optional<string | number>;
	'Open Date'?: Optional<string | number>;
	'Open Date Translated'?: Optional<string | number | TFunctionResult>;
	'Closed On'?: Optional<string | number>;
	'Closed On Translated'?: Optional<string | number | TFunctionResult>;
	NetPL?: Optional<string | number | any>;
	GrossPL?: Optional<string | number | any>;
	'Pips Change'?: Optional<string | number>;
	oP?: Optional<number | string>;
	qty?: Optional<number>;
	code?: Optional<string>;
	qCcy?: Optional<string>;
	prc?: Optional<string | number>;
	comm?: Optional<string | number>;
	swap?: Optional<string | number>;
	dividend?: Optional<string | number>;
	comment?: Optional<string | number>;
	cP?: Optional<number | string>;
	isGroupRow?: boolean;
	openTime?: Optional<string>;
	closeTime?: Optional<string>;
};

type ClosedGridProps = {
	periodDropdownRef: any;
	searchTerm: string;
	setSearchTerm: (t: string) => void;
};

const orderIconsSize = '20px';

const ClosedGrid = React.memo(
	({ periodDropdownRef, searchTerm, setSearchTerm }: ClosedGridProps) => {
		const appContext = useContext(AppContext);
		const dashboardContext = useContext(DashboardContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);
		const forceRerender = useForceRerender();
		const promiseFactory = usePromiseFactory();
		const { t } = useTranslation();
		const tt = useShortTranslation('en:');

		const theme = appContext.appTheme;
		const isArabic = appContext.isArabic;
		const languageSettings = appContext.languageSettings;

		const tradingPositions = dashboardContext.getTradingPositions();
		const closeTrade = dashboardContext.closeTrade;
		const quantityType = dashboardContext.quantityType;
		const detailedInformation = dashboardContext.detailedInformation;
		const maximizeGrid = dashboardContext.maximizeGrid;
		const showClosedPositionTicket = dashboardContext.showClosedPositionTicket;
		const activeTradingAccount = useSelectedTradingAccount();
		const tradingAccount = dashboardContext.tradingAccount;
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		useObservable(appContext.getPropertyChangeStream('appTheme'), (change) => forceRerender());

		useObservable(dashboardContext.getPropertyChangeStream('tradingPositionsLength'), () => forceRerender());

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'tradingPositions',
				'marketItems',
				'presentComponentType',
				'closeTrade',
				'quantityType',
				'detailedInformation',
				'showClosedPositionTicket',
				'tradingAccount'
			),
			async (change) => {
				await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
				forceRerender();
			}
		);

		const currentAccount = useSelectedTradingAccount();

		const treeList = useRef<TreeList>(null);
		const [headerSelect, setHeaderSelect] = useState('');
		const [positionData, setPositionData] = useState<any>([]);
		const [emptyState, setEmptyState] = useState(false);
		const [gridLength, setGridLength] = useState(0);
		const [columnsUpdated, setColumnsUpdated] = useState(false);

		const addCommas = (current: number | string) => {
			const decPrec = current.toString().split('.')[1]?.length ?? 0;
			return formatNumberWithCommas(current, decPrec, languageSettings);
		};

		useEffect(() => {
			if (dashboardContext.presentComponentType !== AppComponentType.Watchlist) {
				handleHideColumnChooser();
			}
		}, [dashboardContext.presentComponentType]);

		const gridModel = useMemo(() => {
			//create map of positions indexed by symbol
			let closedPositionsBySymbol: any = [];
			if (closeTrade === 'All') {
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}

			if (closeTrade === 'Today') {
				const todayDate: any = new Date();
				const todayDateFormat = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					const closeDate = new Date(position.cT || 0);
					const closeDateFormat = `${closeDate.getDate()}/${closeDate.getMonth() + 1}/${closeDate.getFullYear()}`;
					if (
						position.state === TradingPositionState.closed &&
						todayDateFormat === closeDateFormat &&
						position.aId === activeTradingAccount?.id
					) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}
			if (closeTrade === '1 Week') {
				const subtractWeek: any = new Date();
				const oneWeekDate: any = subtractWeek.setDate(subtractWeek.getDate() - 7);
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					if (
						position.state === TradingPositionState.closed &&
						position.aId === activeTradingAccount?.id &&
						oneWeekDate <= position.cT!
					) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}
			if (closeTrade === '1 Month') {
				const subtractMonth: any = new Date();
				const oneMonthDate: any = subtractMonth.setMonth(subtractMonth.getMonth() - 1);
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					if (
						position.state === TradingPositionState.closed &&
						position.aId === activeTradingAccount?.id &&
						oneMonthDate <= position.cT!
					) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}
			if (closeTrade === '3 Months') {
				const todayDate: any = Date.now();
				const subtractMonths: any = new Date();
				const threeMonthDate: any = subtractMonths.setMonth(subtractMonths.getMonth() - 3);
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					if (
						position.state === TradingPositionState.closed &&
						position.aId === activeTradingAccount?.id &&
						threeMonthDate <= position.cT! &&
						position.cT! <= todayDate
					) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}
			if (closeTrade === 'YTD') {
				const todayDate: any = Date.now();
				const getYTD: any = new Date(new Date().getFullYear(), 0, 1);
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					if (
						position.state === TradingPositionState.closed &&
						position.aId === activeTradingAccount?.id &&
						getYTD <= position.cT! &&
						position.cT! <= todayDate
					) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}
			if (closeTrade === '1 Year') {
				const todayDate: any = Date.now();
				const subtractYear: any = new Date();
				const yearDate: any = subtractYear.setFullYear(subtractYear.getFullYear() - 1);
				closedPositionsBySymbol = tradingPositions.reduce((map, position) => {
					if (
						position.state === TradingPositionState.closed &&
						position.aId === activeTradingAccount?.id &&
						yearDate <= position.cT! &&
						position.cT! <= todayDate
					) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>());
			}

			//populate grid records with "group" rows
			const gridRecords: GridRecord[] = [];

			const mapClosedData = (position: TradingPosition, positionLength: number, index: number) => {
				if (detailedInformation) {
					const marketItem = rfpGatewayContext?.getMarketItem(position.code, position.f);
					let selectedInstrumentDetails = getInstrumentDetails(
						detailedInformation,
						activeTradingAccount ? [activeTradingAccount] : [],
						marketItem?.code as string
					);
					const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
					const lots = convertAmountToLot(position.qty || 0, instrumentRndLot);
					const codeOrTicker =
						marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
							? marketItem.exchangeTicker
							: position.code;

					const openOnTranslated = appContext.isJapanAccount
						? getGeneralFormatDate(position.oT, false, true)
						: moment(position.oT).format('D/MMM/YYYY').toString().toUpperCase();
					const closedOnTranslated = appContext.isJapanAccount
						? getGeneralFormatDate(position.cT, false, true)
						: moment(position.cT).format('D/MMM/YYYY').toString().toUpperCase();

					gridRecords.push({
						id: positionLength !== 1 ? index : position.code,
						posId: position.posId,
						headID: -1,
						Instrument: codeOrTicker,
						Side: position.side,
						SideTranslated: tt(`${position.side.toUpperCase()}`),
						Amount: addCommas(position.qty ? Math.round(position.qty * 100) / 100 : 0),
						Lots: addCommas(lots ? Math.round(lots * 100) / 100 : 0),
						TakeProfit: position.tp
							? addCommas(parseFloat((position.tp as number)?.toString()).toFixed(marketItem?.decPrec))
							: '',
						StopLoss: position.sl
							? addCommas(parseFloat((position.sl as number)?.toString()).toFixed(marketItem?.decPrec))
							: '',
						'Open @': addCommas(position.oP?.toFixed(marketItem?.decPrec) ?? 0),
						'Close @': addCommas(position.cP?.toFixed(marketItem?.decPrec) ?? 0),
						'Open Date': position.oT,
						'Open Date Translated': openOnTranslated,
						'Closed On': position.cT,
						'Closed On Translated': closedOnTranslated,
						NetPL: position.netProfit,
						GrossPL: position.grossProfit,
						'Pips Change': getPositionPipsChange(position),
						oP: position.oP!.toFixed(marketItem?.decPrec),
						Type: position.type || '',
						qty: position.qty,
						code: position.code,
						qCcy: position.qCcy,
						prc: position.prc,
						comm: position.comm,
						swap: position.swap,
						cP: position.cP!.toFixed(marketItem?.decPrec),
						isGroupRow: positionLength > 1 ? true : false,
						openTime: moment(position.oT).format('HH:mm:ss').toLocaleUpperCase(),
						closeTime: moment(position.cT).format('HH:mm:ss').toLocaleUpperCase(),
						comment: position.comment,
						dividend: position.dividend,
					});
				}
			};

			//populate grid records with individual positions
			if (closeTrade === 'All') {
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						numPositions = numPositions + 1;
						mapClosedData(position, positionLength, index);
					}
				});
			}

			if (closeTrade === 'Today') {
				const todayDate: any = new Date();
				const todayDateFormat = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						const closeDate = new Date(position.cT || 0);
						const closeDateFormat = `${closeDate.getDate()}/${closeDate.getMonth() + 1}/${closeDate.getFullYear()}`;
						if (closeDateFormat === todayDateFormat) {
							numPositions = numPositions + 1;
							mapClosedData(position, positionLength, index);
						}
					}
				});
			}
			if (closeTrade === '1 Week') {
				const todayDate: any = Date.now();
				const subtractWeek: any = new Date();
				const oneWeekDate: any = subtractWeek.setDate(subtractWeek.getDate() - 7);
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						if (oneWeekDate <= position.cT! && position.cT! <= todayDate) {
							numPositions = numPositions + 1;
							mapClosedData(position, positionLength, index);
						}
					}
				});
			}
			if (closeTrade === '1 Month') {
				const todayDate: any = Date.now();
				const subtractMonth: any = new Date();
				const oneMonthDate: any = subtractMonth.setMonth(subtractMonth.getMonth() - 1);
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						if (oneMonthDate <= position.cT! && position.cT! <= todayDate) {
							numPositions = numPositions + 1;
							mapClosedData(position, positionLength, index);
						}
					}
				});
			}
			if (closeTrade === '3 Months') {
				const todayDate: any = Date.now();
				const subtractMonths: any = new Date();
				const threeMonthDate: any = subtractMonths.setMonth(subtractMonths.getMonth() - 3);
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						if (threeMonthDate <= position.cT! && position.cT! <= todayDate) {
							numPositions = numPositions + 1;
							mapClosedData(position, positionLength, index);
						}
					}
				});
			}
			if (closeTrade === 'YTD') {
				const todayDate: any = Date.now();
				const getYTD: any = new Date(new Date().getFullYear(), 0, 1);
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						if (getYTD <= position.cT! && position.cT! <= todayDate) {
							numPositions = numPositions + 1;
							mapClosedData(position, positionLength, index);
						}
					}
				});
			}
			if (closeTrade === '1 Year') {
				const todayDate: any = Date.now();
				const subtractYear: any = new Date();
				const yearDate: any = subtractYear.setFullYear(subtractYear.getFullYear() - 1);
				let numPositions = 0;
				tradingPositions.forEach((position, index) => {
					const positionLength = Nullable.of(closedPositionsBySymbol.get(position.code))
						.map((value) => value.length)
						.orElse(0);
					if (position.state === TradingPositionState.closed && position.aId === activeTradingAccount?.id) {
						if (yearDate <= position.cT! && position.cT! <= todayDate) {
							numPositions = numPositions + 1;
							mapClosedData(position, positionLength, index);
						}
					}
				});
			}

			setGridLength(gridRecords.length);
			if (gridRecords.length === 0) {
				setEmptyState(true);
			} else if (searchTerm.length === 0) {
				setEmptyState(false);
			}

			//create grid model
			const tradeStore = new ArrayStore({ key: 'id', data: gridRecords });
			const model = {
				records: gridRecords,
				tradeStore: tradeStore,
				dataSource: new DataSource({ store: tradeStore, reshapeOnPush: true }),
				tradingAccount: currentAccount?.id,
				months: CALENDAR_MONTHS_SHORT_EN,
			};
			return model;
		}, [tradingPositions, activeTradingAccount, tradingAccount, closeTrade, searchTerm]);

		const calcNetPL = (data: any) => {
			const record: GridRecord = data.data;
			return (
				<div className={record.NetPL > 0 ? styles.greenCurrent : styles.redCurrent}>
					<MoneyFormatter money={parseFloat(record.NetPL)} />
				</div>
			);
		};

		const calcGrossPL = (data: any) => {
			const record: GridRecord = data.data;
			return (
				<div className={record.GrossPL > 0 ? styles.greenCurrent : styles.redCurrent}>
					<MoneyFormatter money={parseFloat(record.GrossPL)} />
				</div>
			);
		};

		const showCogIcon = () => {
			const iconStyle = isArabic
				? {
						fontSize: orderIconsSize,
						marginRight: '5px',
				  }
				: {
						fontSize: orderIconsSize,
						marginLeft: '5px',
				  };
			return (
				<OverlayTrigger
					delay={{ show: 750, hide: 0 }}
					key={'positionsGridSettings'}
					placement="bottom-end"
					overlay={
						<Tooltip className="my-tooltip" id={'positionsGridSettings'}>
							{t('en:SETTINGS')}
						</Tooltip>
					}
				>
					<div className={styles.triggerContainer} onClick={handleShowColumnChooser}>
						<FontAwesomeIcon icon={['fas', 'cog']} style={iconStyle} className={styles.icon} />
					</div>
				</OverlayTrigger>
			);
		};

		const showSideTypeAndIcon = (e: any) => {
			if (!e.displayValue) return null;
			return (
				<>
					{e.row.data.Side === 'SQUARE' ? (
						<div className={styles.sideTooltipWrapper}>
							<div className={styles.sideTooltipSquare}>{t(e.displayValue)}</div>
							<WtrTooltip content={t('en:POSITION_SQUARE_TOOLTIP')} position="right center">
								<div className="gridMenu" id="gridMenu">
									<FontAwesomeIcon className={styles.sideTooltipSquareIcon} icon={['fas', 'circle-info']} />
								</div>
							</WtrTooltip>
						</div>
					) : (
						<div>{t(e.displayValue)}</div>
					)}
				</>
			);
		};

		const showInfoIcon = (e: any) => {
			return (
				<div
					className={styles.triggerContainer}
					onClick={() => (dashboardContext.showClosedPositionTicket = !showClosedPositionTicket)}
				>
					<FontAwesomeIcon
						icon={['fas', 'info-circle']}
						style={{ fontSize: orderIconsSize, marginLeft: '10px' }}
						className={styles.iconTheme}
					/>
				</div>
			);
		};

		const handleShowColumnChooser = () => {
			if (treeList.current) {
				treeList.current.instance.showColumnChooser();
			}
		};

		const handleHideColumnChooser = () => {
			if (treeList.current) {
				treeList.current.instance.hideColumnChooser();
			}
		};

		const onContentReady = async (e: any) => {
			const columnChooserView = e.component.getView('columnChooserView');
			if (!columnChooserView._popupContainer) {
				columnChooserView._initializePopupContainer();
				columnChooserView.render();
				columnChooserView._popupContainer.option('height', 200);
				columnChooserView._popupContainer.option('width', 215);
				columnChooserView._popupContainer.option('dragEnabled', false);
				const position = isArabic
					? {
							my: 'top left',
							at: 'top left',
							offset: '50 5',
					  }
					: {
							my: 'top right',
							at: 'top right',
							offset: '-50 5',
					  };
				columnChooserView._popupContainer.option('position', position);
			}
			//Other Arabic UI Changes
			if (isArabic) {
				const search: NodeListOf<HTMLElement> = document.querySelectorAll('.dx-treelist-header-panel .dx-toolbar');
				if (search.length > 0) search[0].style.right = 'calc(100% - 200px)';
			}
		};

		const handleRowClick = (e: any) => {
			if (e.data) {
				const closedPositions = tradingPositions.filter(
					(position: TradingPosition) =>
						position.code === e.data.code &&
						position.state === TradingPositionState.closed &&
						position.posId === e.data.posId
				);
				const marketItem = rfpGatewayContext?.getMarketItem(e.data.code);
				setPositionData(closedPositions);
				if (marketItem) {
					dashboardContext.closeAllOtherTabs();
					dashboardContext.isEdit = false;
					dashboardContext.selectedInstrument = marketItem;
					dashboardContext.symbolChanged = marketItem.code;
					dashboardContext.gridChartsChanged = !dashboardContext.gridChartsChanged;
				}
			}
		};

		const highlightCell = (data: any) => {
			const cssClass = cn(
				headerSelect === data.column.caption.replace(/\s/g, '') ? styles[`${theme}headerCell`] : '',
				data.column.caption === tt('INSTRUMENT') ? styles.headerCellInstrument : ''
			);

			return (
				<div className={cssClass} tabIndex={0}>
					{data.column.caption}
				</div>
			);
		};

		const changeHighlight = (data: any) => {
			if (data.rowType === 'header') {
				setHeaderSelect(data.column.dataField?.replace(/\s/g, ''));
			}
		};

		const handleSortNetPL = useCallback((rowValue: any) => {
			return +rowValue.NetPL;
		}, []);

		const handleSortGrossPL = useCallback((rowValue: any) => {
			return +rowValue.GrossPL;
		}, []);

		const handleSortTP = useCallback((rowValue: any) => {
			return rowValue.TakeProfit ? Number(revertToTraditionalNumberFormat(rowValue.TakeProfit, languageSettings)) : 0;
		}, []);

		const handleSortSL = useCallback((rowValue: any) => {
			return rowValue.StopLoss ? Number(revertToTraditionalNumberFormat(rowValue.StopLoss, languageSettings)) : 0;
		}, []);

		const sortClosedDates = useCallback((rowValue: any) => {
			return moment(rowValue['Closed On']).valueOf();
		}, []);

		const sortOpenDates = useCallback((rowValue: any) => {
			return moment(rowValue['Open Date']).valueOf();
		}, []);

		const customSave = useCallback(
			(e) => {
				if (!emptyState && gridModel.records.length > 0) {
					localStorage.setItem(
						'closedGridColumns',
						JSON.stringify(
							(e.columns as any[])?.map((c) => ({
								dataField: c.dataField,
								dataType: c.dataType,
								name: c.name,
								visible: c.visible,
								visibleIndex: c.visibleIndex,
								width: c.width,
								sortOrder: c.sortOrder,
								sortIndex: c.sortIndex,
							}))
						)
					);
				}
			},
			[emptyState]
		);

		const calculatePLPips = (data: any) => {
			const record: GridRecord = data.data;
			let profitLossPipsValue = isNaN((record['Pips Change'] || Number.NaN) as number)
				? 0
				: (record['Pips Change'] as number);
			return (
				<div style={{ direction: 'ltr' }} className={profitLossPipsValue > 0 ? styles.greenCurrent : styles.redCurrent}>
					{addCommas(profitLossPipsValue.toFixed(2))}
				</div>
			);
		};

		const calculateDollarValue = (data: any) => {
			const record: GridRecord = data.data;
			const field: keyof GridRecord = data.column.dataField;
			return (
				<div>
					<MoneyFormatter money={Number(record[field])} />
				</div>
			);
		};

		const calculateComment = (data: any) => {
			const record: GridRecord = data.data;
			const comment = StringUtils.isNullOrEmpty(record.comment?.toString()) ? t('wtr:NA') : record.comment;
			return <div>{comment}</div>;
		};

		const getTimeFrameTranslation = () => {
			switch (closeTrade) {
				case '1 Month':
					return t('wtr:1_MONTH');
				case '3 Months':
					return t('wtr:3_MONTHS');
				case 'YTD':
					return t('wtr:WTR_YTD');
				case '1 Year':
					return t('wtr:1_YEAR');
				case '1 Week':
					return t('wtr:1_WEEK');
				case 'Today':
					return t('wtr:WTR_TODAY');
				case 'All':
					return tt('All');
			}
		};

		const openPeriodDropdown = (event: React.MouseEvent<HTMLAnchorElement>) => {
			const opener: any = event.target;
			const periodDropDownComponent = periodDropdownRef.current;

			if (!periodDropDownComponent) {
				return;
			}

			const hasOpenedDropDown = opener.dataset.hasOpenedDropDown === 'true';
			if (!hasOpenedDropDown) {
				periodDropDownComponent.open();
				opener.dataset.hasOpenedDropDown = 'true';
			} else {
				periodDropDownComponent.close();
				opener.dataset.hasOpenedDropDown = 'false';
			}
		};

		const renderClearFilters = () => {
			return searchTerm.length > 0 ? (
				<div className={styles.emptyGridContainer}>
					<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'search']} size="5x" />
					<div>{t('wtr:NO_POSITIONS_FOUND')}</div>
					<div className={styles.emptyButtonContainer}>
						<div className={styles.clearFilters} onMouseDown={() => setSearchTerm('')}>
							{t('wtr:CLEAR_FILTERS')}
						</div>
					</div>
				</div>
			) : (
				<div className={styles.emptyGridContainer}>
					<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'history']} size="5x" />
					<div className={styles.emptyStateMessage}>
						{t('wtr:WTR_NO_CLOSED_POSITIONS')}
						<a className={styles.positionDate} onClick={openPeriodDropdown}>
							{getTimeFrameTranslation()}
						</a>
					</div>
				</div>
			);
		};
		const searchMatches = useMemo(() => {
			let matches: Optional<string | number>[] = [];
			let visibleColumns: string[] = [];
			if (treeList.current) {
				treeList.current.instance.refresh();
				visibleColumns =
					treeList.current?.instance
						.getVisibleColumns()
						.filter((column) => column.dataField)
						.map((column) => column.dataField as string) ?? [];
			}
			if (visibleColumns.length === 0) {
				const localData = localStorage.getItem('closedGridColumns') ?? '[]';
				visibleColumns = JSON.parse(localData)
					.filter((column: any) => column.visible)
					.map((column: any) => column.dataField);
			}
			gridModel.records.forEach((record) => {
				visibleColumns.forEach((columnName: string) => {
					matches.push([(record as any)[columnName]][0]);
				});
			});
			return matches;
		}, [gridModel, columnsUpdated]);

		useEffect(() => {
			if (searchTerm.length === 0) return;

			const matches = searchMatches
				.map((item) => item?.toString().toUpperCase())
				.filter((item: string | undefined) => item);
			if (
				matches.length !== 0 &&
				!matches.find((match: string | undefined) => match?.includes(searchTerm.toUpperCase()))
			) {
				setEmptyState(true);
			} else if (gridLength !== 0) {
				setEmptyState(false);
			}
		}, [searchTerm, searchMatches, gridLength]);

		const onOptionChanged = (e: any) => {
			if (e.fullName === 'dataSource') {
				e.component.hideColumnChooser();
			}
		};

		const renderOpenDate = (data: any) => {
			const record: GridRecord = data.data;
			return <div style={{ direction: 'ltr' }}>{record['Open Date Translated']}</div>;
		};

		const renderClosedDate = (data: any) => {
			const record: GridRecord = data.data;
			return <div style={{ direction: 'ltr' }}>{record['Closed On Translated']}</div>;
		};

		const sortAmountLots = useCallback(
			(rowValue: any) => {
				const value = quantityType === QUANTITY_TYPE.LOTS ? rowValue.Lots : rowValue.Amount;
				return Number(revertToTraditionalNumberFormat(value, languageSettings));
			},
			[quantityType]
		);

		const sortOpenAt = useCallback((rowValue: any) => {
			return Number(revertToTraditionalNumberFormat(rowValue['Open @'], languageSettings));
		}, []);

		const sortClosedAt = useCallback((rowValue: any) => {
			return Number(revertToTraditionalNumberFormat(rowValue['Close @'], languageSettings));
		}, []);

		const columnsMap: { [key: string]: { [key: string]: any } } = useMemo(
			() =>
				JSON.parse(localStorage.getItem('closedGridColumns') ?? '[]').reduce((res: any, current: any) => {
					res[current.dataField] = current;
					return res;
				}, {}),
			[]
		);
		const hasColumns = useMemo(() => columnsMap && Object.keys(columnsMap).length > 0, [columnsMap]);

		const getAttribute = useCallback(
			(attribute: string, dataField: string) => {
				if (emptyState && attribute === 'visible') return false;
				if (!hasColumns) return undefined;
				return columnsMap[dataField]?.[attribute];
			},
			[emptyState]
		);

		const quantityTypeString = useMemo(() => (quantityType === QUANTITY_TYPE.LOTS ? 'Lots' : 'Amount'), [quantityType]);

		if (emptyState) {
			return renderClearFilters();
		}

		return (
			<div className={styles.grid}>
				{showClosedPositionTicket && (
					<div>
						<ClosedTicketModal data={positionData} />
					</div>
				)}
				<TreeList
					dataSource={gridModel.dataSource}
					allowColumnResizing={true}
					columnResizingMode="nextColumn"
					columnMinWidth={50}
					showRowLines={true}
					showColumnLines={false}
					showBorders={true}
					rootValue={-1}
					keyExpr="id"
					parentIdExpr="headID"
					className={!maximizeGrid ? styles.treeListLayout : styles.maximizeTreeListLayout}
					ref={treeList}
					onContentReady={onContentReady}
					onRowClick={handleRowClick}
					onOptionChanged={onOptionChanged}
					repaintChangesOnly={true}
					onCellClick={changeHighlight}
					hoverStateEnabled={!emptyState}
					rtlEnabled={isArabic}
				>
					<ColumnChooser
						title={t('wtr:COLUMN_CHOOSER')}
						emptyPanelText={t('wtr:DRAG_COLUMN')}
						width={DX_COLUMN_CHOOSER_SIZE.width}
						height={DX_COLUMN_CHOOSER_SIZE.height}
					/>
					<LoadPanel enabled={false} />
					<SearchPanel
						visible={false}
						placeholder={t('wtr:SEARCH_FOR_POSITION')}
						text={searchTerm}
						width={200}
						searchVisibleColumnsOnly={true}
					/>
					<Selection mode={emptyState ? 'none' : 'single'} />
					<StateStoring enabled={true} type="custom" customSave={customSave} savingTimeout={0} />
					<Column
						caption={tt('INSTRUMENT')}
						dataField="Instrument"
						allowHiding={false}
						fixedPosition={isArabic ? 'right' : 'left'}
						sortIndex={getAttribute('sortIndex', 'Instrument')}
						sortOrder={getAttribute('sortOrder', 'Instrument') ?? 'desc'}
						width={getAttribute('width', 'Instrument') ?? 190}
						minWidth="120"
						headerCellRender={highlightCell}
						visible={!emptyState}
						visibleIndex={0}
						allowSearch={true}
						alignment={isArabic ? 'right' : ''}
						cellRender={(data: any) => <>{data.data['Instrument']}&lrm;</>} // display grouped brackets properly
						fixed={true}
					/>
					<Column
						allowReordering={true}
						caption={tt('SIDE')}
						sortIndex={getAttribute('sortIndex', 'SideTranslated')}
						sortOrder={getAttribute('sortOrder', 'SideTranslated') ?? 'desc'}
						dataField="SideTranslated"
						width={getAttribute('width', 'SideTranslated')}
						headerCellRender={highlightCell}
						cellRender={showSideTypeAndIcon}
						visible={getAttribute('visible', 'SideTranslated') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'SideTranslated') ?? 1}
						allowSearch={!emptyState}
						minWidth="100"
						alignment={isArabic ? 'right' : ''}
					/>

					<Column
						caption={tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
						dataField={quantityTypeString}
						allowReordering={true}
						alignment="right"
						calculateSortValue={sortAmountLots}
						sortIndex={getAttribute('sortIndex', quantityTypeString)}
						sortOrder={getAttribute('sortOrder', quantityTypeString) ?? 'desc'}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', quantityTypeString) ?? true}
						visibleIndex={getAttribute('visibileIndex', quantityTypeString) ?? 2}
						width={getAttribute('width', quantityTypeString)}
						minWidth="72"
						allowSearch={!emptyState}
					/>
					<Column
						caption={tt('OPEN_AT')}
						dataField="Open @"
						alignment="right"
						headerCellRender={highlightCell}
						allowReordering={true}
						calculateSortValue={sortOpenAt}
						sortIndex={getAttribute('sortIndex', 'Open @')}
						sortOrder={getAttribute('sortOrder', 'Open @') ?? 'desc'}
						visible={getAttribute('visible', 'Open @') ?? true}
						visibleIndex={getAttribute('visibileIndex', 'Open @') ?? 3}
						width={getAttribute('width', 'Open @')}
						minWidth="72"
						allowSearch={!emptyState}
					/>
					<Column
						caption={tt('CLOSE_AT')}
						dataField="Close @"
						alignment="right"
						headerCellRender={highlightCell}
						allowReordering={true}
						calculateSortValue={sortClosedAt}
						sortIndex={getAttribute('sortIndex', 'Close @')}
						sortOrder={getAttribute('sortOrder', 'Close @') ?? 'desc'}
						visible={getAttribute('visible', 'Close @') ?? true}
						visibleIndex={getAttribute('visibileIndex', 'Close @') ?? 4}
						width={getAttribute('width', 'Close @')}
						minWidth="72"
						allowSearch={!emptyState}
					/>
					<Column
						caption={tt('OPEN_DATE')}
						dataField="Open Date Translated"
						allowReordering={true}
						dataType="string"
						calculateSortValue={sortOpenDates}
						sortIndex={getAttribute('sortIndex', 'Open Date Translated')}
						sortOrder={getAttribute('sortOrder', 'Open Date Translated')}
						alignment={isArabic ? 'right' : 'left'}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'Open Date Translated') ?? true}
						width={getAttribute('width', 'Open Date Translated')}
						minWidth="72"
						visibleIndex={getAttribute('visibileIndex', 'Open Date Translated') ?? 5}
						allowSearch={!emptyState}
						cellRender={renderOpenDate}
					/>
					<Column
						caption={t('wtr:WTR_CLOSED_ON')}
						dataField="Closed On Translated"
						allowReordering={true}
						dataType="string"
						calculateSortValue={sortClosedDates}
						sortIndex={getAttribute('sortIndex', 'Closed On Translated')}
						sortOrder={getAttribute('sortOrder', 'Closed On Translated') ?? 'desc'}
						alignment={isArabic ? 'right' : 'left'}
						headerCellRender={highlightCell}
						width={getAttribute('width', 'Closed On Translated')}
						minWidth="72"
						visible={getAttribute('visible', 'Closed On Translated') ?? true}
						visibleIndex={getAttribute('visibileIndex', 'Closed On Translated') ?? 6}
						allowSearch={!emptyState}
						cellRender={renderClosedDate}
					/>
					<Column
						caption={tt('NET_PL')}
						dataField="NetPL"
						allowReordering={true}
						cellRender={calcNetPL}
						calculateSortValue={handleSortNetPL}
						sortIndex={getAttribute('sortIndex', 'NetPL')}
						sortOrder={getAttribute('sortOrder', 'NetPL') ?? 'desc'}
						alignment="right"
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'NetPL') ?? true}
						width={getAttribute('width', 'NetPL')}
						minWidth="72"
						visibleIndex={getAttribute('visibileIndex', 'NetPL') ?? 7}
						allowSearch={!emptyState}
					/>
					<Column
						caption={tt('GROSS_PL')}
						dataField="GrossPL"
						allowReordering={true}
						cellRender={calcGrossPL}
						calculateSortValue={handleSortGrossPL}
						sortIndex={getAttribute('sortIndex', 'GrossPL')}
						sortOrder={getAttribute('sortOrder', 'GrossPL') ?? 'desc'}
						alignment="right"
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'GrossPL') ?? false}
						width={getAttribute('width', 'GrossPL')}
						minWidth="72"
						visibleIndex={getAttribute('visibileIndex', 'GrossPL') ?? 7}
						allowSearch={!emptyState}
					/>
					<Column
						caption={t(`wtr:WTR_PL_IN_${isSpreadBettingAccount ? 'POINTS' : 'PIPS'}`)}
						dataField="Pips Change"
						allowReordering={true}
						sortIndex={getAttribute('sortIndex', 'Pips Change')}
						sortOrder={getAttribute('sortOrder', 'Pips Change') ?? 'desc'}
						cellRender={calculatePLPips}
						width={getAttribute('width', 'Pips Change')}
						minWidth="72"
						alignment="right"
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'Pips Change') ?? true}
						visibleIndex={getAttribute('visibileIndex', 'Pips Change') ?? 8}
						allowSearch={!emptyState}
					/>
					<Column
						caption={tt('Open Time')}
						dataField="openTime"
						allowReordering={true}
						sortIndex={getAttribute('sortIndex', 'openTime')}
						sortOrder={getAttribute('sortOrder', 'openTime') ?? 'desc'}
						alignment="right"
						visible={getAttribute('visible', 'openTime') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'openTime') ?? 9}
						allowSearch={!emptyState}
						width={getAttribute('width', 'openTime')}
						minWidth="72"
						allowSorting={false}
					/>
					<Column
						caption={tt('Close Time')}
						dataField="closeTime"
						allowReordering={true}
						sortIndex={getAttribute('sortIndex', 'closeTime')}
						sortOrder={getAttribute('sortOrder', 'closeTime') ?? 'desc'}
						alignment="right"
						visible={getAttribute('visible', 'closeTime') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'closeTime') ?? 10}
						allowSearch={!emptyState}
						width={getAttribute('width', 'closeTime')}
						minWidth="72"
						allowSorting={false}
					/>
					<Column
						caption={tt('SWAP')}
						dataField="swap"
						allowReordering={true}
						sortIndex={getAttribute('sortIndex', 'swap')}
						sortOrder={getAttribute('sortOrder', 'swap') ?? 'desc'}
						alignment="right"
						visible={getAttribute('visible', 'swap') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'swap') ?? 11}
						allowSearch={!emptyState}
						width={getAttribute('width', 'swap')}
						minWidth="72"
						cellRender={calculateDollarValue}
					/>
					{!appContext.isJapanAccount && (
						<Column
							caption={tt('DIVIDEND')}
							dataField="dividend"
							allowReordering={true}
							sortIndex={getAttribute('sortIndex', 'dividend')}
							sortOrder={getAttribute('sortOrder', 'dividend') ?? 'desc'}
							alignment="right"
							visible={getAttribute('visible', 'dividend') ?? false}
							visibleIndex={getAttribute('visibileIndex', 'dividend') ?? 12}
							allowSearch={!emptyState}
							width={getAttribute('width', 'dividend')}
							minWidth="72"
							cellRender={calculateDollarValue}
						/>
					)}
					{!appContext.isJapanAccount && (
						<Column
							caption={tt('COMMISSION')}
							dataField="comm"
							allowReordering={true}
							sortIndex={getAttribute('sortIndex', 'comm')}
							sortOrder={getAttribute('sortOrder', 'comm') ?? 'desc'}
							alignment="right"
							visible={getAttribute('visible', 'comm') ?? false}
							visibleIndex={getAttribute('visibileIndex', 'comm') ?? 13}
							allowSearch={!emptyState}
							width={getAttribute('width', 'comm')}
							minWidth="72"
							cellRender={calculateDollarValue}
						/>
					)}
					<Column
						caption={tt('POSITION_COMMENT')}
						dataField="comment"
						allowReordering={true}
						sortIndex={getAttribute('sortIndex', 'comment')}
						sortOrder={getAttribute('sortOrder', 'comment') ?? 'desc'}
						alignment="right"
						visible={getAttribute('visible', 'comment') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'comment') ?? 14}
						allowSearch={!emptyState}
						width={getAttribute('width', 'comment')}
						minWidth="72"
						allowSorting={false}
						cellRender={calculateComment}
					/>
					<Column
						caption={tt('POSITION_ID')}
						dataField="posId"
						allowReordering={true}
						sortIndex={getAttribute('sortIndex', 'posId')}
						sortOrder={getAttribute('sortOrder', 'posId') ?? 'desc'}
						alignment="right"
						visible={getAttribute('visible', 'posId') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'posId') ?? 15}
						allowSearch={!emptyState}
						width={getAttribute('width', 'posId')}
						minWidth="72"
					/>
					<Column
						caption={tt('TAKE_PROFIT')}
						dataField="TakeProfit"
						allowReordering={true}
						allowFiltering={false}
						visible={getAttribute('visible', 'TakeProfit') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'TakeProfit') ?? 16}
						allowSearch={!emptyState}
						width={getAttribute('width', 'TakeProfit')}
						calculateSortValue={handleSortTP}
						minWidth="90"
						cssClass={styles.tpslCell}
					/>
					<Column
						caption={tt('STOP_LOSS')}
						dataField="StopLoss"
						allowReordering={true}
						allowFiltering={false}
						visible={getAttribute('visible', 'StopLoss') ?? false}
						visibleIndex={getAttribute('visibileIndex', 'StopLoss') ?? 17}
						allowSearch={!emptyState}
						width={getAttribute('width', 'StopLoss')}
						calculateSortValue={handleSortSL}
						minWidth="90"
						cssClass={styles.tpslCell}
					/>

					<Column
						fixedPosition={isArabic ? 'left' : 'right'}
						allowReordering={false}
						allowHiding={false}
						allowSorting={false}
						allowResizing={false}
						headerCellRender={showCogIcon}
						cellRender={showInfoIcon}
						allowFiltering={false}
						width={40}
						visible={!emptyState}
						visibleIndex={18}
						allowSearch={!emptyState}
						cssClass={styles.closeAndMoreOptionsCell}
						fixed={true}
					/>
					<Scrolling showScrollbar={emptyState ? 'never' : 'always'} mode="standard" />
				</TreeList>
			</div>
		);
	},
	(prevProps, nextProps) => {
		return prevProps.searchTerm === nextProps.searchTerm;
	}
);

export default ClosedGrid;
