import React from 'react';

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import styles from './Button.module.scss';

interface ButtonProps {
	className?: string;
	labelClass?: string;
	disabled?: boolean;
	label: any | undefined;
	onClick?: (event: React.MouseEvent) => void;
	icon?: IconProp;
	size?: 'sm' | 'md' | 'lg' | 'fluid';
	type?: 'submit';
	variant?: 'primary' | 'secondary' | 'danger' | 'outline';
	smallFont?: boolean;
	isFullWidth?: boolean;
	children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
	className,
	labelClass,
	disabled = false,
	label,
	onClick,
	icon,
	size = 'md',
	type,
	variant = 'primary',
	smallFont = false,
	isFullWidth = false,
	children,
}) => {
	const _className = cn(
		smallFont ? styles.smallFont : '',
		className,
		styles.button,
		styles[size],
		styles[variant],
		{
			[styles.disabled]: disabled,
			[styles.outlineDisabled]: disabled && variant === 'outline',
		},
		isFullWidth ? styles.fullWidth : ''
	);
	if (!label) {
		return null;
	}
	const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!disabled && typeof onClick === 'function') {
			onClick(event);
		}
	};

	return (
		<button disabled={disabled} type={type} className={_className} onClick={handleButtonClick} aria-label={label}>
			{icon && <FontAwesomeIcon icon={icon} />}
			<span className={cn(labelClass && labelClass, styles.buttonLabel)}>
				{children}
				{label}
			</span>
		</button>
	);
};

export default Button;
