import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import {
  ColumnResizeDirection,
  ColumnResizeMode,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// needed for table body level scope DnD setup
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  DragEndEvent,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';

import AppContext from '../../../../contexts/AppContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import RfpGatewayContext from '../../../../contexts/RfpGatewayContext';
import InstrumentContext from '../../../../contexts/InstrumentContext';

import quoteStore from '../../../../store/QuoteStore/quoteStore';
import orderStore from '../../../../store/OrderStore/orderStore';
import tradingAccountStore from '../../../../store/tradingAccountStore';
import marketTableFilterStore, {
  ALL_STRING,
  Period
} from '../../../../store/MarketTableFilterStore/marketTableFilterStore';

import useOrderTicketAccess from '../../../../utils/hooks/useOrderTicketAccess';

import {
  createMarketItemCodes,
  createMarketItemGroupTableData,
  createMarketItemsArrayFromCodes,
  createMarketWatchItemCodes,
  TableMarketItem
} from '../../../../utils/functions/marketItems/marketItemGroupMapFormatter';
import { DEFAULT_FEED_ID } from '../../../../utils/functions/WatchlistUtils';

import FilterBar from './components/FilterBar';
import DragAlongCell from './components/DragAlongCell';
import PercentChange from './components/PercentChange';
import SettingTrigger from './components/SettingTrigger';
import Loading from '../../../components/Loading/Loading';
import TableSettingsModal from './components/TableSettingsModal';
import LowOrHigh, { LowOrHighType } from './components/LowOrHigh';
import DraggableTableHeader from './components/DraggableTableHeader';
import VolatilityProgressBar from './components/VolativityProgressBar';
import AddToWatchlistWrapper from './components/AddToWatchlistWrapper';
import SellOrBuyButton, { SellOrBuyType } from './components/SellOrBuyButton';
import MarketItemIcon from '../../../components/GroupBadge/MarketItemIcon';
import { useMarketItemsMap } from '../../../components/MarketItemFormatter/useMarketItemsMap';
import { useMarketItemFormatter } from '../../../components/MarketItemFormatter/useMarketItemFormatter';

import Routes from '../../../../setup/routes';

import {
  AppComponentType,
  MarketsGroupName,
  MarketsGroupPeriod,
  TicketLayout,
  TradingPositionSide
} from '../../../../utils/functions/enums';
import { RFP } from '../../../../gateways/RfpGateway/rfpConstants';
import { PriceQuote } from '../../../../gateways/RfpGateway/rfp.types';

import { IMarketGroup } from '../MarketsGrid';

import styles from './MarketsTable.module.scss';

interface MarketTableProps {
  selectedCategory: string;
  translatedMarketGroups: IMarketGroup[];
  newsPanelHeight: number;
}

const MarketsTable: FC<MarketTableProps> = ({
  selectedCategory,
  translatedMarketGroups,
  newsPanelHeight
}): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const instrumentContext = useContext(InstrumentContext);
  const appContext = useContext(AppContext);
  const dashboardContext = useContext(DashboardContext);
  const rfpGatewayContext = useContext(RfpGatewayContext);

  const orderTicketAccess = useOrderTicketAccess();
  const data = useMarketItemFormatter();
  const marketsItemMap = useMarketItemsMap();
  const setQuote = quoteStore.use.setQuote();
  const maxVolatility = quoteStore.use.maxVolatility();
  const setMaxVolatility = quoteStore.use.setMaxVolatility();
  const currencyFilter = marketTableFilterStore.use.currency();
  const exchangeCountryCodeFilter = marketTableFilterStore.use.exchangeCountryCode();
  const periodFilter = marketTableFilterStore.use.period();
  const categoryFilter = marketTableFilterStore.use.category();
  const sectorFilter = marketTableFilterStore.use.sector();
  const setTradeProps = orderStore.use.setTradeProps();
  const setTradingPosition = orderStore.use.setTradingPosition();
  const setMarketItem = orderStore.use.setMarketItem();
  const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
  const setAllFiltersToDefault = marketTableFilterStore.use.setAllFiltersToDefault();

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnResizeMode, _] = useState<ColumnResizeMode>('onChange');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [forceRerender, setForceRerender] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [emptyState, setEmptyState] = useState<boolean>(false);

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const subIdRef = useRef<string | undefined>(undefined);
  const sortingRef = useRef<Record<string, PriceQuote>>({});

  // State for comparing codes in dynamic groups
  const [codesComparison, setCodesComparison] = useState<string>('');

  // Effect that fixes DND race condition
  useEffect(() => {
    setLoading(true);

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    selectedCategory,
    currencyFilter,
    exchangeCountryCodeFilter,
    periodFilter,
    categoryFilter,
    sectorFilter
  ]);

  // Reset filters if user changes the market item category
  useEffect(() => {
    setAllFiltersToDefault();
  }, [selectedCategory, setAllFiltersToDefault]);

  // Table columns with custom sorting functions
  const columnHelper = createColumnHelper<TableMarketItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('symbol', {
        id: 'symbol',
        header: () => <span>{t('en:SYMBOL')}</span>,
        cell: (info) => (
          <div className={cn(styles.symbolCell)}>
            <MarketItemIcon marketItem={marketsItemMap[info.row.original.symbol]}/>
            <span className={cn(styles.symbolText)}>{info.getValue().replace('_SB', '')}</span>
          </div>
        ),
        enableHiding: false,
        minSize: 100
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: () => <span>{t('wtr:FULL_NAME')}</span>,
        cell: (info) => (
          <span
            className={cn(styles.verticalCenterAlign)}
          >
            {info.getValue()}
          </span>
        ),
        minSize: 150
      }),
      columnHelper.accessor('volatility', {
        id: 'volatility',
        header: () => <span>{t('wtr:VOLATILITY')}</span>,
        cell: (info) => (
          <VolatilityProgressBar volatility={info.getValue()}/>
        ),
        minSize: 150
      }),
      columnHelper.accessor('currency', {
        id: 'currency',
        header: () => <span>{t('en:CURRENCY')}</span>,
        cell: (info) => (
          <span className={cn(styles.verticalCenterAlign)}>{info.renderValue()}</span>
        ),
        minSize: 100
      }),
      columnHelper.accessor('change', {
        id: 'change',
        header: () => <span>{changeCaption}</span>,
        cell: (info) => (
          <div className={cn(styles.verticalCenterAlign)}>
            <PercentChange change={info.getValue()}/>
          </div>
        ),
        minSize: 100
      }),
      columnHelper.accessor('low', {
        id: 'low',
        header: () => <span>{t('en:LOW')}</span>,
        cell: (info) => (
          <div className={cn(styles.verticalCenterAlign)}>
            <LowOrHigh
              marketItemCode={info.getValue()}
              type={LowOrHighType.Low}
            />
          </div>
        ),
        minSize: 100,
        sortingFn: (rowA, rowB) => {
          const a = sortingRef.current[rowA.original.low];
          const b = sortingRef.current[rowB.original.low];

          if (!a || !b) {
            return 0;
          }

          return a.l - b.l;
        }
      }),
      columnHelper.accessor('high', {
        id: 'high',
        header: () => <span>{t('en:HIGH')}</span>,
        cell: (info) => (
          <div className={cn(styles.verticalCenterAlign)}>
            <LowOrHigh
              marketItemCode={info.getValue()}
              type={LowOrHighType.High}
            />
          </div>
        ),
        minSize: 100,
        sortingFn: (rowA, rowB) => {
          const a = sortingRef.current[rowA.original.high];
          const b = sortingRef.current[rowB.original.high];

          if (!a || !b) {
            return 0;
          }

          return a.h - b.h;
        }
      }),
      columnHelper.accessor('sell', {
        id: 'sell',
        header: () => <span>{t('en:SELL')}</span>,
        cell: (info) => (
          <div className={cn(styles.verticalCenterAlign)}>
            <SellOrBuyButton
              marketItem={marketsItemMap[info.row.original.sell]}
              type={SellOrBuyType.Sell}
            />
          </div>
        ),
        sortingFn: (rowA, rowB) => {
          const a = sortingRef.current[rowA.original.sell];
          const b = sortingRef.current[rowB.original.sell];

          if (!a || !b) {
            return 0;
          }

          return a.b - b.b;
        },
        enableResizing: false,
        enableHiding: false,
        size: 100,
        minSize: 100,
        maxSize: 100
      }),
      columnHelper.accessor('buy', {
        id: 'buy',
        header: () => <span>{t('en:BUY')}</span>,
        cell: (info) => (
          <div className={cn(styles.verticalCenterAlign)}>
            <SellOrBuyButton
              marketItem={marketsItemMap[info.row.original.buy]}
              type={SellOrBuyType.Buy}
            />
          </div>
        ),
        sortingFn: (rowA, rowB) => {
          const a = sortingRef.current[rowA.original.buy];
          const b = sortingRef.current[rowB.original.buy];

          if (!a || !b) {
            return 0;
          }

          return a.a - b.a;
        },
        enableResizing: false,
        enableHiding: false,
        size: 100,
        minSize: 100,
        maxSize: 100
      }),
      columnHelper.accessor('settings', {
        id: 'settings',
        header: () => <SettingTrigger handleShowColumnChooser={setShowSettingsModal}/>,
        cell: (info) => (
          <div className={cn(styles.verticalCenterAlign)}>
            <AddToWatchlistWrapper marketItem={marketsItemMap[info.row.original.settings]}/>
          </div>
        ),
        enableSorting: false,
        enableResizing: false,
        enableHiding: false,
        size: 30,
        minSize: 50,
        maxSize: 50
      })
    ],
    [Object.keys(marketsItemMap).length]
  );

  const [columnOrder, setColumnOrder] = React.useState<string[]>(() => columns.map((c) => c.id!));

  // In order NOT to use useObservable here, we need to force rerender the table every 2 seconds
  // because we do not know when we have new dynamic data saved in the DashboardContext
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        selectedCategory === MarketsGroupName.Risers ||
        selectedCategory === MarketsGroupName.Fallers ||
        selectedCategory === MarketsGroupName.MostVolatile ||
        selectedCategory === MarketsGroupName.RisersSB ||
        selectedCategory === MarketsGroupName.FallersSB ||
        selectedCategory === MarketsGroupName.MostVolatileSB
      ) {
        setForceRerender((state) => state + 1);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [selectedCategory]);

  // Set column visibility and default sorting for dynamic categories
  useEffect(() => {
    setShowSettingsModal(false);

    const isDynamicCategorySelected =
      selectedCategory === MarketsGroupName.Risers ||
      selectedCategory === MarketsGroupName.RisersSB ||
      selectedCategory === MarketsGroupName.Fallers ||
      selectedCategory === MarketsGroupName.FallersSB ||
      selectedCategory === MarketsGroupName.MostVolatile ||
      selectedCategory === MarketsGroupName.MostVolatileSB;

    const isVolatileSelected =
      selectedCategory === MarketsGroupName.MostVolatile || selectedCategory === MarketsGroupName.MostVolatileSB;

    setColumnVisibility((state) => ({
      ...state,
      volatility: isVolatileSelected,
      change: isDynamicCategorySelected
    }));

    if (isDynamicCategorySelected) {
      setSorting([
        {
          id:
            selectedCategory === MarketsGroupName.MostVolatile || selectedCategory === MarketsGroupName.MostVolatileSB
              ? 'volatility'
              : 'change',
          desc:
            selectedCategory === MarketsGroupName.Risers ||
            selectedCategory === MarketsGroupName.RisersSB ||
            selectedCategory === MarketsGroupName.MostVolatile ||
            selectedCategory === MarketsGroupName.MostVolatileSB
        }
      ]);
    }
  }, [selectedCategory, setColumnVisibility, setShowSettingsModal, setSorting]);

  // Change caption for dynamic categories
  const changeCaption = useMemo(() => {
    const caption = t('wtr:CHANGE');
    return caption[caption.length - 1] === ':' ? caption.slice(0, -1) : caption;
  }, []);

  // Translate selected category
  const selectedCategoryTranslatedLabel = useMemo(() => {
    const selectedCategoryTranslated = translatedMarketGroups.find((group) => group.name === selectedCategory);

    if (!selectedCategoryTranslated || !selectedCategoryTranslated.label) {
      return '';
    }

    return selectedCategoryTranslated.label;
  }, [selectedCategory, translatedMarketGroups]);

  // Market item map for table returning items for UI and codes for subscription
  const tableData = useMemo(() => {
    setEmptyState(false);

    // If NOT dynamic category is selected
    if (
      selectedCategory !== MarketsGroupName.Risers &&
      selectedCategory !== MarketsGroupName.Fallers &&
      selectedCategory !== MarketsGroupName.MostVolatile &&
      selectedCategory !== MarketsGroupName.RisersSB &&
      selectedCategory !== MarketsGroupName.FallersSB &&
      selectedCategory !== MarketsGroupName.MostVolatileSB
    ) {
      // Force scroll to prevent not subscribing bug
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTo(0, 0);
      }

      if (!data[selectedCategory]) {
        setEmptyState(false);
        return {
          codes: [],
          items: []
        };
      }

      const arrayFromMap = data[selectedCategory];

      // Filtering
      const filteredArray = arrayFromMap.filter((item) => {
        if (currencyFilter === ALL_STRING && exchangeCountryCodeFilter === ALL_STRING && sectorFilter === ALL_STRING) {
          return true; // No filtering needed
        }

        // Filter by currency only
        if (currencyFilter !== ALL_STRING && exchangeCountryCodeFilter === ALL_STRING && sectorFilter === ALL_STRING) {
          return item.ccy === currencyFilter;
        }

        // Filter by exchangeCountryCode only
        if (currencyFilter === ALL_STRING && exchangeCountryCodeFilter !== ALL_STRING && sectorFilter === ALL_STRING) {
          return item.exchangeCountryCode === exchangeCountryCodeFilter;
        }

        // Filter by sector only
        if (currencyFilter === ALL_STRING && exchangeCountryCodeFilter === ALL_STRING && sectorFilter !== ALL_STRING) {
          return item.sector === sectorFilter;
        }

        // Filter by currency and exchangeCountryCode
        if (currencyFilter !== ALL_STRING && exchangeCountryCodeFilter !== ALL_STRING && sectorFilter === ALL_STRING) {
          return item.ccy === currencyFilter && item.exchangeCountryCode === exchangeCountryCodeFilter;
        }

        // Filter by currency and sector
        if (currencyFilter !== ALL_STRING && exchangeCountryCodeFilter === ALL_STRING && sectorFilter !== ALL_STRING) {
          return item.ccy === currencyFilter && item.sector === sectorFilter;
        }

        // Filter by exchangeCountryCode and sector
        if (currencyFilter === ALL_STRING && exchangeCountryCodeFilter !== ALL_STRING && sectorFilter !== ALL_STRING) {
          return item.exchangeCountryCode === exchangeCountryCodeFilter && item.sector === sectorFilter;
        }

        // Filter by currency, exchangeCountryCode, and sector
        return (
          item.ccy === currencyFilter &&
          item.exchangeCountryCode === exchangeCountryCodeFilter &&
          item.sector === sectorFilter
        );
      });

      const items = createMarketItemGroupTableData({ marketItems: filteredArray });

      if (!items.length) {
        setEmptyState(true);
      }

      const codes = createMarketItemCodes(filteredArray);

      return {
        codes,
        items
      };
    }

    // If dynamic group is selected
    if (
      selectedCategory === MarketsGroupName.Risers ||
      selectedCategory === MarketsGroupName.Fallers ||
      selectedCategory === MarketsGroupName.MostVolatile
    ) {
      const dataMap =
        selectedCategory === MarketsGroupName.Risers
          ? dashboardContext.marketWatchRisers
          : selectedCategory === MarketsGroupName.Fallers
            ? dashboardContext.marketWatchFallers
            : dashboardContext.marketWatchMostVolatile;

      const periodArray = periodFilter === Period.Daily ? dataMap.daily : dataMap.weekly;
      const categoryArray = periodArray.find((item) => item.category === categoryFilter);

      if (!categoryArray) {
        setEmptyState(true);
        return {
          codes: [],
          items: []
        };
      }

      const changeMap = categoryArray.items.reduce((all, item) => {
        if (item.code && item.percentChange) {
          all[item.code] = item.percentChange;
        }

        return all;
      }, {} as Record<string, number>);

      const volatilityMap = categoryArray.items.reduce((all, item) => {
        if (!item.volatility) {
          return all;
        }

        if (item.code) {
          all[item.code] = item.volatility;
        }

        if (item.volatility > maxVolatility) {
          setMaxVolatility(item.volatility);
        }

        return all;
      }, {} as Record<string, number>);

      const codes = createMarketWatchItemCodes(categoryArray.items);
      const marketItems = createMarketItemsArrayFromCodes({ codes, marketsItemMap });

      const items = createMarketItemGroupTableData({ marketItems, changeMap, volatilityMap });

      if (!items.length) {
        setEmptyState(true);
      }

      if (codesComparison !== codes.join(',')) {
        setCodesComparison(codes.join(','));
      }

      return {
        codes,
        items
      };
    }

    // If dynamic SB group is selected
    if (
      selectedCategory === MarketsGroupName.RisersSB ||
      selectedCategory === MarketsGroupName.FallersSB ||
      selectedCategory === MarketsGroupName.MostVolatileSB
    ) {
      const dataMap =
        selectedCategory === MarketsGroupName.RisersSB
          ? dashboardContext.marketWatchRisersSB
          : selectedCategory === MarketsGroupName.FallersSB
            ? dashboardContext.marketWatchFallersSB
            : dashboardContext.marketWatchMostVolatileSB;

      const periodArray = periodFilter === Period.Daily ? dataMap.daily : dataMap.weekly;
      const categoryArray = periodArray.find(
        (item) => item.category === (categoryFilter === 'All' ? categoryFilter : `${categoryFilter}SB`)
      );

      if (!categoryArray) {
        setEmptyState(true);
        return {
          codes: [],
          items: []
        };
      }

      const changeMap = categoryArray.items.reduce((all, item) => {
        if (item.code && item.percentChange) {
          all[item.code] = item.percentChange;
        }

        return all;
      }, {} as Record<string, number>);

      const volatilityMap = categoryArray.items.reduce((all, item) => {
        if (!item.volatility) {
          return all;
        }

        if (item.code) {
          all[item.code] = item.volatility;
        }

        if (item.volatility > maxVolatility) {
          setMaxVolatility(item.volatility);
        }

        return all;
      }, {} as Record<string, number>);

      const codes = createMarketWatchItemCodes(categoryArray.items);
      const marketItems = createMarketItemsArrayFromCodes({ codes, marketsItemMap });

      const items = createMarketItemGroupTableData({ marketItems, changeMap, volatilityMap });

      if (!items.length) {
        setEmptyState(true);
      }

      if (codesComparison !== codes.join(',')) {
        setCodesComparison(codes.join(','));
      }

      return {
        codes,
        items
      };
    }

    setEmptyState(true);
    return {
      codes: [],
      items: []
    };
  }, [
    codesComparison,
    isSpreadBettingAccount,
    forceRerender,
    Object.keys(data).length,
    selectedCategory,
    currencyFilter,
    exchangeCountryCodeFilter,
    sectorFilter,
    periodFilter,
    categoryFilter
  ]);

  // Subscribe for price quotes
  const subscribeToNewInstruments = (codes: string[]) => {
    if (
      rfpGatewayContext &&
      (selectedCategory === MarketsGroupName.Risers ||
        selectedCategory === MarketsGroupName.RisersSB ||
        selectedCategory === MarketsGroupName.Fallers ||
        selectedCategory === MarketsGroupName.FallersSB ||
        selectedCategory === MarketsGroupName.MostVolatile ||
        selectedCategory === MarketsGroupName.MostVolatileSB)
    ) {
      rfpGatewayContext.send(RFP.getMarketWatchDynamicSubscription, {
        category: selectedCategory === MarketsGroupName.MostVolatile ? 'Most volatile' : selectedCategory,
        intervalLength: MarketsGroupPeriod[periodFilter],
        subscribe: true
      });
    }

    if (rfpGatewayContext && tableData.items.length > 0 && tableData.codes.length > 0) {
      subIdRef.current = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, codes, (priceQuote) => {
        setQuote(priceQuote);
        sortingRef.current = {
          ...sortingRef.current,
          [priceQuote.c]: priceQuote
        };
      });
    }
  };

  // Unsubscribe price quotes on unmount
  const unsubscribeInstruments = () => {
    if (rfpGatewayContext && subIdRef.current) {
      rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
      subIdRef.current = undefined;
    }
  };

  // Column resize direction
  const columnResizeDirection: ColumnResizeDirection = useMemo(() => {
    return appContext.isArabic ? 'rtl' : 'ltr';
  }, [appContext.isArabic]);

  const table = useReactTable({
    data: tableData.items,
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility,
      columnOrder
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder
  });

  // Close all tickets
  const closeAllTickets = () => {
    dashboardContext.showOrderTicket = false;
    dashboardContext.showCloseTicket = false;
    dashboardContext.showCancelTicket = false;
    dashboardContext.modifyTicket = false;
    dashboardContext.showNewsFeed = false;
    dashboardContext.showConfirmTicket = false;
    dashboardContext.showOrderInformation = false;
  };

  // Handle selected instrument (copied logic from previous grid)
  const handleSelectedInstrument = (cell: any) => {
    if (cell.column.id === 'settings') {
      return;
    }

    if (!orderTicketAccess()) {
      return;
    }

    const record = marketsItemMap[cell.row.original.symbol];

    if (!record) {
      return;
    }

    const marketItem = instrumentContext.instruments.find(
      (instrument) => instrument.feedId === record.feedId && instrument.code === record.code
    );

    if (!marketItem) {
      return;
    }

    history.push(Routes.trader.watchlist, { from: window.location.pathname });
    dashboardContext.presentComponentType = AppComponentType.Watchlist;

    setTimeout(() => {
      closeAllTickets();
      dashboardContext.showOrderTicket = false;
      dashboardContext.symbolChanged = marketItem.code;
      dashboardContext.gridChartsChanged = true;
      dashboardContext.selectedPosition = null;
      dashboardContext.selectedInstrument = marketItem;
      dashboardContext.selectedType = 'Instrument';

      if (cell.column.id === 'sell' || cell.column.id === 'buy') {
        // Open up Order Ticket
        dashboardContext.modifyTicket = false;
        dashboardContext.showConfirmTicket = false;

        dashboardContext.orderModalOpenedFrom = 'watchlist';

        const tradingPositionSide = cell.column.id === 'sell' ? TradingPositionSide.Sell : TradingPositionSide.Buy;

        setTradingPosition(undefined);
        setMarketItem(marketItem);
        setTradeProps({ side: tradingPositionSide });

        dashboardContext.isEdit = false;

        if (dashboardContext.ticketLayout === TicketLayout.Dock) {
          dashboardContext.showOrderTicket = true;
          dashboardContext.showNewsFeed = false;
          dashboardContext.showCloseTicket = false;
          dashboardContext.showCancelTicket = false;
        } else {
          dashboardContext.newOrderModalToggle = {
            orderTicket: true,
            confirmOrder: false
          };
        }
      } else {
        dashboardContext.showOrderInformation = true;
        dashboardContext.toggleAccordionMenu = '0';
      }
    }, 500);
  };

  // Reorder columns after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const { rows } = table.getRowModel();

  // Dynamic row height virtualization - alternatively you could use a simpler fixed row height strategy without the need for `measureElement`
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: useCallback(() => 40, []), //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 3
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  // Subscription effect for dynamic categories
  useEffect(() => {
    if (
      selectedCategory === MarketsGroupName.Risers ||
      selectedCategory === MarketsGroupName.Fallers ||
      selectedCategory === MarketsGroupName.MostVolatile ||
      selectedCategory === MarketsGroupName.RisersSB ||
      selectedCategory === MarketsGroupName.FallersSB ||
      selectedCategory === MarketsGroupName.MostVolatileSB
    ) {
      if (subIdRef.current) {
        unsubscribeInstruments();
      }

      subscribeToNewInstruments(tableData.codes);
    }

    return () => {
      unsubscribeInstruments();
    };
  }, [
    selectedCategory,
    periodFilter,
    categoryFilter,
    codesComparison
  ]);

  // Subscription effect for normal categories
  useEffect(() => {
    if (
      selectedCategory === MarketsGroupName.Risers ||
      selectedCategory === MarketsGroupName.Fallers ||
      selectedCategory === MarketsGroupName.MostVolatile ||
      selectedCategory === MarketsGroupName.RisersSB ||
      selectedCategory === MarketsGroupName.FallersSB ||
      selectedCategory === MarketsGroupName.MostVolatileSB
    ) {
      return;
    }

    if (rowVirtualizer.isScrolling) {
      return;
    }

    const codes = rowVirtualizer.getVirtualItems().map((virtualRow) => rows[virtualRow.index].original.symbol);

    if (subIdRef.current) {
      unsubscribeInstruments();
    }

    subscribeToNewInstruments(codes);

    return () => {
      unsubscribeInstruments();
    };
  }, [
    virtualRows.length,
    rowVirtualizer.isScrolling,
    selectedCategory,
    currencyFilter,
    exchangeCountryCodeFilter,
    periodFilter,
    sectorFilter,
    categoryFilter,
    sorting[0]?.desc,
    sorting[0]?.id
  ]);

  if (loading || (!tableData.codes.length && !tableData.items.length && !emptyState)) {
    return (
      <div className={cn(styles.loadingContainer)}>
        <Loading/>
      </div>
    );
  }

  return (
    <div className={cn(styles.marketPageTableWrapper)}>
      {showSettingsModal && (
        <TableSettingsModal
          columns={table.getAllLeafColumns()}
          selectedCategory={selectedCategory}
          setShowSettingsModal={setShowSettingsModal}
        />
      )}

      <FilterBar
        selectedCategory={selectedCategory}
        selectedCategoryTranslatedLabel={selectedCategoryTranslatedLabel}
        marketItems={data[selectedCategory]}
      />

      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div style={{ direction: table.options.columnResizeDirection }}>
          <div
            ref={tableContainerRef}
            className={cn(styles.tableContainerVirtualStyles)}
            style={{
              height: `calc(100vh - ${newsPanelHeight}px - 85px)` // This needs to be like that in order to calculate height of the table dynamically based on the remaining height
            }}
          >
            <table
              {...{
                className: cn(styles.table)
              }}
            >
              <thead className={cn(styles.stickyHeader)}>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <tr key={headerGroup.id}>
                    <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                      {headerGroup.headers.map((header) => (
                        <DraggableTableHeader key={header.id} header={header} table={table}/>
                      ))}
                    </SortableContext>

                    {
                      <th
                        {...{
                          key: headerGroup.headers[headerGroup.headers.length - 1].id,
                          colSpan: headerGroup.headers[headerGroup.headers.length - 1].colSpan,
                          className: cn(styles.tableHeader),
                          style: {
                            width: headerGroup.headers[headerGroup.headers.length - 1].getSize()
                          }
                        }}
                      >
                        {headerGroup.headers[headerGroup.headers.length - 1].isPlaceholder ? null : (
                          <div
                            className={cn(styles.align_left)}
                            onClick={headerGroup.headers[
                            headerGroup.headers.length - 1
                              ].column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              headerGroup.headers[headerGroup.headers.length - 1].column.columnDef.header,
                              headerGroup.headers[headerGroup.headers.length - 1].getContext()
                            )}
                          </div>
                        )}

                        <div
                          {...{
                            onDoubleClick: () =>
                              headerGroup.headers[headerGroup.headers.length - 1].column.resetSize(),
                            onMouseDown: headerGroup.headers[headerGroup.headers.length - 1].getResizeHandler(),
                            onTouchStart: headerGroup.headers[headerGroup.headers.length - 1].getResizeHandler(),
                            className: `${styles.resizer} ${
                              table.options.columnResizeDirection === 'ltr' ? styles.ltr : styles.rtl
                            } ${
                              headerGroup.headers[headerGroup.headers.length - 1].column.getIsResizing() &&
                              styles.isResizing
                            }`
                          }}
                        />
                      </th>
                    }
                  </tr>
                );
              })}
              </thead>

              <tbody
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                  position: 'relative' //needed for absolute positioning of rows
                }}
              >
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<TableMarketItem>;

                return (
                  <tr
                    key={row.id}
                    data-index={virtualRow.index} //needed for dynamic row height measurement
                    className={cn(styles.tableRow)}
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                      width: '100%',
                      height: `${virtualRow.size}px` //this should always be a `style` as it changes on scroll
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      if (cell.column.id === 'settings') {
                        return (
                          <td
                            {...{
                              key: cell.id,
                              className: cn(styles.tableData),
                              style: {
                                textAlign: 'center'
                              },
                              onClick: () => handleSelectedInstrument(cell)
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      }

                      return (
                        <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
                          <DragAlongCell
                            key={cell.id}
                            cell={cell}
                            handleSelectedInstrument={handleSelectedInstrument}
                          />
                        </SortableContext>
                      );
                    })}
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </div>
      </DndContext>
    </div>
  );
};

export default MarketsTable;
