import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import DashboardContext from '../../../../../contexts/DashboardContext';

import authStore from '../../../../../store/authStore';
import pipStore from '../../../../../store/PipsStore/pipStore';
import orderStore from '../../../../../store/OrderStore/orderStore';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

import { getMarketItemPipSize } from '../../../../../utils/functions/calculations';
import { TradingPositionLimitType, TradingPositionSide } from '../../../../../utils/functions/enums';

import NumberInput from '../NumberInput';

import { MarketItem, TradingPositionState } from '../../../../../gateways/RfpGateway/rfp.types';

import styles from '../TradeTicket.module.scss';

interface LimitProfitLossProps {
	type: TradingPositionLimitType;
	value: number;
	openPrice: number;
	potentialProfitLoss: string;
	pnlTextColor: string;
	enabled: boolean;
	marketItem: MarketItem | undefined;
	error?: string;
	onEnabled: () => void;
	onChange: (value: number) => void;
}

const LimitProfitLoss = ({
	type,
	value,
	openPrice,
	potentialProfitLoss,
	pnlTextColor,
	enabled,
	marketItem,
	error,
	onEnabled,
	onChange,
}: LimitProfitLossProps) => {
	const { t } = useTranslation();

	const dashboardContext = useContext(DashboardContext);

	const current = orderStore.use.current();
	const tradingPosition = orderStore((store) => store.tradingPosition);
	const email = authStore.use.userProfile().email;
	const takeProfitPips = pipStore.use.takeProfit();
	const setTakeProfitPips = pipStore.use.setTakeProfitPips();
	const stopLossPips = pipStore.use.stopLoss();
	const setStopLossPips = pipStore.use.setStopLossPips();

	const [pipValue, setPipValue] = useState<number>(
		type === TradingPositionLimitType.TakeProfit ? takeProfitPips : stopLossPips
	);

	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isSignalOrder = orderStore.use.isSignalOrder();
	const [isPriceChanged, setIsPriceChanged] = useState<boolean>(false);
	const [isPipsFocused, setIsPipsFocused] = useState<boolean>(false);

	const updatePipStore = useCallback(
		(pips: number) => {
			if (type === TradingPositionLimitType.TakeProfit) {
				setTakeProfitPips(pips);
			}

			if (type === TradingPositionLimitType.StopLoss) {
				setStopLossPips(pips);
			}
		},
		[type, setTakeProfitPips, setStopLossPips]
	);

	const pipSize = useMemo(() => {
		return getMarketItemPipSize(marketItem);
	}, [marketItem]);

	const price = useMemo(() => {
		if (tradingPosition?.state === TradingPositionState.open) {
			return tradingPosition?.oP ? tradingPosition?.oP : 0;
		} else if (tradingPosition?.state === TradingPositionState.pending) {
			return tradingPosition?.prc ? tradingPosition?.prc : 0;
		}
		return openPrice;
	}, [tradingPosition, openPrice]);

	useEffect(() => {
		if (isPipsFocused || !enabled || !value) {
			return;
		}

		const pips = getPipsValue();

		setPipValue(pips);
		updatePipStore(pips);
	}, [value, isPipsFocused, updatePipStore]);

	useEffect(() => {
		const localPrefs = localStorage.getItem(`userPref-${email}`);

		if (!localPrefs || localPrefs === 'null') {
			return;
		}

		const parsedPrefs = JSON.parse(localPrefs);

		if (!parsedPrefs.user_prefs.trading.settings[dashboardContext.accountType].pips) {
			return;
		}

		const takeProfit = parsedPrefs.user_prefs.trading.settings[dashboardContext.accountType].pips.takeProfit;
		const stopLoss = parsedPrefs.user_prefs.trading.settings[dashboardContext.accountType].pips.stopLoss;

		if (!takeProfit || !stopLoss) {
			return;
		}

		updatePipStore(type === TradingPositionLimitType.TakeProfit ? takeProfit : stopLoss);
	}, [enabled, updatePipStore]);

	useEffect(() => {
		if (
			tradingPosition?.state === TradingPositionState.open ||
			tradingPosition?.state === TradingPositionState.pending
		) {
			enabled && setPipValue(getPipsValue());
		} else if (enabled && (isPriceChanged || isSignalOrder)) {
			const pips = getPipsValue();

			setPipValue(pips);

			// Don't update pip store if change came from a Signal
			if (!isSignalOrder) {
				updatePipStore(pips);
			}
		} else {
			handlePipValueChange(pipValue);
		}
	}, [price, isPriceChanged]);

	const handlePipValueChange = (pips: number) => {
		let result = 0;
		const isBuy = current.side === TradingPositionSide.Buy;
		const isTakeProfit = type === TradingPositionLimitType.TakeProfit;
		if (isTakeProfit) {
			if (isBuy) {
				result = price ? price + pips * pipSize : 0;
			} else {
				result = price ? price - pips * pipSize : 0;
			}
		} else {
			if (isBuy) {
				result = price ? price - pips * pipSize : 0;
			} else {
				result = price ? price + pips * pipSize : 0;
			}
		}

		onChange(result);
	};

	const getPipsValue = (): number => {
		let result;
		const takeProfit = current.takeProfitInfo?.price ? current.takeProfitInfo?.price : 0;
		const stopLoss = current.stopLossInfo?.price ? current.stopLossInfo?.price : 0;
		const isTakeProfit = type === TradingPositionLimitType.TakeProfit;

		if (current.side === TradingPositionSide.Buy) {
			result = isTakeProfit ? (takeProfit - price) / pipSize : (price - stopLoss) / pipSize;
		} else {
			result = isTakeProfit ? (price - takeProfit) / pipSize : (stopLoss - price) / pipSize;
		}

		return Math.round(result);
	};

	const handlePriceChange = (value: number) => {
		onChange(value);
		setIsPriceChanged(true);
	};

	const handlePipsChange = (pips: number) => {
		if (pips === undefined) {
			pips = 0;
		}
		setPipValue(pips);
		setIsPriceChanged(false);
		handlePipValueChange(pips);

		// Don't update pip store if change came from a Signal
		if (!isSignalOrder) {
			updatePipStore(pips);
		}
	};

	return (
		<div className={styles.fieldGroup}>
			<div className={styles.row}>
				{type === TradingPositionLimitType.TakeProfit && <div className={styles.label}>{t('en:TAKE_PROFIT')}</div>}
				{type === TradingPositionLimitType.StopLoss && <div className={styles.label}>{t('en:STOP_LOSS')}</div>}
				<div className={styles.toggleButton}>
					<Radio toggle checked={enabled} onChange={onEnabled} />
				</div>
			</div>

			{enabled && (
				<div className={styles.row}>
					<div>
						<div className={styles.fieldWrapper}>
							<p className={styles.label}>{t('en:PRICE')}</p>
							<NumberInput
								lotAware={false}
								incrementor={{ step: pipSize, max: 10000000 }}
								value={value}
								marketItem={marketItem}
								onChange={handlePriceChange}
								amountErrorClass={!!error}
							/>
						</div>
						<div
							onFocus={() => setIsPipsFocused(true)}
							onBlur={() => setIsPipsFocused(false)}
							className={styles.fieldWrapper}
						>
							<p className={styles.label}>{isSpreadBettingAccount ? t('en:POINTS') : t('en:PIPS')}</p>
							<NumberInput
								lotAware={false}
								incrementor={{ step: 1, min: -10000000, max: 10000000 }}
								value={pipValue}
								marketItem={marketItem}
								onChange={handlePipsChange}
								amountErrorClass={!!error}
								allowNegativeValues={true}
							/>
						</div>
						<div className={styles.fieldWrapper}>
							<div className={cn(styles.label, styles.subLabel)}>
								{type === TradingPositionLimitType.TakeProfit && t('wtr:POTENTITAL_PROFIT')}
								{type === TradingPositionLimitType.StopLoss && t('wtr:POTENTITAL_LOSS')}
							</div>
							<div className={cn(styles.subText, pnlTextColor)}>
								{error ? <span className={styles.errorValidation}>{error}</span> : potentialProfitLoss}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LimitProfitLoss;
