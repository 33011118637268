import { default as protobuf } from 'protobufjs';
import { expose } from 'threads/worker';
import { Observable, Subject } from 'threads/observable';

import { default as TypeUtils } from '../utils/functions/TypeUtils';
import { default as Nullable, Optional } from '../utils/functions/Nullable';
import { default as Lazy } from '../utils/functions/Lazy';

import { default as RfpBinaryMessages } from '../gateways/RfpGateway/RfpBinaryMessages';
import { default as RfpBinaryMessagesJson } from '../gateways/RfpGateway/RfpBinaryMessages.json';
import { IWrapperMessage } from '../gateways/RfpGateway/BinaryRfpGateway';

import { WorkerModule } from './index';

export enum BinaryRfpClientWorkerState {
	disconnected = 'disconnected',
	connected = 'connected',
	error = 'error',
	connectionTimeout = 'connectionTimeout',
}

class ProtobufSchema {
	private static readonly _jsonDefinitions = new Lazy(() => RfpBinaryMessagesJson);
	private static readonly _root = new Lazy(() => protobuf.Root.fromJSON(ProtobufSchema._jsonDefinitions.getValue()));
	private static readonly _types = new Lazy(() => {
		const root = ProtobufSchema._root.getValue();
		return {
			WrapperMessage: root.lookupType(
				'com.thinkmarkets.webtrader.protobuf.v1.WrapperMessage'
			) as unknown as typeof RfpBinaryMessages.com.thinkmarkets.webtrader.protobuf.v1.WrapperMessage,
		} as const;
	});

	public static get jsonDefinitions(): any {
		return this._jsonDefinitions;
	}

	public static get root(): ReturnType<typeof ProtobufSchema['_root']['getValue']> {
		return this._root.getValue();
	}

	public static get types(): ReturnType<typeof ProtobufSchema['_types']['getValue']> {
		return this._types.getValue();
	}

	private constructor() {}
}

const worker = (() => {
	class BinaryRfpClientWorker {
		private m_socketClient: Optional<WebSocket> = null;
		private m_state: BinaryRfpClientWorkerState = BinaryRfpClientWorkerState.disconnected;
		private m_stateStream: Subject<BinaryRfpClientWorkerState> = new Subject<BinaryRfpClientWorkerState>();
		private m_debugStream: Subject<any[]> = new Subject<any[]>();
		private m_dataStream: Optional<Subject<IWrapperMessage>> = null;
		private m_subscriptionStreams: Map<IWrapperMessage['commandId'], Subject<IWrapperMessage>> = new Map<
			IWrapperMessage['commandId'],
			Subject<IWrapperMessage>
		>();

		private m_appInBackgroundMode: boolean = false;
		private m_lastInBackgroundModeTimestamp: number = -1;

		private get state(): BinaryRfpClientWorkerState {
			return this.m_state;
		}

		public setIsInBackgroundMode(value: boolean) {
			this.m_lastInBackgroundModeTimestamp = value ? performance.now() : -1;
			this.m_appInBackgroundMode = value;
		}

		private set state(value: BinaryRfpClientWorkerState) {
			this.m_state = value;
			this.m_stateStream.next(value);
		}

		public stateStream(): Observable<BinaryRfpClientWorkerState> {
			return Observable.from(this.m_stateStream);
		}

		public debugStream(): Observable<any[]> {
			return Observable.from(this.m_debugStream);
		}

		public connect(url: string): Promise<void> {
			return new Promise((resolve, reject) => {
				const client = new WebSocket(url);
				const connectionTimeoutMilliseconds = parseInt(process.env.REACT_APP_WEBSOCKET_CONNECTION_TIMEOUT!);
				const pongTimeoutMilliseconds = parseInt(process.env.REACT_APP_PONG_MESSAGE_TIMEOUT!);

				// Ping-pong / Heartbeat system to monitor WebSocket connection:
				let connectionTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000);
				let pongTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000);

				let lastFrameTime: number = 0;
				let isMonitoringMainThread = false;
				const mainThreadCheckThreshold = 20000; // 20000 milliseconds (20 second)

				const checkMainThread = () => {
					const currentTime = performance.now();
					const elapsed = currentTime - lastFrameTime;

					// Check if the main thread hasn't updated in a certain threshold (e.g., 100 milliseconds)
					if (elapsed > mainThreadCheckThreshold) {
						// Main thread is likely blocked, take necessary actions
						console.error('Main thread is blocked!');

						this.reconnect();
					}

					lastFrameTime = currentTime;

					// Schedule the next check on the next animation frame
					if (isMonitoringMainThread) {
						requestAnimationFrame(checkMainThread);
					}
				};

				const resetConnectionTimeout = () => {
					// console.debug('resetConnectionTimeout')
					clearTimeout(connectionTimeout);
					clearTimeout(pongTimeout);

					// 1. Define a connection timeout and send a ping message if no other messages are received after REACT_APP_WEBSOCKET_CONNECTION_TIMEOUT period of time
					connectionTimeout = setTimeout(() => {
						const pingRequest = ProtobufSchema.types.WrapperMessage.fromObject({
							commandId: ProtobufSchema.types.WrapperMessage.Command.PING,
							data: null,
						});
						this.send(pingRequest);
						// console.debug('send ping')

						// 2. Define a pong timeout in order to take action if pong is not received after REACT_APP_PONG_MESSAGE_TIMEOUT period of time-> connection is interrupted
						pongTimeout = setTimeout(() => {
							// console.debug('reconnect')

							this.reconnect();
						}, pongTimeoutMilliseconds);
					}, connectionTimeoutMilliseconds);
				};
				client.binaryType = 'arraybuffer';
				client.onopen = (event) => {
					this.m_socketClient = client;
					this.state = BinaryRfpClientWorkerState.connected;
					client.onopen = null;

					// Start checking the main thread
					isMonitoringMainThread = true;
					lastFrameTime = performance.now();
					checkMainThread();
					// resetConnectionTimeout()
					resolve();
				};
				client.onmessage = (event) => {
					if (event.data instanceof ArrayBuffer) {
						let uint8ArrData;
						uint8ArrData = new Uint8Array(event.data);
						const message = ProtobufSchema.types.WrapperMessage.decode(uint8ArrData);
						uint8ArrData = null;

						checkMainThread();

						if (this.m_lastInBackgroundModeTimestamp > 0) {
							// 30 min background mode inactivity will trigger reconnect
							if (performance.now() - this.m_lastInBackgroundModeTimestamp > 1800000) {
								console.log('Disconnect because of inactivity');

								// Reset last timestamp
								this.m_lastInBackgroundModeTimestamp = performance.now();

								this.reconnect();
								return;
							}
						}

						// let shouldSendMessage = true;
						// if (this.m_appInBackgroundMode && message.commandId === ProtobufSchema.types.WrapperMessage.Command.QUOTE) {
						// 	shouldSendMessage = false;
						// }

						// if (shouldSendMessage) {
						// const commandName = ProtobufSchema.types.WrapperMessage.Command[message.commandId];
						// console.debug(`${Date.now()} Received command=${commandName}`);
						if (this.m_dataStream) {
							this.m_dataStream.next(message);
						}

						const stream = this.m_subscriptionStreams.get(message.commandId);
						if (stream) {
							stream.next(message);
						}
						// }
					}
					// Activate the ping-pong logic, call resetConnectionTimeout and clear timeouts if any message is received from RFP (connection is OK).
					resetConnectionTimeout();
				};
				client.onerror = (event) => {
					client.onerror = null;
					isMonitoringMainThread = false;
					this.state = BinaryRfpClientWorkerState.error;

					// Clear connection timeouts on WebSocket error e.g. network connection is down
					// When network connection is down we have listeners in the React ReconnectModal component which trigger the reconnection logic,
					// so we don't need to trigger context updates here.
					clearTimeout(connectionTimeout);
					clearTimeout(pongTimeout);
					reject('WebSocket error');
				};
				client.onclose = (event) => {
					client.onclose = null;

					isMonitoringMainThread = false;
					this.state = BinaryRfpClientWorkerState.disconnected;

					// Clear connection timeouts on WebSocket error e.g. network connection is down
					// When network connection is down we have listeners in the React ReconnectModal component which trigger the reconnection logic,
					// so we don't need to trigger context updates here.
					clearTimeout(connectionTimeout);
					clearTimeout(pongTimeout);
					resolve();
				};
			});
		}

		public send(message: IWrapperMessage): Promise<void> {
			return new Promise((resolve, reject) => {
				try {
					this.requireConnectedClient((client) => {
						message.time = message.time || Math.floor(Date.now() / 1000);
						const bytes = ProtobufSchema.types.WrapperMessage.encode(message).finish();
						client.send(bytes);
						resolve();
					});
				} catch (err) {
					reject(err);
				}
			});
		}

		public subscribeForData(): Observable<IWrapperMessage> {
			return Nullable.of(this.m_socketClient)
				.map((client) => {
					const stream = Nullable.of(this.m_dataStream).orElseGet(() => {
						this.m_dataStream = new Subject<IWrapperMessage>();
						return this.m_dataStream;
					});
					return Observable.from(stream);
				})
				.getOrThrow(() => new Error('Not connected'));
		}

		public unsubscribeForData(): Promise<void> {
			return new Promise((resolve, reject) => {
				try {
					Nullable.of(this.m_dataStream).run((stream) => {
						stream.complete();
						this.m_dataStream = null;
					});
					resolve();
				} catch (err) {
					reject(err);
				}
			});
		}

		public subscribe(command: IWrapperMessage['commandId']): Observable<IWrapperMessage> {
			return Nullable.of(this.m_socketClient)
				.map((client) => {
					const stream = Nullable.of(this.m_subscriptionStreams.get(command)).orElseGet(() => {
						const stream = new Subject<IWrapperMessage>();
						this.m_subscriptionStreams.set(command, stream);
						return stream;
					});
					return Observable.from(stream);
				})
				.getOrThrow(() => new Error('Not connected'));
		}

		public unsubscribe(command: IWrapperMessage['commandId']): Promise<void> {
			return new Promise((resolve, reject) => {
				try {
					Nullable.of(this.m_subscriptionStreams.get(command)).run((stream) => {
						stream.complete();
						this.m_subscriptionStreams.delete(command);
					});
					resolve();
				} catch (err) {
					reject(err);
				}
			});
		}

		public reconnect() {
			// Disconnect socket to perform some actions as needed
			this.disconnect();

			// Update the state with connection timeout error in order to notify BinaryRfpGateway, update App context and start reconnection attempts
			this.state = BinaryRfpClientWorkerState.connectionTimeout;
		}

		public disconnect(): Promise<void> {
			return new Promise((resolve, reject) => {
				try {
					this.requireConnectedClient(
						(client) => client.close(),
						() => {}
					);
					this.m_socketClient = null;
					this.state = BinaryRfpClientWorkerState.disconnected;
					resolve();
				} catch (err) {
					reject(err);
				}
			});
		}

		private requireConnectedClient(
			connectedAction: (client: WebSocket) => any,
			disconnectedAction?: (() => any) | Error | string
		): void {
			if (this.m_socketClient && this.m_socketClient.readyState === WebSocket.OPEN) {
				connectedAction(this.m_socketClient);
			} else {
				disconnectedAction = disconnectedAction || 'Client socket is not connected';
				if (typeof disconnectedAction === 'string') {
					throw new Error(disconnectedAction);
				} else if (typeof disconnectedAction === 'function') {
					disconnectedAction();
				} else if (
					TypeUtils.is<Error>(disconnectedAction, (subject) => subject.name !== null && subject.message !== null)
				) {
					throw disconnectedAction;
				}
			}
		}

		private debug(...args: any[]): void {
			try {
				//console.debug(...args)
				args = args.map((value) => (typeof value !== 'string' ? this.stripObjectFunctions(value) : value));
				this.m_debugStream.next(args);
			} catch (err) {}
		}

		private stripObjectFunctions<T = any>(value: T): T {
			const val: any = {};
			TypeUtils.propertyNames(value, true)
				.filter((key) => typeof (value as any)[key] !== 'function')
				.forEach((key) => (val[key] = (value as any)[key]));
			return val;
		}

		public static createWorkerModule(): WorkerModule<BinaryRfpClientWorker> {
			const rfpClient = new BinaryRfpClientWorker();
			const worker: any = {};
			TypeUtils.propertyNames(rfpClient, true)
				.filter((key) => typeof (rfpClient as any)[key] === 'function')
				.forEach((key) => (worker[key] = (rfpClient as any)[key].bind(rfpClient)));
			return worker;
		}
	}

	return BinaryRfpClientWorker.createWorkerModule();
})();

export type BinaryRfpClientWorker = typeof worker;

// eslint-disable-next-line eqeqeq
if (globalThis.window == null) {
	expose(worker);
}
