import React, { FC, useCallback, useContext, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../../../../contexts/AppContext';

import quoteStore from '../../../../../store/QuoteStore/quoteStore';
import marketTableFilterStore, {
  ALL_STRING,
  Period
} from '../../../../../store/MarketTableFilterStore/marketTableFilterStore';

import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import { MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';

import { translateMarketTerm } from '../../MarketsGrid/utils';

import { MarketsGroupName } from '../../../../../utils/functions/enums';

import styles from '../MarketsTable.module.scss';

interface FilterBarProps {
  selectedCategory: string;
  selectedCategoryTranslatedLabel: string;
  marketItems?: MarketItem[];
}

const FilterBar: FC<FilterBarProps> = ({
  selectedCategory,
  selectedCategoryTranslatedLabel,
  marketItems = []
}) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const isArabic = appContext.isArabic;

  const sector = marketTableFilterStore.use.sector();
  const setSector = marketTableFilterStore.use.setSector();
  const isSectorOpen = marketTableFilterStore.use.isSectorOpen();
  const toggleOpenSector = marketTableFilterStore.use.toggleOpenSector();

  const exchangeCountryCode = marketTableFilterStore.use.exchangeCountryCode();
  const setExchangeCountryCode = marketTableFilterStore.use.setExchangeCountryCode();
  const isExchangeCountryCodeOpen = marketTableFilterStore.use.isExchangeCountryCodeOpen();
  const toggleOpenExchangeCountryCode = marketTableFilterStore.use.toggleOpenExchangeCountryCode();

  const currency = marketTableFilterStore.use.currency();
  const setCurrency = marketTableFilterStore.use.setCurrency();
  const isCurrencyOpen = marketTableFilterStore.use.isCurrencyOpen();
  const toggleOpenCurrency = marketTableFilterStore.use.toggleOpenCurrency();

  const category = marketTableFilterStore.use.category();
  const setCategory = marketTableFilterStore.use.setCategory();
  const isCategoryOpen = marketTableFilterStore.use.isCategoryOpen();
  const toggleOpenCategory = marketTableFilterStore.use.toggleOpenCategory();

  const period = marketTableFilterStore.use.period();
  const setPeriod = marketTableFilterStore.use.setPeriod();
  const isPeriodOpen = marketTableFilterStore.use.isPeriodOpen();
  const toggleOpenPeriod = marketTableFilterStore.use.toggleOpenPeriod();

  const closeAllFilters = marketTableFilterStore.use.closeAllFilters();
  const setMaxVolatility = quoteStore.use.setMaxVolatility();

  // Sectors list
  const sectors = useMemo(() => {
    if (!marketItems || !marketItems.length) {
      return [];
    }

    return Array.from(
      new Set(
        marketItems
          .reduce((all, item) => {
            if (!item.sector || typeof item.sector !== 'string') {
              return all;
            }

            all.push(item.sector);
            return all;
          }, [] as string[])))
      .sort((a: any, b: any) => a.localeCompare(b));
  }, [selectedCategory, marketItems.length]);

  // Exchange country codes list
  const exchangeCountryCodes = useMemo(() => {
    if (!marketItems || !marketItems.length) {
      return [];
    }

    return Array.from(
      new Set(
        marketItems
          .reduce((all, item) => {
            if (!item.exchangeCountryCode || typeof item.exchangeCountryCode !== 'string') {
              return all;
            }

            all.push(item.exchangeCountryCode);
            return all;
          }, [] as string[])))
      .sort((a: any, b: any) => a.localeCompare(b));
  }, [selectedCategory, marketItems.length]);

  // Currencies list
  const currencies = useMemo(() => {
    if (!marketItems || !marketItems.length) {
      return [];
    }

    return Array.from(
      new Set(
        marketItems
          .map((item) => item.ccy)))
      .sort((a, b) => a.localeCompare(b));
  }, [selectedCategory, marketItems.length]);

  // Categories list
  const categories = useMemo(() => {
    if (
      selectedCategory !== MarketsGroupName.Risers &&
      selectedCategory !== MarketsGroupName.Fallers &&
      selectedCategory !== MarketsGroupName.MostVolatile &&
      selectedCategory !== MarketsGroupName.RisersSB &&
      selectedCategory !== MarketsGroupName.FallersSB &&
      selectedCategory !== MarketsGroupName.MostVolatileSB
    ) {
      return [];
    }

    return ['All', 'ETF', 'Commodities', 'Crypto', 'Energy', 'Equities', 'Forex', 'Indices', 'Metals'];
  }, [selectedCategory]);

  // Periods list
  const periods = useMemo(() => {
    if (
      selectedCategory !== MarketsGroupName.Risers &&
      selectedCategory !== MarketsGroupName.Fallers &&
      selectedCategory !== MarketsGroupName.MostVolatile &&
      selectedCategory !== MarketsGroupName.RisersSB &&
      selectedCategory !== MarketsGroupName.FallersSB &&
      selectedCategory !== MarketsGroupName.MostVolatileSB
    ) {
      return [];
    }

    return ['Daily', 'Weekly'];
  }, [selectedCategory]);

  // Popup position
  const popupPosition = useMemo(() => {
    return isArabic ? 'bottom left' : 'bottom right';
  }, [isArabic]);

  // Close filters on outside click
  const onClickOutside = useCallback(() => {
    closeAllFilters();
  }, [closeAllFilters]);

  // Set sector
  const onClickSetSector = useCallback((value) => {
    setSector(value);
    toggleOpenSector();
  }, [setSector, toggleOpenSector]);

  // Set exchange country code
  const onClickSetExchangeCountry = useCallback((value) => {
    setExchangeCountryCode(value);
    toggleOpenExchangeCountryCode();
  }, [setExchangeCountryCode, toggleOpenExchangeCountryCode]);

  // Set currency
  const onClickSetCurrency = useCallback((value) => {
    setCurrency(value);
    toggleOpenCurrency();
  }, [setCurrency, toggleOpenCurrency]);

  // Set currency
  const onClickSetCategory = useCallback((value) => {
    setMaxVolatility(1);
    setCategory(value);
    toggleOpenCategory();
  }, [setCategory, toggleOpenCategory, setMaxVolatility]);

  // Set period
  const onClickSetPeriod = useCallback((value) => {
    setMaxVolatility(1);
    setPeriod(value);
    toggleOpenPeriod();
  }, [setPeriod, toggleOpenPeriod, setMaxVolatility]);

  // Sectors JSX map
  const sectorsJSXMap = useMemo(() =>
    sectors.map((value) => (
      <div key={value} className={cn(styles.listContainer)}>
        <div className={cn(styles.itemContainer)}>
          <div
            className={cn({
              [styles.item]: true,
              [styles.bold]: sector === value
            })}
            onClick={() => onClickSetSector(value)}
          >
            <span>{translateMarketTerm('sector', value)}</span>
          </div>
        </div>
      </div>
    )), [sectors.length, sector]);

  // Exchange countries JSX map
  const exchangeCountriesJSXMap = useMemo(() =>
    exchangeCountryCodes.map((value) => (
      <div key={value} className={cn(styles.listContainer)}>
        <div className={cn(styles.itemContainer)}>
          <div
            className={cn({
              [styles.item]: true,
              [styles.bold]: exchangeCountryCode === value
            })}
            onClick={() => onClickSetExchangeCountry(value)}
          >
            <span>{translateMarketTerm('countrycode', value)}</span>
          </div>
        </div>
      </div>
    )), [exchangeCountryCodes.length, exchangeCountryCode]);

  // Currencies JSX map
  const currenciesJSXMap = useMemo(() =>
    currencies.map((value) => (
      <div key={value} className={cn(styles.listContainer)}>
        <div className={cn(styles.itemContainer)}>
          <div
            className={cn({
              [styles.item]: true,
              [styles.bold]: currency === value
            })}
            onClick={() => onClickSetCurrency(value)}
          >
            <span>{value}</span>
          </div>
        </div>
      </div>
    )), [currencies.length, currency]);

  // Category JSX map
  const categoriesJSXMap = useMemo(() =>
    categories.map((value) => (
      <div key={value} className={cn(styles.listContainer)}>
        <div className={cn(styles.itemContainer)}>
          <div
            className={cn({
              [styles.item]: true,
              [styles.bold]: category === value
            })}
            onClick={() => onClickSetCategory(value)}
          >
            <span>{translateMarketTerm('category', value)}</span>
          </div>
        </div>
      </div>
    )), [categories.length, category]);

// Period JSX map
  const periodsJSXMap = useMemo(() =>
    periods.map((value) => (
      <div key={value} className={cn(styles.listContainer)}>
        <div className={cn(styles.itemContainer)}>
          <div
            className={cn({
              [styles.item]: true,
              [styles.bold]: period === value
            })}
            onClick={() => onClickSetPeriod(value)}
          >
            <span>{value === Period.Daily ? t('wtr:DAILY') : t('wtr:WEEKLY')}</span>
          </div>
        </div>
      </div>
    )), [periods.length, period]);

  return (
    <div className={cn(styles.header)}>
      <label className={cn(styles.label)}>
        {selectedCategoryTranslatedLabel}
      </label>

      {/*SECTOR*/}
      <div>
        <div
          className={cn({
            [styles.filterOptionGroup]: sectors.length > 1,
            [styles.hidden]: sectors.length <= 1
          })}
        >
          <span className={cn(styles.filterLabel)}>{t('wtr:SECTOR')}</span>
          <WtrPopup
            className={cn(styles.Popup)}
            on={['click']}
            pinned
            position={popupPosition}
            open={isSectorOpen}
            onClose={onClickOutside}
            trigger={
              <span
                className={cn(styles.filterControl)}
                onClick={() => toggleOpenSector()}
              >
        					{sector !== ALL_STRING
                    ? translateMarketTerm('sector', sector)
                    : t('wtr:ALL_SECTORS')}{' '}
                &nbsp;
                <FontAwesomeIcon icon={['fas', 'caret-down']} size="1x"/>
        				</span>
            }
            content={
              <>
                <div className={cn(styles.filterList)}>
                  <div className={cn(styles.listContainer)}>
                    <div className={cn(styles.itemContainer)}>
                      <div
                        className={cn(styles.item)}
                        onClick={() => onClickSetSector(ALL_STRING)}
                      >
        									<span
                            className={
                              cn({
                                [styles.bold]: sector === ALL_STRING
                              })}
                          >
        										{t('wtr:ALL_SECTORS')}
        									</span>
                      </div>
                    </div>
                  </div>
                  {sectorsJSXMap}
                </div>
              </>
            }
          />
        </div>

        {/*EXCHANGE COUNTRY*/}
        <div
          className={cn({
            [styles.filterOptionGroup]: exchangeCountryCodes.length > 1,
            [styles.hidden]: exchangeCountryCodes.length <= 1
          })}
        >
          <span className={cn(styles.filterLabel)}>{t('wtr:EXCHANGE_COUNTRY')}</span>
          <WtrPopup
            className={cn(styles.Popup)}
            on={['click']}
            pinned
            position="bottom center"
            open={isExchangeCountryCodeOpen}
            onClose={onClickOutside}
            trigger={
              <span
                className={cn(styles.filterControl)}
                onClick={() => toggleOpenExchangeCountryCode()}
              >
                {
                  (exchangeCountryCode !== ALL_STRING
                      ? translateMarketTerm('countrycode', exchangeCountryCode)
                      : t('wtr:ALL_EXCHANGES')
                  )
                }{' '}
                &nbsp;
                <FontAwesomeIcon icon={['fas', 'caret-down']} size="1x"/>
        				</span>
            }
            content={
              <>
                <div className={cn(styles.filterList)}>
                  <div className={cn(styles.listContainer)}>
                    <div className={cn(styles.itemContainer)}>
                      <div
                        className={cn(styles.item)}
                        onClick={() => onClickSetExchangeCountry(ALL_STRING)}
                      >
        									<span
                            className={
                              cn({
                                [styles.bold]: exchangeCountryCode === ALL_STRING
                              })
                            }
                          >
        										{t('wtr:ALL_EXCHANGES')}
        									</span>
                      </div>
                    </div>
                  </div>
                  {exchangeCountriesJSXMap}
                </div>
              </>
            }
          />
        </div>

        {/*CURRENCY*/}
        <div
          className={cn({
            [styles.filterOptionGroup]: currencies.length > 1,
            [styles.hidden]: currencies.length <= 1
          })}
        >
          <span className={cn(styles.filterLabel)}>{t('en:CURRENCY')}</span>
          <WtrPopup
            className={cn(styles.Popup)}
            on={['click']}
            pinned
            position={popupPosition}
            open={isCurrencyOpen}
            onClose={onClickOutside}
            trigger={
              <span
                className={cn(styles.filterControl)}
                onClick={() => toggleOpenCurrency()}
              >
        					{
                    (currency !== ALL_STRING
                        ? currency
                        : translateMarketTerm('category', 'All')
                    )
                  }{' '}
                &nbsp;
                <FontAwesomeIcon icon={['fas', 'caret-down']} size="1x"/>
        				</span>
            }
            content={
              <>
                <div className={cn(styles.filterList)}>
                  <div className={cn(styles.listContainer)}>
                    <div className={cn(styles.itemContainer)}>
                      <div
                        className={cn(styles.item)}
                        onClick={() => onClickSetCurrency(ALL_STRING)}
                      >
        									<span
                            className={
                              cn({
                                [styles.bold]: currency === ALL_STRING
                              })}
                          >
        										{t('wtr:ALL_CURRENCIES')}
        									</span>
                      </div>
                    </div>
                  </div>
                  {currenciesJSXMap}
                </div>
              </>
            }
          />
        </div>

        {/*CATEGORY*/}
        <div
          className={cn({
            [styles.filterOptionGroup]: categories.length > 1,
            [styles.hidden]: categories.length <= 1
          })}
        >
          <span className={styles.filterLabel}>{t('wtr:CATEGORY')}</span>
          <WtrPopup
            className={cn(styles.Popup)}
            on={['click']}
            pinned
            position={popupPosition}
            open={isCategoryOpen}
            onClose={onClickOutside}
            trigger={
              <span
                className={cn(styles.filterControl)}
                onClick={() => toggleOpenCategory()}
              >
                {
                  translateMarketTerm('category', category)
                }{' '}
                &nbsp;
                <FontAwesomeIcon icon={['fas', 'caret-down']} size="1x"/>
        				</span>
            }
            content={
              <>
                <div className={cn(styles.filterList)}>
                  {categoriesJSXMap}
                </div>
              </>
            }
          />
        </div>

        {/*Period*/}
        <div
          className={cn({
            [styles.filterOptionGroup]: periods.length > 1,
            [styles.hidden]: periods.length <= 1
          })}
        >
          <span className={styles.filterLabel}>{t('wtr:PERIOD')}</span>
          <WtrPopup
            className={cn(styles.Popup)}
            on={['click']}
            pinned
            position={popupPosition}
            open={isPeriodOpen}
            onClose={onClickOutside}
            trigger={
              <span
                className={cn(styles.filterControl)}
                onClick={() => toggleOpenPeriod()}
              >
        					{period === Period.Daily
                    ? t('wtr:DAILY')
                    : t('wtr:WEEKLY')}{' '}
                &nbsp;
                <FontAwesomeIcon icon={['fas', 'caret-down']} size="1x"/>
        				</span>
            }
            content={
              <>
                <div className={cn(styles.filterList)}>
                  {periodsJSXMap}
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FilterBar;