import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import Content from './Content';
import RfpGatewayContext from './contexts/RfpGatewayContext';
import RfpGateway from './gateways/RfpGateway/RfpGateway';
import { Resolver } from './utils/functions/Ioc';
import usePageVisibility from './utils/hooks/usePageVisibility';
import Routes from './setup/routes';

const queryClient = new QueryClient();

const App = () => {
	const history = useHistory();
	const location = history.location;

	const rfpGateway = Resolver.resolve(RfpGateway);
	// the "Content" component was created because the caching didn't work due to the renders of the app component.
	// It will also be used for future refactoring.

	// Workaround until WTR-4682 get fixed
	useEffect(() => {
		if (location.pathname === Routes.account.forgotPassword) {
			history.push(Routes.account.forgotPassword);
		}
	}, []);

	const pageHiddenStatus = usePageVisibility();
	useEffect(() => {
		if (rfpGateway) {
			if (pageHiddenStatus) {
				console.log('App is in background');
				rfpGateway.setAppInBackgroundMode = true;
			} else {
				console.log('App is visible');
				rfpGateway.setAppInBackgroundMode = false;
			}
		}
	}, [pageHiddenStatus]);

	return (
		<QueryClientProvider client={queryClient}>
			<RfpGatewayContext.Provider value={rfpGateway}>
				<Content />
			</RfpGatewayContext.Provider>
		</QueryClientProvider>
	);
};

export default App;
