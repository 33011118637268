import { immer } from 'zustand/middleware/immer';

import {
	EditOrderRequest,
	EditPositionRequest,
	Instrument,
	MarketItem,
	NewOrderRequest,
	NewPositionRequest,
	PriceQuote,
	TradingPosition,
	TradingPositionState,
} from '../../gateways/RfpGateway/rfp.types';

import { TradingPositionLimitType, TradingPositionSide, TradingPositionType } from '../../utils/functions/enums';

import { create } from '../create';
import createSelectors from '../createSelectors';

import { GymTradingPosition } from '../../pages/TradersGym/Positions/GymTradingPosition';

import { initialState } from './orderStore.initial';
import {
	ActionType,
	IAmountInfo,
	IPriceInfo,
	ITradeObjProps,
	Order,
	OrderStore,
	OrderStoreValues,
} from './orderStore.types';
import { compareLimits } from './orderStore.helpers';
import { getMarketItemPipSize } from '../../utils/functions/calculations';

const orderStore = create<OrderStore>()(
	immer((set: any, get: any) => ({
		...initialState,
		reset: () =>
			set((state: OrderStoreValues) => {
				return (state = initialState);
			}),

		resetExceptSide: () =>
			set((state: OrderStoreValues) => {
				const { side } = state.current;

				state = { ...initialState };
				state.current = { ...state.current, side };

				return state;
			}),

		setAction: (action: ActionType) => {
			set((state: OrderStoreValues) => {
				state.action = action;
			});
		},

		setTradingPosition: (tradingPosition: TradingPosition | undefined) => {
			set((state: OrderStoreValues) => {
				state.tradingPosition = tradingPosition ? { ...tradingPosition } : tradingPosition;
			});
		},

		setTradingInstrument: (tradingInstrument: Instrument | undefined) => {
			set((state: OrderStore) => {
				state.tradingInstrument = tradingInstrument ? { ...tradingInstrument } : tradingInstrument;
			});
		},

		setCurrentQuote: (currentQuote: PriceQuote | undefined) => {
			set((state: OrderStore) => {
				state.currentQuote = currentQuote ? { ...currentQuote } : currentQuote;
			});
		},

		setMarketItem: (marketItem: MarketItem | undefined) => {
			set((state: OrderStoreValues) => {
				// create object copy to not be passed by reference

				state.marketItem = marketItem ? { ...marketItem } : marketItem;
			});
		},

		setTradeProps: (values: Order) => {
			set((state: OrderStore) => {
				state.current = { ...state.current, ...values };
			});
		},

		setAmountInfo: (amountInfo: IAmountInfo) => {
			set((state: OrderStore) => {
				state.current.amountInfo = { ...state.current.amountInfo, ...amountInfo };
			});
		},

		setOpenPriceInfo: (openPriceInfo: IPriceInfo) => {
			set((state: OrderStore) => {
				state.current.openPriceInfo = { ...state.current.openPriceInfo, ...openPriceInfo };
			});
		},

		setTakeProfitInfo: (takeProfitInfo: IPriceInfo) => {
			set((state: OrderStore) => {
				state.current.takeProfitInfo = { ...state.current.takeProfitInfo, ...takeProfitInfo };
			});
		},

		setStopLossInfo: (stopLossInfo: IPriceInfo) => {
			set((state: OrderStore) => {
				state.current.stopLossInfo = { ...state.current.stopLossInfo, ...stopLossInfo };
			});
		},

		setIsSignalOrder: (isSignalOrder: boolean) => {
			set((state: OrderStore) => {
				state.isSignalOrder = isSignalOrder;
			});
		},

		fillStoreFromPosition: (
			tradingPosition: TradingPosition,
			limitsType?: TradingPositionLimitType[],
			isPositionDraggingDisabled?: boolean,
			pips?: { takeProfitPips: number; stopLossPips: number }
		) => {
			const position = get().tradingPosition;
			const limitsOnly = get().current;
			if (position && tradingPosition.posId === position.posId && compareLimits(limitsOnly, limitsType)) {
				return;
			}
			set((state: OrderStoreValues) => {
				return (state = { ...initialState });
			});
			const { marketItem, ...restTradingPosition } = tradingPosition;

			if (tradingPosition instanceof GymTradingPosition) {
				set((state: OrderStoreValues) => {
					state.tradingPosition = tradingPosition;
				});
			} else {
				set((state: OrderStoreValues) => {
					state.tradingPosition = { ...restTradingPosition };
				});
			}

			set((state: OrderStoreValues) => {
				if (state.tradingPosition !== undefined) {
					state.tradingPosition.marketItem = marketItem ? { ...marketItem } : undefined;
				}
			});

			set((state: OrderStoreValues) => {
				state.marketItem = tradingPosition.marketItem ? { ...tradingPosition.marketItem } : tradingPosition.marketItem;
			});

			set((state: OrderStoreValues) => {
				state.current = {
					...state.current,
					side: tradingPosition.side,
					type: tradingPosition.type,
					date: tradingPosition.eT,
					limitsOnly: limitsType,
					isPositionDraggingDisabled: isPositionDraggingDisabled,
				};
			});
			set((state: OrderStoreValues) => {
				if (state.current.amountInfo !== undefined) {
					state.current.amountInfo.amount = tradingPosition.qty;
				}
			});
			set((state: OrderStoreValues) => {
				if (tradingPosition.state === TradingPositionState.open) {
					state.current.openPriceInfo = { ...state.current.openPriceInfo, price: tradingPosition.oP, isActive: true };
				} else if (tradingPosition.state === TradingPositionState.pending) {
					state.current.openPriceInfo = { ...state.current.openPriceInfo, price: tradingPosition.prc, isActive: true };
				}
			});

			let stopLoss = tradingPosition.sl ? (isNaN(tradingPosition.sl) ? undefined : tradingPosition.sl) : undefined;

			let takeProfit = tradingPosition.tp ? (isNaN(tradingPosition.tp) ? undefined : tradingPosition.tp) : undefined;

			set((state: OrderStoreValues) => {
				if (takeProfit || limitsType?.includes(TradingPositionLimitType.TakeProfit)) {
					if (!takeProfit && tradingPosition.currentPrice && pips?.takeProfitPips) {
						const mul = tradingPosition.side === TradingPositionSide.Buy ? 1 : -1;
						takeProfit = tradingPosition.currentPrice + pips.takeProfitPips * getMarketItemPipSize(marketItem) * mul;
					}

					state.current.takeProfitInfo = {
						...state.current.takeProfitInfo,
						isActive: true,
						price: takeProfit,
					};
				} else {
					state.current.takeProfitInfo = {
						...state.current.takeProfitInfo,
						isActive: false,
						price: undefined,
						potentialProfitLoss: undefined,
						error: undefined,
					};
				}
			});

			set((state: OrderStoreValues) => {
				if (stopLoss || limitsType?.includes(TradingPositionLimitType.StopLoss)) {
					if (!stopLoss && tradingPosition.currentPrice && pips?.stopLossPips) {
						const mul = tradingPosition.side === TradingPositionSide.Buy ? 1 : -1;
						stopLoss = tradingPosition.currentPrice - pips.stopLossPips * getMarketItemPipSize(marketItem) * mul;
					}

					state.current.stopLossInfo = { ...state.current.stopLossInfo, isActive: true, price: stopLoss };
				} else {
					state.current.stopLossInfo = {
						...state.current.stopLossInfo,
						isActive: false,
						price: undefined,
						potentialProfitLoss: undefined,
						error: undefined,
					};
				}
			});
		},

		createTradeRequestObject: () => {
			let tradeObject: any;
			set((state: OrderStoreValues) => {
				const { tradingPosition, current } = state;

				const { type } = state.current;
				const editState =
					tradingPosition?.state === TradingPositionState.open ||
					tradingPosition?.state === TradingPositionState.pending;

				const isMarketOrder = type === TradingPositionType.Market;

				let stopLevel = null;
				let limitLevel = null;

				if (current.stopLossInfo?.isActive && current.stopLossInfo?.price) {
					stopLevel = current.stopLossInfo?.price;
				}
				if (current.takeProfitInfo?.isActive && current.takeProfitInfo?.price) {
					limitLevel = current.takeProfitInfo?.price;
				}

				let commonProps: ITradeObjProps = {
					stopLevel,
					limitLevel,
					reqId: +new Date().getSeconds(),
					acctId: current.accountId!,
				};

				if (editState) {
					// edit order / position
					if (current.limitsOnly) {
						// modify take profit and stop loss for open positions
						if (tradingPosition?.state === TradingPositionState.open) {
							tradeObject = {
								...commonProps,
								posId: tradingPosition.posId,
							} as EditPositionRequest;
						}
						// modify take profit and stop loss for pending orders
						if (tradingPosition?.state === TradingPositionState.pending) {
							tradeObject = {
								...commonProps,
								posId: tradingPosition.posId,
								priceLevel: tradingPosition.prc,
								expiration: tradingPosition.eT,
							} as EditOrderRequest;
						}
					} else {
						// modify a pending order
						tradeObject = {
							...commonProps,
							posId: tradingPosition?.posId,
							tradeSide: current.side,
							tradeSize: current.amountInfo?.amount ?? 0,
							tradeType: current.type,
							priceLevel: !isMarketOrder ? current.openPriceInfo?.price : null,
							expiration: !isMarketOrder ? current.date : null,
						} as EditOrderRequest;
					}
				} else {
					if (!isMarketOrder) {
						// new order
						tradeObject = {
							...commonProps,
							code: state.marketItem?.code,
							feedId: state.marketItem?.feedId,
							tradeSide: current.side,
							tradeSize: current.amountInfo?.amount,
							tradeType: !isMarketOrder ? current.type : null,
							priceLevel: !isMarketOrder ? current.openPriceInfo?.price : null,
							expiration: !isMarketOrder ? current.date : null,
							lotAmountQty: current.amountInfo?.amount,
						} as NewOrderRequest;
					} else {
						// new position
						tradeObject = {
							...commonProps,
							code: state.marketItem?.code,
							feedId: state.marketItem?.feedId,
							tradeSide: current.side,
							tradeSize: current.amountInfo?.amount,
							lotAmountQty: current.amountInfo?.amount,
						} as NewPositionRequest;
					}
				}
			});
			return tradeObject;
		},
	}))
);

export default createSelectors(orderStore);
