import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import styles from './NotificationsIcon.module.scss';

interface NotificationsIconProps {
	open: boolean;
	messageCount: number;
	onClick: (open: boolean) => void;
}

const NotificationsIcon = ({ open, messageCount, onClick }: NotificationsIconProps) => {
	return (
		<div
			className={cn({
				[styles.iconContainer]: true,
				[styles.iconContainerClicked]: open,
			})}
			onClick={onClick}
		>
			<FontAwesomeIcon icon={[open ? 'fas' : 'fal', 'bell']} className={styles.notificationIcon} />
			{messageCount > 0 && <div className={styles.newMessageBadge}>{messageCount}</div>}
		</div>
	);
};

export default NotificationsIcon;
