import {
	TCfdWatchlistPreference,
	TWatchlistInstrument,
	TWatchlists,
} from '../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { MarketItem } from '../../gateways/RfpGateway/rfp.types';
import { DefaultWatchList, TMappedWatchlist, WatchListItem, WatchListItemCodes } from '../../contexts/DashboardContext';

import { AppContextProvider } from '../../contexts/AppContext';
import { IGridRecord } from '../../views/features/Markets/MarketsGrid';

import { Resolver } from './Ioc';

const spreadBettingFxSymbols = [
	'UK100_SB',
	'GER40_SB',
	'NAS100_SB',
	'US30_SB',
	'SPX500_SB',
	'JPN225_SB',
	'GBPUSD_SB',
	'EURUSD_SB',
	'USDJPY_SB',
	'XAUUSD_SB',
	'WTI_SB',
];

const commonFxSymbols: string[] = [];

let MAX_WATCHLISTS_ALLOWED = 20;
let MAX_INSTRUMENTS_ALLOWED = 50;
let MAX_INSTRUMENTS_ALLOWED_JP = 100;

export const DEFAULT_FEED_ID = 'VTFeed';

export const findSelectedWatchlistId = (
	watchlist: TCfdWatchlistPreference[],
	selectedTradingAccount: number
): number => {
	const watchlistId = watchlist.findIndex((item) => item.id === selectedTradingAccount);
	return watchlistId !== -1 ? watchlistId : -1;
};

//Creates a key/value map of watchlists based on the new preference structure v1.0
export const createWatchlistMap = (preferenceValue: TWatchlists[]): { [key: string]: TWatchlistInstrument[] } => {
	let watchlistMap: { [key: string]: TWatchlistInstrument[] } = {};

	preferenceValue.forEach((watchlists) => {
		if (Array.isArray(watchlists.instrument)) {
			watchlistMap[watchlists._name] = watchlists.instrument;
		} else {
			watchlistMap[watchlists._name] = [watchlists.instrument];
		}
	});

	return watchlistMap;
};

export const getDefaultJapanWatchlists = (preferredFeedId: string, marketItems: MarketItem[]): TWatchlists[] => {
	const numTiers = 3;
	const japanWatchlists: Record<string, any> = {};
	for (let i = 1; i <= numTiers; i++) {
		japanWatchlists[`tier${i}`] = [];
	}

	for (const element of marketItems) {
		if (typeof element.minTier !== 'undefined' && element.minTier !== null) {
			// loop from each instrument's minTier to max number of tiers defined
			// to make sure higher tiers include instruments from lower tiers
			for (let tierNum = element.minTier; tierNum <= numTiers; tierNum++) {
				japanWatchlists[`tier${tierNum}`].push({ _code: element.code, _rank: element.watchlistRank });
			}
		}
	}

	Object.keys(japanWatchlists).forEach((tierKey) => {
		// finally, sort instruments inside each watchlist by rank
		japanWatchlists[tierKey].sort((a: TWatchlistInstrument, b: TWatchlistInstrument) => (a._rank < b._rank ? -1 : 1));
	});

	let result: TWatchlists[] = [];

	Object.keys(japanWatchlists).forEach((tierKey) => {
		result.push({
			instrument: japanWatchlists[tierKey],
			_feedId: preferredFeedId,
			_id: `Tier ${tierKey.slice(-1)}`,
			_name: `Tier ${tierKey.slice(-1)}`,
			_sortOrder: 'None',
			_sortValue: 'Instrument',
		});
	});

	return result;
};

export const getDefaultCFDWatchlist = (marketItems: MarketItem[], isSpreadBettingWatchlist = false) => {
	const fXSymbols = isSpreadBettingWatchlist ? spreadBettingFxSymbols : commonFxSymbols;

	let defaultArray: TWatchlistInstrument[] = [];
	fXSymbols.forEach((symbol: string) => {
		marketItems.forEach((marketItem) => {
			if (marketItem.code === symbol) {
				let newInstrumentEntry = { _code: marketItem.code };
				defaultArray.push(newInstrumentEntry);
			}
		});
	});

	return defaultArray;
};

export const defaultWatchlistPreference = (
	preferredFeedId: string,
	marketItems: MarketItem[],
	translatedName: string,
	isSpreadBettingWatchlist?: boolean
): TWatchlists => {
	const newWatchlistEntry: TWatchlists = {
		instrument: getDefaultCFDWatchlist(marketItems, isSpreadBettingWatchlist),
		_feedId: preferredFeedId,
		_id: translatedName,
		_name: translatedName,
		_sortOrder: 'None',
		_sortValue: 'Instrument',
	};
	return newWatchlistEntry;
};
export const dynamicWatchlistPreference = (
	preferredFeedId: string,
	translatedName: string,
	instrument: any,
	order: number
): TWatchlists => {
	const newWatchlistEntry: TWatchlists = {
		instrument: instrument,
		_feedId: preferredFeedId,
		_id: translatedName,
		_name: translatedName,
		_sortOrder: order,
		_sortValue: 'Instrument',
	};
	return newWatchlistEntry;
};

export const maxWatchlistsReached = (watchlist: TMappedWatchlist): boolean => {
	return Object.keys(watchlist).length >= MAX_WATCHLISTS_ALLOWED;
};

export const maxInstrumentsPerWatchlist = (watchlist: TMappedWatchlist, currentWatchlist: string): boolean => {
	// this guy is being invoked way more often than needed; how it's used needs to be reviewed
	// ideally we don't want to use the resolver but this function is all over the place and I don't want to
	// walktrough and udate its invokations everywhere
	const appContext = Resolver.resolve(AppContextProvider);
	const maxInstruments = appContext.isJapanAccount ? MAX_INSTRUMENTS_ALLOWED_JP : MAX_INSTRUMENTS_ALLOWED;
	return watchlist[currentWatchlist].length >= maxInstruments;
};

export const invalidWatchlistName = (typedValue: string, watchlist: TMappedWatchlist): boolean => {
	return Object.keys(watchlist).some(
		(keyName: string) => keyName.toLocaleLowerCase() === typedValue.toLocaleLowerCase()
	);
};

export const instrumentExistsInWatchlist = (
	mappedWatchlist: TMappedWatchlist,
	watchlistName: string,
	instrument: MarketItem
): boolean => {
	return mappedWatchlist[watchlistName].filter((item) => item._code === instrument.code).length > 0;
};

export const instrumentExistsInDynamicWatchlists = (
	name: string,
	watchlists: DefaultWatchList[],
	isSpreadBettingAccount: boolean
): boolean => {
	let result = false;
	if (watchlists.length > 0 && !isSpreadBettingAccount) {
		watchlists.forEach((watchList) => {
			if (!!watchList.items.find((item: WatchListItem) => item.title === name)) result = true;
		});
	}
	return result;
};

export const instrumentExistsInAnyWatchlist = (
	mappedWatchlist: TMappedWatchlist,
	instrument: MarketItem | IGridRecord
): boolean => {
	let occurrences = 0;
	if (!mappedWatchlist) return false;

	Object.values(mappedWatchlist).forEach((watchlist) => {
		watchlist.forEach((wInstrument) => {
			wInstrument._code === instrument.code && occurrences++;
		});
	});
	return occurrences > 0;
};
