export enum MarketsGroupName {
	Risers = 'Risers',
	Fallers = 'Fallers',
	MostVolatile = 'Most volatile',
	RisersSB = 'Risers SB',
	FallersSB = 'Fallers SB',
	MostVolatileSB = 'Most volatile SB',
	Commodities = 'Commodities',
	Crypto = 'Crypto',
	CryptoSB = 'CryptoSB',
	Energy = 'Energy',
	EnergySB = 'EnergySB',
	CFD = 'CFD',
	ETF = 'ETF',
	Forex = 'Forex',
	ForexSB = 'ForexSB',
	Indices = 'Indices',
	IndicesSB = 'IndicesSB',
	Metals = 'Metals',
	MetalsSB = 'MetalsSB',
	Stocks = 'Stocks',
	Equities = 'Equities',
	Tier1 = 'Tier1',
	Tier2 = 'Tier2',
	Tier3 = 'Tier3',
}
export enum MarketsGroupTooltips {
	Risers = 'RISERS_TOOLTIP',
	Fallers = 'FALLERS_TOOLTIP',
	'Most volatile' = 'MOST_VOLATILE_TOOLTIP',
	'Risers SB' = 'RISERS_TOOLTIP',
	'Fallers SB' = 'FALLERS_TOOLTIP',
	'Most volatile SB' = 'MOST_VOLATILE_TOOLTIP',
	Commodities = 'COMMODITIES_TOOLTIP',
	Crypto = 'CRYPTO_TOOLTIP',
	CryptoSB = 'CRYPTO_TOOLTIP',
	Energy = 'ENERGY_TOOLTIP_CFD',
	EnergySB = 'ENERGY_TOOLTIP',
	CFD = 'SHARE_TOOLTIP_CFD',
	ETF = 'ETF_TOOLTIP_CFD',
	Forex = 'FOREX_TOOLTIP_CFD',
	ForexSB = 'FOREX_TOOLTIP',
	Indices = 'INDICES_TOOLTIP',
	IndicesSB = 'INDICES_TOOLTIP_SB',
	Metals = 'METALS_TOOLTIP',
	MetalsSB = 'METALS_TOOLTIP_SB',
	Equities = 'SHARE_TOOLTIP_CFD',
}

export enum Topic {
	Unknown = 0,
	Quotes = 1,
	History = 2,
	Alarms = 3,
	Trading = 4,
	News = 5,
	Connection = 6,
	Service = 7,
}

export enum Result {
	Unknown = 0,
	OK = 1,
	ERROR = 2,
}
export enum SubType {
	Unknown = 0,
	SUB = 1,
	UNSUB = 2,
	CL_REQ = 3,
	NONE = 4,
}

export enum MarketsGroupPeriod {
	Daily = 1440,
	Weekly = 10080,
}

export enum RequestState {
	None,
	InProgress,
	Success,
	Error,
}

export enum AppComponentType {
	UploadDocuments = 10,
	OpenAccount = 20,
	CompleteOnboarding = 30,
	Funds = 40,
	Reports = 50,
	Deposits = 60,
	Watchlist = 70,
	Markets = 80,
	Status = 90,
	Calendar = 100,
	Portal = 110,
	TradersGym = 120,
	Signals = 130,
	Tutorials = 140,
}

/** MarginAccountType The flag which describes the account level setting for margin calculations (0: Hedged, 1: Unhedged, 2: Japan) */
export enum MarginAccountType {
	Hedged = 0,
	Unhedged = 1,
	Japan = 2,
}

export enum QuantityType {
	Amount = 1,
	Lots = 2,
}

//Support contact phone numbers per region
export enum PhoneNumbers {
	UK = '44 203 514 2374',
	AU = '61 3 9093 3400',
	SA = '27 10 446 5933',
	CY = '357 252 621 49',
	ES = '34 911 984 469',
	IT = '39 023 0579033',
	BR = '55 11 4858 4252',
	JA_INTL = '81 0120 010 097',
	JA_LOCAL = '0120-010-097',
}

//Application Statuses
export enum ApplicationStatus {
	EMPTY = 'EMPTY',
	APPROVED = 'APPROVED',
	FUNDED_TRADER = 'FUNDED_TRADER',
	PENDING = 'PENDING',
	INCOMPLETE = 'INCOMPLETE',
	PENDING_REVIEW = 'PENDING_REVIEW',
	PENDING_PROPER_DOCUMENTS = 'PENDING_PROPER_DOCUMENTS',
	PENDING_APPROPRIATENESS_TEST = 'PENDING_APPROPRIATENESS_TEST',
	PENDING_TRANSLATION = 'PENDING_TRANSLATION',
	PENDING_RG227 = 'PENDING_RG227',
	PENDING_BLURRY_CROPPED_ID = 'PENDING_BLURRY_CROPPED_ID',
	PENDING_BLURRY_POA = 'PENDING_BLURRY_POA',
	PENDING_ADDRESS = 'PENDING_ADDRESS',
	PENDING_ID = 'PENDING_ID',
	PENDING_E_VERIFICATION = 'PENDING_E_VERIFICATION',
	PENDING_E_VERIFICATION_RETRY = 'PENDING_E_VERIFICATION_RETRY',
	PENDING_ID_ADDRESS = 'PENDING_ID_ADDRESS',
	LEVEL1_APPROVED = 'LEVEL1_APPROVED',
	PENDING_KYC = 'PENDING_KYC',
	MANUAL_DOCS = 'MANUAL_DOCS',
	DENIED = 'DENIED',
	PENDING_TRANSFER = 'PENDING_TRANSFER',
	PENDING_NEW_TnC_ACCEPTANCE = 'PENDING_NEW_TnC_ACCEPTANCE',
	FAILED = 'FAILED',
	INTERNAL_TESTING = 'INTERNAL_TESTING',
	PENDING_IP_MISMATCH = 'PENDING_IP_MISMATCH',
	PENDING_DOB_MISMATCH = 'PENDING_DOB_MISMATCH',
	PENDING_DOB_ADDRESS_MISMATCH = 'PENDING_DOB_ADDRESS_MISMATCH',
	PENDING_ADDRESS_MISMATCH = 'PENDING_ADDRESS_MISMATCH',
	PENDING_SOF = 'PENDING_SOF',
	PENDING_NO_KYC_DOCS = 'PENDING_NO_KYC_DOCS',
	PENDING_EMAIL = 'PENDING_EMAIL',
	PENDING_NO_ID = 'PENDING_NO_ID',
	DUPLICATE = 'DUPLICATE',
}

//Chart Timezone
export enum ChartTimezone {
	Local = 'Local',
	GMT = 'UTC',
	NYT = 'America/New_York',
}

export enum TicketLayout {
	Dock = 'Dock',
	Undock = 'Undock',
}

export enum TradingPositionType {
	Market = 'MARKET',
	Limit = 'LIMIT',
	Stop = 'STOP',
}

export enum TradingPositionLimitType {
	StopLoss = 'StopLoss',
	TakeProfit = 'TakeProfit',
}

export enum TradingPositionSide {
	Buy = 'BUY',
	Sell = 'SELL',
}

export enum TradingAccountType {
	DEMO = 'DEMO',
	LIVE = 'LIVE',
	GYM = 'GYM',
}

export const QUANTITY_TYPE: { [key: string]: string } = {
	LOTS: 'Lots',
	AMOUNT: 'Amount',
};

export enum TradingValueType {
	PRICE = 'PRICE',
	PIPS = 'PIPS',
}

export enum SignalType {
	LiveTrade = 9,
	BuyLimit = 16,
	SellLimit = 14,
	BuyStop = 15,
	SellStop = 13,
}

export enum ThaiPriceType {
	Buy = 'ซื้อ',
	Sell = 'ขาย',
}

export enum WatchListCountries {
	AU,
	CN,
	DE,
	ES,
	HK,
	ID,
	NL,
	NZ,
	PH,
	TH,
	TW,
	ZA,
}
