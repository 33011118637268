import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

const useAggreementStatus = (options: UseQueryOptions) => {
	options.queryKey = ['checkUserTermsAndConditions'];

	options.queryFn = async () => {
		try {
			const response = await axios({
				url: '',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},

				data: {
					payload: [{ module: 'profile', action: 'checkUserTermsAndConditions', parameters: {} }],
				},
			});
			return response.data.payload[0].result;
		} catch (e) {}
	};

	return useQuery(options);
};

export default useAggreementStatus;
