import React, { useContext } from 'react';

import cn from 'classnames';

import { tierIcons } from '../../../setup/subscriptionsConfig';

import AppContext from '../../../contexts/AppContext';

import { instrumentGroupProps } from '../../../utils/functions/constants';

import styles from './GroupBadge.module.scss';
import StringUtils from '../../../utils/functions/StringUtils';

interface TierBadgeProps {
	groupName: any;
	hideIconForNonJapan?: boolean;
}

const GroupBadge = ({ groupName, hideIconForNonJapan }: TierBadgeProps) => {
	const { isJapanAccount } = useContext(AppContext);

	if (hideIconForNonJapan) return null;
	if (!groupName) return null;
	if (StringUtils.isString(groupName) && groupName?.endsWith('SB')) return null;

	if (isJapanAccount) {
		const tier: string = `tier${groupName}`;
		if (groupName !== 0)
			return <span className={cn(styles.marginRight, styles.groupIcon, styles.tier, styles[tier])}>&nbsp;</span>;

		return <img src={tierIcons[tier]} alt="Tier" className={styles.tier} />;
	}

	return <img className={styles.groupIcon} src={instrumentGroupProps[groupName]?.icon} alt={groupName.toString()} />;
};

export default GroupBadge;
