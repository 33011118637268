import i18n from '../setup/i18n';

import { Tier } from '../utils/functions/subscriptionUtils';

import tier1Icon from '../images/tiers/tier1.svg';
import tier2Icon from '../images/tiers/tier2.svg';
import tier3Icon from '../images/tiers/tier3.svg';

export const tierIcons: { [key: string]: string } = {
	tier1: tier1Icon,
	tier2: tier2Icon,
	tier3: tier3Icon,
};

export const tiers: Tier[] = [
	{
		id: 'Tier1',
		key: 'TIER_ONE',
		pricePerMonth: 1500,
		currencyPairs: '2',
		minPerOrder: 1000,
		maxPerOrder: 100000,
		monthlyAmount: '10000000',
		maxPosition: 30000000,
		instruments: ['USD/JPY', 'EUR/USD'],
		maxOpenInstrumentPosition: {
			'EUR/USD': 30000000,
			'USD/JPY': 30000000,
		},
	},
	{
		id: 'Tier2',
		key: 'TIER_TWO',
		pricePerMonth: 3000,
		currencyPairs: '25',
		minPerOrder: 1000,
		maxPerOrder: 250000,
		monthlyAmount: i18n.t('wtr:UNLIMITED'),
		maxPosition: 30000000,
		instruments: [
			'USD/JPY',
			'EUR/USD',
			'EUR/JPY',
			'AUD/JPY',
			'GBP/JPY',
			'NZD/JPY',
			'CAD/JPY',
			'CHF/JPY',
			'GBP/USD',
			'AUD/USD',
			'NZD/USD',
			'USD/CAD',
			'USD/CHF',
			'EUR/GBP',
			'EUR/AUD',
			'EUR/CAD',
			'EUR/CHF',
			'EUR/NZD',
			'GBP/AUD',
			'GBP/CAD',
			'GBP/CHF',
			'GBP/NZD',
			'AUD/CAD',
			'AUD/CHF',
			'AUD/NZD',
		],
		maxOpenInstrumentPosition: {
			'AUD/CAD': 30000000,
			'AUD/CHF': 30000000,
			'AUD/JPY': 30000000,
			'AUD/NZD': 30000000,
			'AUD/USD': 30000000,
			'CAD/JPY': 30000000,
			'CHF/JPY': 30000000,
			'EUR/AUD': 30000000,
			'EUR/CAD': 30000000,
			'EUR/CHF': 30000000,
			'EUR/GBP': 30000000,
			'EUR/JPY': 30000000,
			'EUR/NZD': 30000000,
			'EUR/USD': 30000000,
			'GBP/AUD': 30000000,
			'GBP/CAD': 30000000,
			'GBP/CHF': 30000000,
			'GBP/JPY': 30000000,
			'GBP/NZD': 30000000,
			'GBP/USD': 30000000,
			'NZD/JPY': 30000000,
			'NZD/USD': 30000000,
			'USD/CAD': 30000000,
			'USD/CHF': 30000000,
			'USD/JPY': 30000000,
		},
	},
	{
		id: 'Tier3',
		key: 'TIER_THREE',
		pricePerMonth: 5000,
		currencyPairs: '70',
		minPerOrder: 1000,
		maxPerOrder: 500000,
		monthlyAmount: i18n.t('wtr:UNLIMITED'),
		maxPosition: 50000000,
		instruments: [
			'USD/JPY',
			'EUR/USD',
			'EUR/JPY',
			'AUD/JPY',
			'GBP/JPY',
			'NZD/JPY',
			'CAD/JPY',
			'CHF/JPY',
			'GBP/USD',
			'AUD/USD',
			'NZD/USD',
			'USD/CAD',
			'USD/CHF',
			'EUR/GBP',
			'EUR/AUD',
			'EUR/CAD',
			'EUR/CHF',
			'EUR/NZD',
			'GBP/AUD',
			'GBP/CAD',
			'GBP/CHF',
			'GBP/NZD',
			'AUD/CAD',
			'AUD/CHF',
			'AUD/NZD',
			'CAD/CHF',
			'EUR/HUF',
			'EUR/SGD',
			'GBP/HUF',
			'NZD/CAD',
			'NZD/CHF',
			'USD/HUF',
			'USD/MXN',
			'USD/PLN',
			'USD/SGD',
			'USD/ZAR',
			'EUR/MXN',
			'MXN/JPY',
			'ZAR/JPY',
			'USD/CNH',
			'AUD/CNH',
			'AUD/SGD',
			'CNH/JPY',
			'EUR/CNH',
			'GBP/CNH',
			'NZD/SGD',
			'SGD/JPY',
			'EUR/NOK',
			'EUR/SEK',
			'USD/NOK',
			'USD/SEK',
			'CHF/SEK',
			'GBP/NOK',
			'GBP/SEK',
			'GBP/SGD',
			'NOK/JPY',
			'NZD/CNH',
			'EUR/CZK',
			'EUR/PLN',
			'EUR/ZAR',
			'GBP/ZAR',
			'USD/HKD',
			'EUR/DKK',
			'EUR/HKD',
			'GBP/CZK',
			'GBP/DKK',
			'GBP/PLN',
			'NOK/SEK',
			'USD/CZK',
			'USD/DKK',
		],
		maxOpenInstrumentPosition: {
			'AUD/CAD': 30000000,
			'AUD/CHF': 30000000,
			'AUD/JPY': 50000000,
			'AUD/NZD': 30000000,
			'AUD/USD': 30000000,
			'CAD/CHF': 30000000,
			'CAD/JPY': 30000000,
			'CHF/JPY': 30000000,
			'EUR/AUD': 30000000,
			'EUR/CAD': 30000000,
			'EUR/CHF': 30000000,
			'EUR/CZK': 1000000,
			'EUR/GBP': 30000000,
			'EUR/HUF': 30000000,
			'EUR/JPY': 50000000,
			'EUR/NOK': 2000000,
			'EUR/NZD': 30000000,
			'EUR/PLN': 1000000,
			'EUR/SEK': 2000000,
			'EUR/SGD': 30000000,
			'EUR/USD': 50000000,
			'EUR/ZAR': 1000000,
			'GBP/AUD': 30000000,
			'GBP/CAD': 30000000,
			'GBP/CHF': 30000000,
			'GBP/HUF': 30000000,
			'GBP/JPY': 50000000,
			'GBP/NZD': 30000000,
			'GBP/USD': 30000000,
			'GBP/ZAR': 1000000,
			'NZD/CAD': 30000000,
			'NZD/CHF': 30000000,
			'NZD/JPY': 30000000,
			'NZD/USD': 30000000,
			'USD/CAD': 30000000,
			'USD/CHF': 30000000,
			'USD/CNH': 5000000,
			'USD/HKD': 1000000,
			'USD/HUF': 30000000,
			'USD/JPY': 50000000,
			'USD/MXN': 30000000,
			'USD/NOK': 2000000,
			'USD/PLN': 30000000,
			'USD/SEK': 2000000,
			'USD/SGD': 30000000,
			'USD/ZAR': 30000000,
			'AUD/CNH': 5000000,
			'AUD/SGD': 5000000,
			'CHF/SEK': 2000000,
			'CNH/JPY': 5000000,
			'EUR/CNH': 5000000,
			'EUR/DKK': 1000000,
			'EUR/HKD': 1000000,
			'EUR/MXN': 10000000,
			'GBP/CNH': 5000000,
			'GBP/CZK': 1000000,
			'GBP/DKK': 1000000,
			'GBP/NOK': 2000000,
			'GBP/PLN': 1000000,
			'GBP/SEK': 2000000,
			'GBP/SGD': 2000000,
			'MXN/JPY': 10000000,
			'NOK/JPY': 2000000,
			'NOK/SEK': 1000000,
			'NZD/CNH': 2000000,
			'NZD/SGD': 5000000,
			'SGD/JPY': 5000000,
			'USD/CZK': 1000000,
			'USD/DKK': 1000000,
			'ZAR/JPY': 10000000,
		},
	},
];

export const tiersIds = tiers.map((tierObj) => tierObj.id as string);

export const tiersKeysMap: { [key: string]: string } = tiers.reduce((accumulator, currentTier) => {
	return {
		...accumulator,
		[currentTier.id]: currentTier.key,
	};
}, {});

export const totalNumberOfTiers = tiers.length;

export const LOWEST_TIER_ID = tiers[0].id;

export const HIGHEST_TIER_ID = tiers[tiers.length - 1].id;

export const GLOBAL_MAX_ORDER_SIZE = tiers.find((tier) => tier.id === HIGHEST_TIER_ID)?.maxPerOrder;

export const TIERS_WITH_GYM_ACCESS = [tiers[1].id, tiers[2].id];
