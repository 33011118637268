import { AppContextProvider } from '../../contexts/AppContext';
import { DashboardContextProvider } from '../../contexts/DashboardContext';
import { TAccountStats } from '../../gateways/TfboGateway/TfboGateway.types';

import { ApplicationStatus } from './enums';

export const hasThinkTraderLiveAccount = (appContext: AppContextProvider) => {
	if (appContext.accountStats) {
		const accountPlatform = appContext.accountStats.find(
			(data: TAccountStats) => data.account.type === 'LIVE' && data.account.platform.name === 'ThinkTrader'
		);
		if (accountPlatform) {
			return true;
		}
	}
	return false;
};

export const fetchedAccountStats = (appContext: AppContextProvider) => {
	return appContext.accountStats && appContext.accountStats.length > 0;
};

export const fetchedApplicationStatus = (dashboardContext: DashboardContextProvider) => {
	return dashboardContext.applicationStatus;
};

export const hasApprovedLiveAccount = (appContext: AppContextProvider, dashboardContext: DashboardContextProvider) => {
	if (appContext.accountStats) {
		const hasLiveAccount = appContext.accountStats.find((data: TAccountStats) => data.account.type === 'LIVE');
		const isApproved = dashboardContext.applicationStatus === ApplicationStatus.APPROVED;
		if (hasLiveAccount && isApproved && hasThinkTraderLiveAccount(appContext)) {
			return true;
		}
	}
	return false;
};

export const unapprovedLiveApplication = (dashboardContext: DashboardContextProvider) => {
	// Live application status is undefined
	return !dashboardContext.applicationStatus;
};

export const incompleteLiveApplication = (
	appContext: AppContextProvider,
	dashboardContext: DashboardContextProvider
) => {
	return (
		dashboardContext.applicationStatus &&
		dashboardContext.applicationStatus.includes(ApplicationStatus.INCOMPLETE) &&
		hasThinkTraderLiveAccount(appContext)
	);
};

export const underReviewLiveApplication = (
	appContext: AppContextProvider,
	dashboardContext: DashboardContextProvider
) => {
	return (
		dashboardContext.applicationStatus &&
		// dashboardContext.applicationStatus.includes(ApplicationStatus.PENDING) &&
		hasThinkTraderLiveAccount(appContext)
	);
};

export const underReviewWithoutTTLiveAccount = (dashboardContext: DashboardContextProvider) => {
	return true;
	// return dashboardContext.applicationStatus && dashboardContext.applicationStatus.includes(ApplicationStatus.PENDING);
};

export const approvedLiveApplication = (appContext: AppContextProvider, dashboardContext: DashboardContextProvider) => {
	return (
		dashboardContext.applicationStatus &&
		dashboardContext.applicationStatus.includes(ApplicationStatus.APPROVED) &&
		dashboardContext.applicationStatus !== ApplicationStatus.LEVEL1_APPROVED &&
		hasThinkTraderLiveAccount(appContext)
	);
};
