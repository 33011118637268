import { useContext } from 'react';

import { useHistory } from 'react-router';

import AppContext from '../../contexts/AppContext';
import DashboardContext from '../../contexts/DashboardContext';
import ChartContext from '../../contexts/ChartContext';
import credentials from '../../setup/credentials';
import { closeChildWindows } from '../functions/closeChildWindows';
import WindowContext from '../../contexts/WindowContext';
import RfpGatewayContext from '../../contexts/RfpGatewayContext';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_EMAIL_KEY } from '../../setup/config';
import useSaveUserPreferences from '../mutations/useSaveUserPreferences';
import authStore from '../../store/authStore';
import Routes from '../../setup/routes';
import { resetAllStores } from '../../store/create';

const useLogout = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const chartContext = useContext(ChartContext);
	const windowContext = useContext(WindowContext);
	const rfpGateway = useContext(RfpGatewayContext);

	const setIsAuthenticated = authStore.use.setIsAuthenticated();
	const setIsJapanAccount = authStore.use.setIsJapanAccount();

	const { mutate: saveUserPreference } = useSaveUserPreferences();

	const history = useHistory();

	const logOut = () => {
		//Close any open child windows
		closeChildWindows(windowContext);

		const promises: Promise<any>[] = [];
		//disconnect RFP Gateway
		if (rfpGateway) {
			promises.push(rfpGateway.disconnect());
		}

		if (appContext.userPreferences && appContext.email) {
			saveUserPreference();
		}

		//logout user and reset session
		Promise.allSettled(promises).then(() => {
			// clear stored TFBO tokes as we are loggin out we don't want to sign in the user again
			credentials.clearCredentials();
			// reset contexts
			appContext.logout();
			appContext.reset();
			appContext.isJapanAccount = false;
			setIsJapanAccount(false);
			dashboardContext.reset();
			chartContext.reset();
			// reset session
			sessionStorage.clear();
			setIsAuthenticated(false);
			// redirect to login
			localStorage.removeItem(ACCESS_TOKEN_KEY);
			localStorage.removeItem(REFRESH_TOKEN_KEY);
			localStorage.removeItem(USER_EMAIL_KEY);

			resetAllStores();
			document.title = 'ThinkTrader Web';
			history.push(Routes.account.login);
			// we need to reload in order to reset zustand
			// https://docs.pmnd.rs/zustand/guides/how-to-reset-state
		});
	};

	return logOut;
};

export default useLogout;
