import { TradingPositionSide, TradingPositionType } from '../../utils/functions/enums';

import { ActionType, Order, OrderStoreValues } from './orderStore.types';

const initialOrder: Order = {
	accountId: undefined,
	side: TradingPositionSide.Buy,
	type: TradingPositionType.Market,
	isPreservedValue: false,
	date: 'EXP_GTC',
	quantity: undefined,
	amountInfo: {
		amount: undefined,
		error: undefined,
		max: undefined,
		min: undefined,
		rndLot: undefined,
		step: undefined,
	},
	openPriceInfo: {
		error: undefined,
		isActive: false,
		isTriggered: false,
		potentialProfitLoss: undefined,
		price: undefined,
	},
	stopLossInfo: {
		error: undefined,
		isActive: false,
		isTriggered: false,
		potentialProfitLoss: undefined,
		price: undefined,
	},
	takeProfitInfo: {
		error: undefined,
		isActive: false,
		isTriggered: false,
		potentialProfitLoss: undefined,
		price: undefined,
	},
	limitsOnly: undefined,
	isPositionDraggingDisabled: false,
};

export const initialState: OrderStoreValues = {
	current: initialOrder,
	action: ActionType.unknown,
	tradingPosition: undefined,
	tradingInstrument: undefined,
	currentQuote: undefined,
	marketItem: undefined,
	isSignalOrder: false,
};
