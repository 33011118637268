import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../setup/config';

const useExchangeSSOToken = () => {
	const mutationFn = async (ssoCode: string) => {
		try {
			const response = await axios({
				url: `/internal/sso/exchange`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: { ssoCode },
			});

			localStorage.setItem(ACCESS_TOKEN_KEY, response.data.accessToken);
			localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);
			return response.data;
		} catch (e) {
			return e.response.data;
		}
	};

	return useMutation({
		mutationKey: ['useExchangeSSOToken'],
		mutationFn,
	});
};

export default useExchangeSSOToken;
