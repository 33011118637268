import { useContext } from 'react';

import AppContext from '../../../contexts/AppContext';
import { TPreferenceResponse } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import authStore, { AuthStore } from '../../../store/authStore';
import { getSavedInstrumentsOCTValue } from '../../../views/features/Dashboard/TradeBoard/TradeBoardItem/OneClickTradeTicket/utils';
import { getSavedInstrumentsPipsValue } from '../../../views/features/Dashboard/TradeTicket/LimitProfitLoss/utils';
import { fillUserPrefLocally, getSavedPreferencesForUser } from '../../functions/fillUserPrefLocally';

import { useFillContextsFromPreferences } from './useFillContextsFromPreferences';

const useStoreAndLoadPreferences = () => {
	const appContext = useContext(AppContext);
	const { email } = authStore((store: AuthStore) => store.userProfile);
	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);
	const fillContextsFromPreferences = useFillContextsFromPreferences();

	return (userPreferences?: TPreferenceResponse) => {
		const limitProfitLossStoredValues = getSavedInstrumentsPipsValue();
		const savedPreferences = getSavedPreferencesForUser(appContext);

		if (userPreferences) {
			const convertUserPref = JSON.stringify(userPreferences);

			const newUserPreferences = userPreferences;
			if (savedPreferences) {
				const storedStopLossValueType = savedPreferences.user_prefs?.trading?.settings?.cfd?.stopLossValueType;
				const storedTakeProfitValueType = savedPreferences.user_prefs?.trading?.settings?.cfd?.takeProfitValueType;
				if (storedStopLossValueType) {
					newUserPreferences.user_prefs.trading.settings.cfd!.stopLossValueType = storedStopLossValueType;
				}
				if (storedTakeProfitValueType) {
					newUserPreferences.user_prefs.trading.settings.cfd!.takeProfitValueType = storedTakeProfitValueType;
				}
			}

			const filterdSBWatchlistAccountPref = newUserPreferences.user_prefs.watchlists.account.filter((account) => {
				return account.accountType !== undefined;
			});

			const hasDemoSpreadBettingWatchlist = filterdSBWatchlistAccountPref.some(
				(account) => account._type === 'Demo' && account.accountType === 'SpreadBetting'
			);

			if (!hasDemoSpreadBettingWatchlist) {
				newUserPreferences.user_prefs.watchlists.account.push({
					watchlist: [],
					_type: 'Demo',
					accountType: 'SpreadBetting',
				});
			}

			const hasLiveSpreadBettingWatchlist = filterdSBWatchlistAccountPref.some(
				(account) => account._type === 'Live' && account.accountType === 'SpreadBetting'
			);

			if (!hasLiveSpreadBettingWatchlist) {
				newUserPreferences.user_prefs.watchlists.account.push({
					watchlist: [],
					_type: 'Live',
					accountType: 'SpreadBetting',
				});
			}

			if (
				!newUserPreferences.user_prefs.platform.accountTypes ||
				Array.isArray(newUserPreferences.user_prefs.platform.accountTypes)
			) {
				newUserPreferences.user_prefs.platform.accountTypes = {};
			}

			if (
				!newUserPreferences.user_prefs.trading.settings.cfd!.amount ||
				typeof newUserPreferences.user_prefs.trading.settings.cfd!.amount === 'number'
			) {
				newUserPreferences.user_prefs.trading.settings.cfd!.amount = {};
			}

			if (
				!newUserPreferences.user_prefs.trading.settings.equity!.amount ||
				typeof newUserPreferences.user_prefs.trading.settings.equity!.amount === 'number'
			) {
				newUserPreferences.user_prefs.trading.settings.equity!.amount = {};
			}

			if (
				!newUserPreferences.user_prefs.platform.lastLoggedAct ||
				typeof newUserPreferences.user_prefs.platform.lastLoggedAct !== 'object'
			) {
				newUserPreferences.user_prefs.platform.lastLoggedAct = { demo: '', live: '' };
			}

			if (convertUserPref !== null && convertUserPref !== 'null') {
				localStorage.setItem(`userPref-${email}`, convertUserPref);
			}
			appContext.userPreferences = newUserPreferences;
		} else {
			fillUserPrefLocally(appContext, isLiveMode);
		}

		if (limitProfitLossStoredValues) {
			appContext.limitProfitLossValues = limitProfitLossStoredValues;
		}

		fillContextsFromPreferences(appContext.userPreferences!);
	};
};

export default useStoreAndLoadPreferences;
