import React from 'react';

import getCurrencyFlag, { CurrencyFlag } from '../../../../../images/flags';

import styles from './AccountCurrency.module.scss';

interface AccountCurrencyProps {
	code: CurrencyFlag;
}

const AccountCurrency = ({ code }: AccountCurrencyProps) => {
	return <img src={getCurrencyFlag(code)} alt="Flag Logo" className={styles.flag} />;
};

export default AccountCurrency;
