import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import { defaultSubscriptionInfo, SubscriptionInfo } from '../functions/subscriptionUtils';

type useSubscriptionInfoInterface = [SubscriptionInfo, (updateProps: Partial<SubscriptionInfo>) => void];

const useSubscriptionInfo = (): useSubscriptionInfoInterface => {
	const appContext = useContext(AppContext);

	const setSubscrInfoProps = (updateProps: Partial<SubscriptionInfo>) => {
		appContext.subscriptionInfo = {
			...(appContext.subscriptionInfo || defaultSubscriptionInfo),
			...updateProps,
		};
	};

	return [appContext.subscriptionInfo || defaultSubscriptionInfo, setSubscrInfoProps];
};

export default useSubscriptionInfo;
