import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import DashboardContext from '../../contexts/DashboardContext';
import { TAccountStats } from '../../gateways/TfboGateway/TfboGateway.types';

import useSelectedTradingAccount from './useSelectedTradingAccount';

export const useCountryPrivacyPolicy = (): string => {
	let organizationName: string = '';
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const account = useSelectedTradingAccount();
	// Define the organization name
	if (appContext && dashboardContext && account) {
		let result = appContext.accountStats.find(
			(data: TAccountStats) => data.account.accountNumber === +account.providerAccountId
		);
		organizationName = result?.account?.organization?.name ?? '';
	}

	switch (organizationName) {
		case 'AU':
			return 'https://www.thinkmarkets.com/au/support/legal-and-regulation/privacy-policy/';
		case 'UK':
			return 'https://www.thinkmarkets.com/uk/support/legal-and-regulation/privacy-policy/';
		case 'TMCY':
			return 'https://www.thinkmarkets.com/eu/support/legal-and-regulation/privacy-policy/';
		case 'TFSA':
			return 'https://www.thinkmarkets.com/za/support/legal-and-regulation/privacy-policy/';
		case 'TMJP':
			return 'https://www.thinkmarkets.com/jp/support/legal-and-regulation/pip/privacy-policy/';
		case 'TMNZ':
			return 'https://www.thinkmarkets.com/nz/support/legal-and-regulation/privacy-policy/';
		default:
			return 'https://www.thinkmarkets.com/en/support/legal-and-regulation/privacy-policy/';
	}
};
