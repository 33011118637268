import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';

import HCaptcha from '@hcaptcha/react-hcaptcha';

import Modal from '../../../shared/Modal/Modal';
import { default as RfpGateway } from '../../../gateways/RfpGateway/RfpGateway';
import { Resolver } from '../../../utils/functions/Ioc';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import { default as AppContext } from '../../../contexts/AppContext';

import Button from '../../../shared/Button/Button';

import useLogout from '../../../utils/hooks/useLogout';
import useForceRerender from '../../../utils/hooks/useForceRerender';

import styles from './ReconnectModal.module.scss';

const RecconectModal = React.memo(
	() => {
		const appContext = useContext(AppContext);
		const handleLogout = useLogout();
		const forceRerender = useForceRerender();
		const tt = useShortTranslation('wtr:RECONN_MOD_');

		const [cancelReconnect, setCancelReconnect] = useState<boolean>(false);
		const [isOnline, setIsOnline] = useState<boolean>(true);

		const rfpGateway = Resolver.resolve(RfpGateway);
		const reconnectModal = appContext.reconnectModal;

		const captchaRef = useRef<HCaptcha>(null);

		useObservable(appContext.getPropertyChangeStream('reconnectModal'), () => forceRerender());

		// Add event listeners to check online status
		useEffect(() => {
			window.addEventListener('offline', () => {
				setIsOnline(false);
			});
			window.addEventListener('online', () => {
				setIsOnline(true);
			});

			return () => {
				window.removeEventListener('offline', () => {
					setIsOnline(false);
				});
				window.removeEventListener('online', () => {
					setIsOnline(true);
				});
			};
		}, []);

		const reloadPage = () => {
			if (!cancelReconnect) {
				appContext.reconnectModal = false;
			}

			if (!rfpGateway.connected && appContext.isLoggedIn && !cancelReconnect) {
				window.location.reload();
			}
		};

		// Start reconnecting session if WebSocket connection has timed out OR network status is offline
		useEffect(() => {
			if (!rfpGateway.connected) {
				reloadPage();
			}
		}, [rfpGateway.connected]);

		// Show reconnect modal if network status is offline and reconnect session is not initialized after ping-pong timeout
		useEffect(() => {
			if (!isOnline) {
				appContext.reconnectModal = true;
			} else {
				reloadPage();
			}
		}, [isOnline]);

		return (
			<Modal
				backdrop="static"
				centered
				contentClassName={styles.reconnectModal}
				show={appContext.isLoggedIn && reconnectModal && !isOnline}
			>
				<Modal.Header className={styles.modalHeader}>{cancelReconnect ? tt('2_HEAD') : tt('1_HEAD')}</Modal.Header>
				<Modal.Body className={styles.modalBody}>
					<div className={styles.iconContainer}>
						<FontAwesomeIcon
							className={styles.failedIcon}
							icon={['fas', cancelReconnect ? 'wifi-slash' : 'sync']}
							size="6x"
							spin={!cancelReconnect}
						/>
					</div>
					<div className={styles.modalText}>{cancelReconnect ? tt('2_TEXT') : ''}</div>
					{process.env.REACT_APP_DISABLE_CAPTCHA !== 'true' && (
						<HCaptcha
							sitekey={process.env.REACT_APP_HCAPTCHA_KEY!}
							onVerify={() => {}}
							onError={handleLogout}
							ref={captchaRef}
							size="invisible"
						/>
					)}
				</Modal.Body>
				<Modal.Footer className={styles.modalFooter}>
					{cancelReconnect ? (
						<>
							<Button className={styles.button} variant="outline" label={tt('2_BTN_LOGOUT')} onClick={handleLogout} />
							<Button
								className={styles.button}
								variant="primary"
								label={tt('2_BTN_RETRY')}
								onClick={() => window.location.reload()}
							/>
						</>
					) : (
						<Button
							className={styles.button}
							variant="outline"
							label={tt('1_BTN_CANCEL')}
							onClick={() => setCancelReconnect(true)}
						/>
					)}
				</Modal.Footer>
			</Modal>
		);
	},
	() => true
);

export default RecconectModal;
