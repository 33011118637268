import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { SUBSCR_MGMT_PROV } from '../../setup/config';

interface SubscriptionStatusParams {
	enabled: boolean;
	accountNumber?: null | number;
}
const useFetchSubscriptionStatus = ({ enabled = true, accountNumber }: SubscriptionStatusParams) => {
	const queryKey = ['useFetchSubscriptionStatus', accountNumber];

	const queryFn = async () => {
		try {
			const response = await axios({
				url: '',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},

				data: {
					payload: [
						{
							module: 'subscription',
							action: 'getSubscriptionStatus',
							parameters: {
								accountNumber,
								provider: SUBSCR_MGMT_PROV,
							},
						},
					],
				},
			});
			return response.data.payload[0];
		} catch (e) {}
	};

	return useQuery({ queryKey, queryFn, enabled, staleTime: 0 });
};

export default useFetchSubscriptionStatus;
