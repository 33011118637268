import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';

import { ApmRoute } from '@elastic/apm-rum-react';

import './App.scss';

import * as reactDeviceDetect from 'react-device-detect';

import Routes, { webTraderPath } from './setup/routes';

import './setup/registerIcons';
import './setup/monitoring';
import './setup/axios';

import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { Resolver } from './utils/functions/Ioc';
import AppContext from './contexts/AppContext';
import usePromiseFactory from './utils/hooks/usePromiseFactory';
import useObservable from './utils/hooks/useObservable';
import useForceRerender from './utils/hooks/useForceRerender';
import ThemeWrapper from './views/components/ThemeWrapper';
import { subscribeOpsPersistHelper } from './utils/functions/subscrOpParamsPersistHelper';
import TradersGymContextProvider from './pages/TradersGym/TradersGymContext';
import InstrumentContext, { InstrumentContextProvider } from './contexts/InstrumentContext';

import useInitialize from './utils/hooks/system/useInitialize';
import AccountStatusHandler from './views/components/Permissions/AccountStatusHandler';

import TwoFactorAuthModal from './views/features/Dashboard/Header/components/SettingsModal/TwoFactorAuthentication/TwoFactorAuthModal';

import DashboardContainerComponent from './views/features/DashboardContainerComponent/DashboardContainerComponent';
import useWindowSize from './utils/hooks/useWindowSize';

const Login = lazy(() => import('./pages/Login/Login'));
const MobileView = lazy(() => import('./pages/Mobile/MobileView'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const TwoFactorAuthentication = lazy(() => import('./pages/TwoFactorAuthentication/TwoFactorAuthentication'));
const Agreement = lazy(() => import('./views/features/Agreement'));
const NewTraderWindow = lazy(() => import('./views/components/NewTraderWindow/NewTraderWindow'));
const TradersGym = lazy(() => import('./pages/TradersGym/TradersGym'));
const Signals = lazy(() => import('./pages/Signals/Signals'));
const NewUpdateNotification = lazy(() => import('./shared/NewUpdateNotification/NewUpdateNotification'));
const AccountStatusModal = lazy(() => import('./views/components/AccountStatusModal/AccountStatusModal'));

const App: React.FC = () => {
	const appContext = useContext(AppContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const instrumentContextProvider = Resolver.resolve(InstrumentContextProvider);

	const queryPersistAppendix = subscribeOpsPersistHelper('status', 'providerStatus');

	useObservable(appContext.getPropertyChangeStream('isArabic'), () => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	useEffect(() => {
		if (appContext.isArabic) {
			document.documentElement.setAttribute('dir', 'rtl');
		} else {
			document.documentElement.removeAttribute('dir');
		}
	}, [appContext.isArabic]);

	useInitialize();

	const history = useHistory();

	const { width, height } = useWindowSize();

	const isMobile =
		reactDeviceDetect.isMobile ||
		reactDeviceDetect.isAndroid ||
		reactDeviceDetect.isTablet ||
		'ontouchstart' in document.documentElement;

	useEffect(() => {
		if (isMobile) history.push(Routes.account.mobile);
	}, [width, height, isMobile, reactDeviceDetect]);

	return (
		<Suspense fallback="">
			<ThemeWrapper>
				<InstrumentContext.Provider value={instrumentContextProvider}>
					<TradersGymContextProvider>
						<AccountStatusHandler />
						<AccountStatusModal />
						<TwoFactorAuthModal />
						<Switch>
							<ApmRoute exact path={Routes.account.sso} />
							<ApmRoute exact path={Routes.trader.tradersGym} component={TradersGym} />
							<ApmRoute exact path={Routes.trader.signals} component={Signals} />
							<ApmRoute path={`${Routes.trader.watchlist}/:id`} component={NewTraderWindow} />
							<ApmRoute exact path={Routes.account.agreeToTermsAndConditions} component={Agreement} />
							<ApmRoute path={webTraderPath} component={DashboardContainerComponent} />
							<ApmRoute exact path={Routes.account.forgotPassword} component={ForgotPassword} />
							<ApmRoute exact path={Routes.account.login} component={Login} />
							<ApmRoute exact path={Routes.account.twoFactorAuthentication} component={TwoFactorAuthentication} />
							<ApmRoute exact path={Routes.account.mobile} component={MobileView} />
							<ApmRoute
								render={() => <Redirect to={{ pathname: Routes.account.login, search: queryPersistAppendix }} />}
							/>
						</Switch>
					</TradersGymContextProvider>
				</InstrumentContext.Provider>
			</ThemeWrapper>
		</Suspense>
	);
};

export default App;
