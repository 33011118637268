import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import Routes from '../../../../../setup/routes';

import { AppComponentType } from '../../../../../utils/functions/enums';
import AppContext, { TTradingMode } from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import { AccountMarketType, TradingAccount } from '../../../../../gateways/RfpGateway/rfp.types';

import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';

// TODO: fix this double importation
import statusStore from '../../../../../store/accountStatusStore';

import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

import { GymTradingAccount } from '../../../../../pages/TradersGym/Accounts/GymTradingAccount';

import authStore, { TradingMode } from '../../../../../store/authStore';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

import accountStatusStore from '../../../../../store/accountStatusStore';

import useSaveUserPreferences from '../../../../../utils/mutations/useSaveUserPreferences';

import { useFillContextsFromPreferences } from '../../../../../utils/hooks/preferences/useFillContextsFromPreferences';

import { TradingAccountType } from '../../../../../gateways/TfboGateway/TfboGateway.types';

import { LastLoggedAct } from '../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import AccountCurrency from './AccountCurrency';

import styles from './AccountMenuPopupSection.module.scss';
import { RFP } from '../../../../../gateways/RfpGateway/rfpConstants';
import { Resolver } from '../../../../../utils/functions/Ioc';
import RfpGateway from '../../../../../gateways/RfpGateway/RfpGateway';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

interface TradingAccountsProps {
	activeMenuItem: 'live' | 'demo' | TTradingMode;
	setAccountPopupIsOpen: (isOpen: boolean) => void;
	selectedTradingAccount: TradingAccount | GymTradingAccount | undefined;
}

const TradingAccounts = ({ setAccountPopupIsOpen, activeMenuItem, selectedTradingAccount }: TradingAccountsProps) => {
	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');
	const history = useHistory();
	const rfpGateway = Resolver.resolve(RfpGateway);

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const permissions = statusStore.use.permissions();
	const setShowModal = statusStore.use.setShowModal();
	const selectedTradingMode = authStore.use.tradingMode();
	const setTradingMode = authStore.use.setTradingMode();
	const hasLiveAccount = accountStatusStore.use.hasLiveAccount();
	const addAccount = permissions.addAccount;
	const selected = tradingAccountStore.use.selected();
	const setSelected = tradingAccountStore.use.setSelected();
	const setIsSpreadBetting = tradingAccountStore.use.setIsSpreadBetting();
	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	let showAddAccount =
		(!appContext.isJapanAccount &&
			permissions?.addAccount &&
			selectedTradingMode.toLowerCase() === activeMenuItem.toLowerCase()) ||
		(activeMenuItem.toLowerCase() === 'live' && !hasLiveAccount);

	const accountStats = tradingAccountStore.use.accountStats();

	const { mutateAsync: savePreferences } = useSaveUserPreferences();

	const accountsList = accountStats.filter(
		(account) => account.account.type.toLowerCase() === activeMenuItem?.toLowerCase()
	);

	const noDemoAccount = activeMenuItem === 'demo' && accountsList.length === 0;

	showAddAccount = showAddAccount || noDemoAccount;

	const switchAccounts = async (account?: TradingAccount) => {
		dashboardContext.closeAllOtherTabs();
		if (activeMenuItem !== selectedTradingMode.toLowerCase()) {
			setTradingMode(activeMenuItem.toUpperCase() as TradingMode);
		}

		const selectedIndex = dashboardContext.tradingAccount.findIndex((acc) => {
			return acc.accountNumber === account?.accountNumber;
		});

		if (appContext.userPreferences) {
			if (account?.accountNumber) {
				appContext.userPreferences.user_prefs.platform.lastLoggedAct[
					activeMenuItem.toLowerCase() as keyof LastLoggedAct
				] = account.accountNumber.toString();
			}

			fillContextsFromPreferences(appContext.userPreferences, isFundedTrader);
			savePreferences();
		}

		const selectedAccount = dashboardContext.tradingAccount[selected];
		const newAccount = dashboardContext.tradingAccount[selectedIndex];

		if (selectedAccount?.accountMarketType !== newAccount?.accountMarketType) {
			if (dashboardContext.mappedWatchlist !== null) {
				setCurrentWatchList(dashboardContext.mappedWatchlist[0]);
			}
		}

		if (selectedIndex !== -1) {
			setSelected(selectedIndex);

			setIsSpreadBetting(newAccount?.accountMarketType === AccountMarketType.SpreadBetting);
			if (selectedTradingAccount) rfpGateway.send(RFP.tradingAccountLogout, { acct_id: selectedTradingAccount.id });
		}
		setAccountPopupIsOpen(false);
	};

	const handleAddAccountClick = () => {
		if (noDemoAccount) {
			switchAccounts();
			return;
		}

		if (addAccount === IStatusConfigEnum.addLiveAccount || addAccount === IStatusConfigEnum.addDemoAccount) {
			dashboardContext.presentComponentType = AppComponentType.OpenAccount;
			history.push(Routes.trader.openAccount, { from: window.location.pathname });
		} else {
			setShowModal(true);
			appContext.statusModal = true;
		}

		setAccountPopupIsOpen(false);
	};

	return (
		<div className={styles.section}>
			{accountsList.map(({ account, platformAccountType }: any, index: number) => {
				const isSpreadBettingAccount = platformAccountType === TradingAccountType.SpreadBetting;
				return (
					<div
						key={account.accountNumber}
						className={cn(
							styles.item,
							styles.currencyStatsUsers,
							account.accountNumber.toString() === selectedTradingAccount?.providerAccountId && styles.active
						)}
						onClick={() => switchAccounts(account)}
					>
						<span className={styles.icon}>
							<AccountCurrency code={account.currency.code} />
						</span>

						<div className={styles.statsContainer}>
							<div
								className={cn(
									styles.stats,
									selectedTradingAccount?.providerAccountId === account.accountNumber.toString() && styles.selected
								)}
							>
								<span className={styles[account.type.toLowerCase()]}>
									{t(`en:LOGIN_VIEW_${account.type.toUpperCase()}`)}
								</span>
								&nbsp;
								<span>{account.accountNumber}</span>
							</div>
							{isSpreadBettingAccount ? (
								<span className={styles.baseCurrency}>{t('en:SPREAD_BETTING_PRODUCT')}</span>
							) : (
								<span className={styles.baseCurrency}>
									{account.currency.code} • {t('wtr:CFD')}
								</span>
							)}
						</div>
					</div>
				);
			})}
			{showAddAccount && (
				<div className={cn(styles.item, styles.currencyStatsUsers)} onClick={handleAddAccountClick}>
					<div className={cn(styles.addAccountContainer, styles[activeMenuItem.toLowerCase()])}>
						<FontAwesomeIcon icon={['fal', 'plus']} className={styles.plusIcon} />
					</div>
					<span className={styles.addAccountText}>{tt(`ADD_${activeMenuItem.toUpperCase()}`)}</span>
				</div>
			)}
		</div>
	);
};

export default TradingAccounts;
