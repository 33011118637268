import React, { useContext } from 'react';

import cn from 'classnames';

import { getTierDisplayName, SubscriptionModalReason, Tier } from '../../../utils/functions/subscriptionUtils';

import { SubscriptionTier } from '../../../utils/functions/subscriptionUtils';

import { tierIcons } from '../../../setup/subscriptionsConfig';

import styles from './TierTile.module.scss';
import { useTranslation } from 'react-i18next';
import useMoneyFormatter from '../../../utils/hooks/useMoneyFormatter';
import useSelectedTradingAccount from '../../../utils/hooks/useSelectedTradingAccount';
import TierDisplayName from '../TierDisplayName/TierDisplayName';
import { formatNumberWithCommas } from '../../../views/features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../contexts/AppContext';
interface TierTileProps {
	tier: Tier;
	onSelectTier: (tierId: SubscriptionTier) => void;
	isSelected: boolean;
	title: string | null;
	reason: SubscriptionModalReason;
	isNewUser: boolean;
}

const TierTile = ({ tier, onSelectTier, isSelected, title, reason, isNewUser }: TierTileProps) => {
	const { t } = useTranslation();
	const selectedTradingAccount = useSelectedTradingAccount();
	const formatAsMoney = useMoneyFormatter(selectedTradingAccount);
	const appContext = useContext(AppContext);

	const showCurrencyPairs = [
		SubscriptionModalReason.Renewal,
		SubscriptionModalReason.SubscriptionForNewUser,
		SubscriptionModalReason.SubscriptionForExistingUser,
		SubscriptionModalReason.SubscribeFromAlert,
		SubscriptionModalReason.ManageSubscription,
		SubscriptionModalReason.UpgradeSubscription,
		SubscriptionModalReason.Instrument,
	].includes(reason);

	const showMaxVolume = reason === SubscriptionModalReason.Volume;

	const showOrderSize = reason === SubscriptionModalReason.OrderSizeUpgradable;

	return (
		<div className={cn(styles.tileContainer, isSelected && styles.selected)} onClick={() => onSelectTier(tier.id)}>
			{!isNewUser && title && (
				<>
					<div className={cn(isSelected && styles.selectorIndicator)}></div>
					<div className={cn(styles.selectorTitle, isSelected ? styles.selectedText : styles.defaultText)}>{title}</div>
				</>
			)}

			<div className={styles.tileSection}>
				<img src={tierIcons[tier.id.toLowerCase()]} alt={tier.id} className={styles.tierIcon} />
				<h2 className={styles.tierTitle}>{getTierDisplayName(tier.id)}</h2>
			</div>
			<div className={cn(styles.tileSection, styles.padded)}>
				{showCurrencyPairs && (
					<>
						<div className={styles.currencyPairs}>{tier.currencyPairs}</div>
						<span className={styles.tradableCurrencyPairs}>{t('wtr:TIER_CURRENCY_PAIRS')}</span>
					</>
				)}
				{showMaxVolume && (
					<>
						<div className={styles.currencyPairs}>
							{isNaN(parseInt(tier.monthlyAmount))
								? tier.monthlyAmount
								: formatNumberWithCommas(tier.monthlyAmount, 0, appContext.languageSettings)}
						</div>
						<span className={styles.tradableCurrencyPairs}>{t('wtr:MAXIMUM_MOTHNLY_TRADING_VOLUME')}</span>
					</>
				)}
				{showOrderSize && (
					<>
						<div className={styles.currencyPairs}>
							{formatNumberWithCommas(tier.maxPerOrder, 0, appContext.languageSettings)}
						</div>
						<span className={styles.tradableCurrencyPairs}>{t('wtr:TIER_MAX_PER_ORDER')}</span>
					</>
				)}
			</div>

			<span className={styles.price}>{`${formatAsMoney(tier.pricePerMonth)}/${t('wtr:PER_MONTH')}`}</span>
		</div>
	);
};

export default TierTile;
