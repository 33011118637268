import { useContext, useMemo } from 'react';

import DashboardContext from '../../../contexts/DashboardContext';

import authStore from '../../../store/authStore';

import {
  createMarketItemGroupMap,
  createMarketItemTierMap
} from '../../../utils/functions/marketItems/marketItemGroupMapFormatter';

export const useMarketItemFormatter = () => {
  const dashboardContext = useContext(DashboardContext);
  const marketItems = dashboardContext.marketItems;
  const isJapanAccount = authStore.use.isJapanAccount();

  return useMemo(() => {
    if (marketItems.length === 0) {
      return {};
    }

    return isJapanAccount
      ? createMarketItemTierMap(marketItems)
      : createMarketItemGroupMap(marketItems);
  }, [marketItems.length, isJapanAccount]);
};
