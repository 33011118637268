import {
	PriceQuote,
	QuoteSubscription,
	HistorySubscription,
	HistoryTick,
	FeedSubscription,
	NewsSubscriptionRequest,
	MarketWinNewsSubscriptionRequest,
	TradingAccountLoginRequest,
	CloseTradeRequest,
	EditOrderRequest,
	EditPositionRequest,
	NewOrderRequest,
	NewPositionRequest,
	PartialCloseRequest,
	MarketItem,
	TradingAccount,
	TradingInstruments,
	TradingProvider,
	TradingAccountLogin,
	RFPNews,
	TradeEvent,
	TradingPosition,
	QuantityTypeUpdate,
	MarketWatchSnapshotsRequest,
	MarketWatchSubscriptionRequest,
	MarketWatchDynamicSubscriptionRequest,
	MarketWatchIntervals,
	MarketWatchCategories,
	MarketWatchItem,
	MarketWatchItems,
	HistorySize,
	TfboLoginResponse,
	TfboEndpointResponse,
	Pong,
	HistoryTickItems,
	FeedInfos,
	RFPDataObjectType,
	MarketItemsRecord,
	RFPDataObject,
	AccountTierResponse,
	AccountTierRequest,
	PreviousDayClosePriceRequest,
	PreviousDayClosePrice,
	MarketItemsInfoRecord,
	TierUpdateInfo,
	HistoryRequestStartDate,
	HistoryTimescaleStartDate,
	RFPTradingAccountsError,
	TradingAccountLogoutRequest,
	RFPRequestTTSessionTimes,
	RFPSessionTimes,
	RequestTTWatchList,
	TTWatchList,
} from './rfp.types';

export const RFP_STOMP_SIGNIN = `${process.env.REACT_APP_RFP_STOMP_WEBPROXY}/api/auth/signin`;
export const RFP_STOMP_WEBSOCKET_ENDPOINT = `${process.env.REACT_APP_RFP_STOMP_WEBPROXY}/ep`;
export const RFP_STOMP_ENDPOINT = `${process.env.REACT_APP_RFP_STOMP_WEBPROXY}`;
export const RFP_BINARY_ENDPOINT = `${process.env.REACT_APP_RFP_BINARY_WEBPROXY}`;

export const RFP = {
	// endpoints related to 'tfbo'
	tfboLoginResponse: '/app/tfboLoginResponse',
	tfboEndpointResponse: '/app/tfboEndpointResponse',

	// endpoints related to 'app'
	ping: '/app/ping',
	pong: '/app/pong',
	getFeedIds: '/app/feedIds',
	getMarketItems: '/app/mktItems',
	getMarketItemsInfo: '/app/mktItemsInfo',
	getTradingAccounts: '/app/tradingAccounts',
	getTradingProviders: '/app/tradingProviders',
	manageHistory: '/app/manageHistory',
	manageHistoryDate: '/app/manageHistoryDate',
	manageQuotes: '/app/manageQuotes',
	manageTradingNews: '/app/manageNews',
	manageMarketWinNews: '/app/manageMarketWinNews',
	getNewsGroups: '/app/getNewsGroups', //unused
	getMarketWatchIntervals: '/app/getMarketWatchIntervals',
	getMarketWatchCategories: '/app/getMarketWatchCategories',
	getMarketWatchSnapshots: '/app/getMarketWatchSnapshots',
	getMarketWatchSubscription: '/app/getMarketWatchSubscription',
	getMarketWatchDynamicSubscription: '/app/getMarketWatchDynamicSubscription',
	getTTSessionTimes: '/app/getTTSessionTimes',

	// endpoints related to the user
	queueDemoAccountParams: '/user/queue/demo-acct-params', //unused
	queueErrors: '/user/queue/errors', //unused
	queueFeedIds: '/user/queue/feedIds',
	queueHistoricalDataUpdates: '/user/queue/hs-upd',
	queueInfos: '/user/queue/infos', //unused
	queueMarketItems: '/user/queue/mktItems',
	queueMarketItemsInfo: '/user/queue/marketitems-info',
	queueQuotes: '/user/queue/q',
	queueTradingAccountsError: '/user/queue/trading-accounts-error',
	queueSessionTimes: '/user/queue/session-times',
	queueTradingAccount: '/user/queue/trading-account',
	queueTradingAccountProperties: '/user/queue/trading-account-properties', //unused
	queueTradingProvider: '/user/queue/trading-provider',
	queueTradingAccountLogin: '/user/queue/trading-account-logon',
	queueTierInfo: '/user/queue/tier-info',
	queueTradingAccountInstruments: '/user/queue/trading-instruments',
	queueTradingNews: '/user/queue/news',
	queueMarketWinNews: '/user/queue/marketWinNews',
	queueTradingNewsConfig: '/user/queue/news-cfg', //unused
	queueTradingNewsGroup: '/user/queue/news-grp', //unused
	queueTradeEvent: '/user/queue/trade-event',
	queueTradeEventExt: '/user/queue/trade-event-ext',
	queueTradingPositionUpdates: '/user/queue/tp',
	queueMarketWatchCategories: '/user/queue/queueMarketWatchCategories',
	queueMarketWatchIntervals: '/user/queue/queueMarketWatchIntervals',
	queueMarketWatchItem: '/user/queue/queueMarketWatchItem',
	queueMarketWatchItems: '/user/queue/queueMarketWatchItems',
	queueHistorySize: '/user/queue/queueHistorySize',
	queueHistoryStartDate: '/user/queue/queueHistoryStartDate',
	queuePreviousDayClosePrice: '/user/queue/queuePreviousDayClosePrice',
	queueTierUpdateInfo: '/user/queue/queueTierUpdateInfo',

	// endpoints to make trades
	tradingAccountLogin: '/app/tradingAccountLogin',
	tradingAccountLogout: '/app/tradingAccountLogout',
	TTWatchList: '/app/ttWatchList',
	requestTTWatchList: '/app/requestTTWatchList',
	closeTrade: '/app/closeTrade',
	connect: '/app/connect',
	editOrder: '/app/editOrder',
	editPosition: '/app/editPosition',
	newOrder: '/app/newOrder',
	newTrade: '/app/newTrade',
	partialClose: '/app/closeTradePartial',

	//endpoint to update trading quantity settings
	updateQuantityType: '',
} as const;

export const RFP_RESPONSE = {
	UNAUTHORIZED: 'Unauthorized',
};

const RfpMessageMap = (() => {
	const map = {
		send: {
			[RFP['getTTSessionTimes']]: null as unknown as RFPRequestTTSessionTimes,
			[RFP['getFeedIds']]: null as unknown as null | undefined,
			[RFP['ping']]: null as unknown as null | undefined,
			//[RFP['pong']]: null as unknown as null | undefined,
			[RFP['getMarketItems']]: null as unknown as FeedSubscription,
			[RFP['getMarketItemsInfo']]: null as unknown as FeedSubscription,
			[RFP['getTradingAccounts']]: null as unknown as number,
			[RFP['getTradingProviders']]: null as unknown as null | undefined,
			[RFP['manageHistory']]: null as unknown as HistorySubscription,
			[RFP['manageHistoryDate']]: null as unknown as HistoryRequestStartDate,
			[RFP['manageQuotes']]: null as unknown as QuoteSubscription,
			[RFP['manageTradingNews']]: null as unknown as NewsSubscriptionRequest,
			[RFP['manageMarketWinNews']]: null as unknown as MarketWinNewsSubscriptionRequest,
			[RFP['tradingAccountLogin']]: null as unknown as TradingAccountLoginRequest,
			[RFP['tradingAccountLogout']]: null as unknown as TradingAccountLogoutRequest,
			[RFP['requestTTWatchList']]: null as unknown as RequestTTWatchList,
			[RFP['queueTierInfo']]: null as unknown as AccountTierRequest,
			[RFP['closeTrade']]: null as unknown as CloseTradeRequest,
			[RFP['editOrder']]: null as unknown as EditOrderRequest,
			[RFP['editPosition']]: null as unknown as EditPositionRequest,
			[RFP['newOrder']]: null as unknown as NewOrderRequest,
			[RFP['newTrade']]: null as unknown as NewPositionRequest,
			[RFP['partialClose']]: null as unknown as PartialCloseRequest,
			[RFP['updateQuantityType']]: null as unknown as QuantityTypeUpdate,
			[RFP['getMarketWatchIntervals']]: null as unknown as null | undefined,
			[RFP['getMarketWatchCategories']]: null as unknown as null | undefined,
			[RFP['getMarketWatchSnapshots']]: null as unknown as MarketWatchSnapshotsRequest,
			[RFP['getMarketWatchSubscription']]: null as unknown as MarketWatchSubscriptionRequest,
			[RFP['getMarketWatchDynamicSubscription']]: null as unknown as MarketWatchDynamicSubscriptionRequest,
			[RFP['queuePreviousDayClosePrice']]: null as unknown as PreviousDayClosePriceRequest,
		},
		subscribe: {
			[RFP['tfboLoginResponse']]: null as unknown as TfboLoginResponse,
			[RFP['tfboEndpointResponse']]: null as unknown as TfboEndpointResponse,
			[RFP['pong']]: null as unknown as Pong,
			[RFP['queueFeedIds']]: null as unknown as FeedInfos,
			[RFP['queueHistoricalDataUpdates']]: null as unknown as HistoryTickItems,
			[RFP['queueMarketItems']]: null as unknown as MarketItemsRecord,
			[RFP['queueMarketItemsInfo']]: null as unknown as MarketItemsInfoRecord,
			[RFP['queueQuotes']]: null as unknown as PriceQuote,
			[RFP['queueSessionTimes']]: null as unknown as RFPSessionTimes,
			[RFP['queueTradingAccount']]: null as unknown as TradingAccount,
			[RFP['queueTradingAccountsError']]: null as unknown as RFPTradingAccountsError,
			[RFP['queueTradingProvider']]: null as unknown as TradingProvider,
			[RFP['queueTradingAccountLogin']]: null as unknown as TradingAccountLogin,
			[RFP['queueTierInfo']]: null as unknown as AccountTierResponse,
			[RFP['queueTradingAccountInstruments']]: null as unknown as TradingInstruments,
			[RFP['queueTradingNews']]: null as unknown as RFPNews,
			[RFP['queueMarketWinNews']]: null as unknown as RFPNews,
			[RFP['queueTradeEvent']]: null as unknown as TradeEvent,
			[RFP['queueTradeEventExt']]: null as unknown as TradeEvent,
			[RFP['queueTradingPositionUpdates']]: null as unknown as TradingPosition,
			[RFP['queueInfos']]: null as unknown as any,
			[RFP['queueErrors']]: null as unknown as any,
			[RFP['queueMarketWatchIntervals']]: null as unknown as MarketWatchIntervals,
			[RFP['queueMarketWatchCategories']]: null as unknown as MarketWatchCategories,
			[RFP['queueMarketWatchItem']]: null as unknown as MarketWatchItem,
			[RFP['queueMarketWatchItems']]: null as unknown as MarketWatchItems,
			[RFP['queueHistorySize']]: null as unknown as HistorySize,
			[RFP['queueHistoryStartDate']]: null as unknown as HistoryTimescaleStartDate,
			[RFP['queuePreviousDayClosePrice']]: null as unknown as PreviousDayClosePrice,
			[RFP['queueTierUpdateInfo']]: null as unknown as TierUpdateInfo,
			[RFP['TTWatchList']]: null as unknown as TTWatchList,
		},
	} as const;
	return map;
})();

const RfpDataMessageMap = (() => {
	const map = {
		[RFPDataObjectType.All]: null as unknown as RFPDataObject,
		[RFPDataObjectType.HistoryTickItems]: null as unknown as HistoryTickItems,
		[RFPDataObjectType.HistoryTick]: null as unknown as HistoryTick,
		[RFPDataObjectType.HistorySize]: null as unknown as HistorySize,
		[RFPDataObjectType.PriceQuote]: null as unknown as PriceQuote,
		[RFPDataObjectType.TfboLoginResponse]: null as unknown as TfboLoginResponse,
		[RFPDataObjectType.TfboEndpointResponse]: null as unknown as TfboEndpointResponse,
		[RFPDataObjectType.Pong]: null as unknown as Pong,
		[RFPDataObjectType.FeedInfos]: null as unknown as FeedInfos,
		[RFPDataObjectType.TradingAccount]: null as unknown as TradingAccount,
		[RFPDataObjectType.TradingAccountLogin]: null as unknown as TradingAccountLogin,
		[RFPDataObjectType.TradingAccountLogout]: null as unknown as TradingAccountLogoutRequest,
		[RFPDataObjectType.TradingProvider]: null as unknown as TradingProvider,

		[RFPDataObjectType.TradeEvent]: null as unknown as TradeEvent,
		[RFPDataObjectType.TradingPosition]: null as unknown as TradingPosition,
		[RFPDataObjectType.MarketWatchIntervals]: null as unknown as MarketWatchIntervals,

		[RFPDataObjectType.MarketWatchCategories]: null as unknown as MarketWatchCategories,
		[RFPDataObjectType.MarketWatchItem]: null as unknown as MarketWatchItem,
		[RFPDataObjectType.MarketWatchItems]: null as unknown as MarketWatchItems,
		[RFPDataObjectType.RFPNews]: null as unknown as RFPNews,

		[RFPDataObjectType.MarketItem]: null as unknown as MarketItem,
		[RFPDataObjectType.MarketItemsRecord]: null as unknown as MarketItemsRecord,
		[RFPDataObjectType.HistoryTimescaleStartDate]: null as unknown as HistoryTimescaleStartDate,
		[RFPDataObjectType.TradingAccountsError]: null as unknown as RFPTradingAccountsError,
		[RFPDataObjectType.TTSessionTimes]: null as unknown as RequestTTWatchList,
	} as const;
	return map;
})();

export type TRfpDataMessageMap = typeof RfpDataMessageMap;

export type TRfpMessages = typeof RfpMessageMap;
export type TRfpSendParams<T extends keyof TRfpMessages['send']> = TRfpMessages['send'][T] extends null | undefined
	? Parameters<(body?: TRfpMessages['send'][T]) => any>
	: Parameters<(body: TRfpMessages['send'][T]) => any>;
