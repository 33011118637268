import React, { useEffect, useState, useMemo, useRef, useContext, useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import moment from 'moment';

import cn from 'classnames';

import { Subscription } from 'observable-fns';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  TreeList,
  Column,
  Scrolling,
  Selection,
  SearchPanel,
  StateStoring,
  LoadPanel,
  ColumnChooser
} from 'devextreme-react/tree-list';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { OverlayTrigger } from 'react-bootstrap';

import { TFunctionResult } from 'i18next';

import { TradingPositionState, TradingPosition, PriceQuote } from '../../../../../gateways/RfpGateway/rfp.types';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import {
  formatNumberWithCommas,
  revertToTraditionalNumberFormat
} from '../../Watchlist/Instrument/formattedQuoteNumber';
import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../../contexts/RfpGatewayContext';
import { default as usePromiseFactory } from '../../../../../utils/hooks/usePromiseFactory';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as Nullable, Optional } from '../../../../../utils/functions/Nullable';
import { getInstrumentDetails, convertAmountToLot, getCurrentPrice } from '../../../../../utils/functions/calculations';

import orderStore from '../../../../../store/OrderStore/orderStore';

import Tooltip from '../../../../components/Tooltip/Tooltip';
import InstrumentContext from '../../../../../contexts/InstrumentContext';
import {
  AppComponentType,
  QUANTITY_TYPE,
  TicketLayout,
  TradingPositionLimitType,
  TradingPositionType
} from '../../../../../utils/functions/enums';

import { getGeneralFormatDate } from '../../../../../utils/functions/subscriptionUtils';
import { DX_COLUMN_CHOOSER_SIZE } from '../../../../../setup/config';

import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import useOrderTicketAccess from '../../../../../utils/hooks/useOrderTicketAccess';

import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

import styles from './OrdersGrid.module.scss';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import {CALENDAR_MONTHS_SHORT_EN} from "../../../../../utils/functions/constants";

type GridRecord = {
  position: TradingPosition | undefined;
  id: string | number;
  headID: string | number;
  posId?: Optional<number>;
  aId?: Optional<number>;
  state: TradingPositionState;
  Instrument: string;
  Side: string;
  SideTranslated: string;
  Type?: Optional<string>;
  TypeTranslated?: Optional<string>;
  Amount: string;
  Lots?: string;
  'Created On'?: Optional<number> | string;
  'Created On Translated'?: Optional<number> | string;
  'Open @'?: Optional<number | string>;
  distanceToMarket?: Optional<any>;
  '% Change'?: Optional<string>;
  Current?: Optional<any>;
  Previous?: Optional<any>;
  TakeProfit?: Optional<string | number>;
  StopLoss?: Optional<string | number>;
  Duration?: Optional<string>;
  pL?: Optional<string | number>;
  'P/L Pips'?: Optional<string | number>;
  Exposure?: Optional<string | number>;
  'Margin Usage'?: Optional<string | number>;
  oP?: Optional<string | number>;
  qty?: Optional<string | number>;
  code: string;
  qCcy?: Optional<string>;
  prc?: Optional<string | number>;
  comm?: Optional<string | number>;
  swap?: Optional<string | number>;
  eT?: Optional<string | number>;
  ExpiryOn?: Optional<string | number>;
  ExpiryOnTranslated?: Optional<string | number | TFunctionResult>;
  type?: Optional<string | number>;
  side?: Optional<string | number>;
  sl?: Optional<string | number>;
  tp?: Optional<string | number>;
  isGroupRow: boolean;
  oldestCreatedDate?: Optional<number>;
  newestCreatedDate?: Optional<number>;
  farthestExpDate?: Optional<string | number>;
  nearestExpDate?: Optional<string | number>;
  lowestDTM?: Optional<number>;
  highestDTM?: Optional<number>;
  Time?: Optional<string>;
  decPrec?: Optional<number>;
};

const orderIconsSize = '20px';

const OrdersGrid = React.memo(
  ({ searchTerm, setSearchTerm }: { searchTerm: string; setSearchTerm: (t: string) => void }) => {
    const appContext = useContext(AppContext);
    const dashboardContext = useContext(DashboardContext);
    const instrumentContext = useContext(InstrumentContext);
    const rfpGatewayContext = useContext(RfpGatewayContext);
    const fillStoreFromPosition = orderStore.use.fillStoreFromPosition();
    const promiseFactory = usePromiseFactory();
    const forceRerender = useForceRerender();
    const { t } = useTranslation();
    const tt = useShortTranslation('en:');
    const orderTicketAccess = useOrderTicketAccess();

    const theme = appContext.appTheme;

    const isArabic = appContext.isArabic;
    const languageSettings = appContext.languageSettings;
    const tradingPositions = dashboardContext.getTradingPositions();
    const ticketLayout = dashboardContext.ticketLayout;
    const detailedInformation = dashboardContext.detailedInformation;
    const marketItems = dashboardContext.marketItems;
    const quantityType = dashboardContext.quantityType;
    const maximizeGrid = dashboardContext.maximizeGrid;
    const selectedSymbol = dashboardContext.selectedSymbol;
    const showOrderTicket = dashboardContext.showOrderTicket;
    const selectedTradingAccountId = useSelectedTradingAccount()?.id;
    const selectedInstrument = dashboardContext.selectedInstrument;
    const activeTradingAccount = useSelectedTradingAccount();
    const selectedPosition = dashboardContext.selectedPosition;
    const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
    useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

    useObservable(dashboardContext.getPropertyChangeStream('tradingPositionsLength'), () => forceRerender());

    useObservable(
      dashboardContext.getPropertyChangeStream(
        'tradingPositions',
        'ticketLayout',
        'presentComponentType',
        'newOrderModalToggle',
        'tradeInformation',
        'detailedInformation',
        'marketItems',
        'quantityType',
        'maximizeGrid',
        'selectedSymbol',
        'showOrderTicket',
        'selectedInstrument',
        'applicationStatus',
        'tradingAccount',
        'accountValues'
      ),
      async () => {
        await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
        forceRerender();
      }
    );

    const treeList = useRef<TreeList>(null);
    const subscriptionRef = useRef<Optional<Subscription<PriceQuote>>>();
    const feedId = `${window.name}-positions-orders`;
    const [headerSelect, setHeaderSelect] = useState('');
    const [columnOption, setColumnOption] = useState('');
    const [columnOptionExpired, setColumnOptionExpired] = useState('');
    const [, setShowTradingIcon] = useState(false);
    const [emptyState, setEmptyState] = useState(false);
    const [highlightPosition, setHighlightPosition] = useState('');
    const [highlightedInstrument, setHighlightedInstrument] = useState('');
    const [gridLength, setGridLength] = useState(0);
    const [, setShowSettings] = useState(false);
    const [highlightChanged, setHighlightChanged] = useState(false);
    const [columnsUpdated, setColumnsUpdated] = useState(false);

    useEffect(() => {
      return () => {
        if (subscriptionRef.current) {
          subscriptionRef.current.unsubscribe();
          subscriptionRef.current = null;
        }
        Nullable.of(rfpGatewayContext).run((rfpGateway) => {
          rfpGateway.deletePriceFeed(feedId).deleteHistoryFeed(feedId);
        });
      };
    }, []);

    useEffect(() => {
      if (dashboardContext.presentComponentType !== AppComponentType.Watchlist) {
        handleHideColumnChooser();
      }
    }, [dashboardContext.presentComponentType]);

    useEffect(() => {
      if (tradingPositions.length > 0) {
        setShowTradingIcon(true);
      }
    }, []);

    useEffect(() => {
      if (selectedInstrument && selectedInstrument.code && selectedInstrument.code !== highlightedInstrument) {
        setHighlightedInstrument(selectedInstrument.code);
        let poistionToHighlight = selectedInstrument.code;
        if (selectedPosition && selectedPosition.length === 1 && selectedPosition[0].code === selectedInstrument.code) {
          const recordToHighlight = gridModel?.records?.find((record) => selectedPosition[0].posId === record.posId);
          poistionToHighlight = recordToHighlight?.id.toString() ?? selectedInstrument.code;
        }
        setHighlightPosition(poistionToHighlight);
        setHighlightChanged(true);
      }
    }, [selectedInstrument, selectedPosition]);

    const addCommas = (current: number | string, decPrec?: number | null) => {
      decPrec = decPrec ? decPrec : current.toString().split('.')[1]?.length ?? 0;
      return formatNumberWithCommas(current, decPrec, languageSettings);
    };

    const gridModel = useMemo(() => {
      //unsubscribe existing subscription
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }

      //create map of positions indexed by symbol
      const pendingPositionsBySymbol = tradingPositions.reduce((map, position) => {
        if (position.state === TradingPositionState.pending && position.aId === selectedTradingAccountId) {
          if (!map.has(position.code)) {
            map.set(position.code, []);
          }
          map.get(position.code)!.push(position);
        }
        return map;
      }, new Map<string, TradingPosition[]>());

      //create price feed
      const priceFeed = Nullable.of(rfpGatewayContext)
        .map((rfpGateway) => rfpGateway.createPriceFeed(feedId))
        .get();

      //subscribe to price updates
      Nullable.of(priceFeed).run((priceFeed) => {
        priceFeed
          .unsubscribe((subscription) => !pendingPositionsBySymbol.has(subscription.code))
          .then(() => {
            const subscriptions = Array.from(pendingPositionsBySymbol.values())
              .flatMap((positions) => {
                return positions.map((position) => {
                  return { feedId: position.f, code: position.code };
                });
              })
              .reduce((subscriptions, subscription) => {
                if (
                  !subscriptions.some(
                    (value) => value.feedId === subscription.feedId && value.code === subscription.code
                  )
                ) {
                  subscriptions.push(subscription);
                }
                return subscriptions;
              }, [] as Array<{ feedId: string; code: string }>);
            if (subscriptions.length > 0) {
              priceFeed.subscribe(subscriptions);
            }
          });
      });

      //populate grid records with "group" rows
      const gridRecords: GridRecord[] = [];
      pendingPositionsBySymbol.forEach((positions, symbol) => {
        let sellAmount = 0;
        let buyAmount = 0;
        // let quantity = 0
        let openDate: number[] = [];
        let expiredOrder: string = columnOptionExpired;
        let expiredDate: number[] = [];
        let nearestExpDate: string | number = Infinity;
        let oldestCreatedDate = Infinity;
        let farthestExpDate: string | number = 0;
        let newestCreatedDate = 0;
        // let summaryPrice = 0
        positions.forEach((position) => {
          if (position.side === 'BUY') {
            buyAmount += position.qty || 0;
          } else {
            sellAmount += position.qty || 0;
          }
          openDate.push(position.oT!);
          // expiredDate.push(position.eT!)
          const expDate = position.eT === 0 ? 'GTC' : position.eT;
          if (position.oT && moment(position.oT).valueOf() > newestCreatedDate) newestCreatedDate = position.oT;
          if (position.oT && moment(position.oT).valueOf() < oldestCreatedDate) oldestCreatedDate = position.oT;
          if (expDate && farthestExpDate !== 'GTC' && (expDate > farthestExpDate || expDate === 'GTC'))
            farthestExpDate = expDate === 'GTC' ? 'GTC' : expDate;
          if (
            position.eT &&
            (moment(position.eT).valueOf() < (nearestExpDate as number) ||
              nearestExpDate === 'GTC' ||
              (nearestExpDate === Infinity && expDate === 'GTC'))
          )
            nearestExpDate =
              expDate === 'GTC' && (nearestExpDate === Infinity || nearestExpDate === 'GTC') ? 'GTC' : position.eT;
        });
        const isGTC = expiredDate.includes(0);
        let gtcIndex = -1;
        if (isGTC) {
          gtcIndex = expiredDate.indexOf(0);
        }
        const expiredDateValue =
          expiredOrder === 'desc'
            ? isGTC
              ? expiredDate[gtcIndex]
              : expiredDate.sort().reverse()[0]
            : expiredDate.sort()[0];
        if (positions.length > 1 && detailedInformation) {
          const marketItem = rfpGatewayContext?.getMarketItem(symbol);
          const decPrec = marketItem?.decPrec ?? 2;
          const calAmount = buyAmount > 0 ? buyAmount - sellAmount : sellAmount;
          const squareAmount = calAmount === 0;
          const amount = calAmount < 0 ? calAmount * -1 : calAmount;
          let selectedInstrumentDetails = getInstrumentDetails(
            detailedInformation,
            activeTradingAccount ? [activeTradingAccount] : [],
            marketItem?.code as string
          );
          const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
          const lots = convertAmountToLot(amount, instrumentRndLot);
          const codeOrTicker =
            marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
              ? marketItem.exchangeTicker
              : symbol;
          // const open = (summaryPrice / quantity).toFixed(decPrec)
          const sumValue = {
            amount,
            lots,
            // open,
            side: squareAmount ? '' : buyAmount > sellAmount ? 'BUY' : 'SELL',
            change: '',
            current: '',
            pL: ''
          };

          gridRecords.push({
            position: undefined,
            id: symbol,
            headID: -1,
            state: TradingPositionState.pending,
            Instrument: `${codeOrTicker} (${positions.length})`,
            Side: sumValue.side,
            SideTranslated: sumValue.side === 'SQUARE' ? t('wtr:SQUARE') : tt(`${sumValue.side}`),
            Amount: '', //addCommas(sumValue.amount ? Math.round(sumValue.amount * 100) / 100 : 0),
            Lots: addCommas(sumValue.lots ? Math.round(sumValue.lots * 100) / 100 : 0),
            'Created On': '',
            'Created On Translated': '',
            'Open @': '',
            '% Change': sumValue.change,
            Current: '',
            TakeProfit: 'Head',
            StopLoss: 'Head',
            ExpiryOn: expiredDateValue === 0 && expiredOrder === 'desc' ? 2625689831785 : expiredDateValue,
            ExpiryOnTranslated: '',
            pL: '',
            'P/L Pips': '',
            Exposure: '',
            'Margin Usage': '',
            oP: '',
            Type: '',
            TypeTranslated: '',
            qty: '',
            code: symbol,
            qCcy: '',
            prc: '',
            comm: '',
            swap: '',
            isGroupRow: true,
            oldestCreatedDate: oldestCreatedDate,
            newestCreatedDate: newestCreatedDate,
            farthestExpDate: (farthestExpDate as string | number) === 'GTC' ? 'GTC' : farthestExpDate,
            nearestExpDate:
              (nearestExpDate as string | number) === 'GTC' || nearestExpDate === Infinity ? 'GTC' : nearestExpDate,
            lowestDTM: Infinity,
            highestDTM: 0,
            decPrec: decPrec
          });
        }
      });

      //populate grid records with individual positions
      tradingPositions.forEach((position: TradingPosition, index) => {
        const positionLength = Nullable.of(pendingPositionsBySymbol.get(position.code))
          .map((value) => value.length)
          .orElse(0);
        if (
          position.state === TradingPositionState.pending &&
          position.aId === selectedTradingAccountId &&
          detailedInformation
        ) {
          const marketItem = position.marketItem ?? rfpGatewayContext?.getMarketItem(position.code);
          const decPrec = marketItem?.decPrec ?? 0;
          const currentPrice = getCurrentPrice(position.f, position.code);
          let selectedInstrumentDetails = getInstrumentDetails(
            detailedInformation,
            activeTradingAccount ? [activeTradingAccount] : [],
            marketItem?.code as string
          );
          const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
          const lots = convertAmountToLot(position.qty || 0, instrumentRndLot);
          const codeOrTicker =
            marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
              ? marketItem.exchangeTicker
              : position.code;
          const expiry = position.eT === 0 ? ('GTC' as string) : position.eT;

          const createdOnTranslated = appContext.isJapanAccount
            ? getGeneralFormatDate(position.oT, false, true)
            : moment(position.oT).format('D/MMM/YYYY').toString().toUpperCase();

          gridRecords.push({
            position: position,
            id: positionLength !== 1 ? index : position.code,
            headID: positionLength !== 1 ? position.code : -1,
            posId: position.posId,
            aId: position.aId,
            state: position.state,
            Instrument: codeOrTicker,
            Side: position.side,
            SideTranslated: tt(`${position.side}`),
            Type: position.type,
            TypeTranslated: position.type === TradingPositionType.Limit ? tt('TYPE_LIMIT') : tt('TYPE_STOP'),
            Amount: addCommas(position.qty ? Math.round(position.qty * 100) / 100 : 0),
            Lots: addCommas(lots ? Math.round(lots * 100) / 100 : 0),
            'Created On': position.oT,
            'Created On Translated': createdOnTranslated,
            'Open @': addCommas(position.prc?.toFixed(decPrec) ?? NaN, decPrec),
            distanceToMarket: '',
            Current: +(
              (position.side === 'SELL' ? currentPrice?.b.toFixed(decPrec) : currentPrice?.a.toFixed(decPrec)) ?? 0
            ),
            TakeProfit: position.tp?.toFixed(decPrec) ?? '',
            StopLoss: position.sl?.toFixed(decPrec) ?? '',
            ExpiryOn: expiry,
            ExpiryOnTranslated:
              expiry === 'GTC' ? t('wtr:GTC') : moment(expiry).format('D/MMM/YYYY').toString().toUpperCase(),
            oP: position.oP,
            type: position.type,
            qty: position.qty,
            qCcy: position.qCcy,
            prc: position.prc,
            sl: position.sl,
            tp: position.tp,
            code: position.code,
            isGroupRow: false,
            Time: moment(position.oT).format('HH:mm:ss').toLocaleUpperCase(),
            decPrec: decPrec
          });
        }
      });

      setGridLength(gridRecords.length);
      if (gridRecords.length === 0) {
        setEmptyState(true);
      } else {
        setEmptyState(false);
      }

      //create grid model
      const tradeStore = new ArrayStore({ key: 'id', data: gridRecords });
      const model = {
        records: gridRecords,
        tradeStore: tradeStore,
        dataSource: new DataSource({ store: tradeStore, reshapeOnPush: true }),
        months: CALENDAR_MONTHS_SHORT_EN,
      };

      //subscribe to price updates
      Nullable.of(priceFeed).run((priceFeed) => {
        subscriptionRef.current = priceFeed.stream.subscribe((priceQuote) => {
          const updates: any[] = [];
          model.records
            .filter((value) => value.code === priceQuote.c || value.Instrument === priceQuote.c)
            .forEach((record) => {
              const marketItem = record.position?.marketItem ?? rfpGatewayContext?.getMarketItem(record.code);
              let updatedRecord = Object.assign({}, record); // creating copy of variable
              updatedRecord.Previous = updatedRecord.Current;
              updatedRecord.Current = updatedRecord.Side === 'SELL' ? priceQuote.b : priceQuote.a;
              const open: any = updatedRecord['Open @']
                ? Number(revertToTraditionalNumberFormat(updatedRecord['Open @'].toString(), languageSettings))
                : 0;
              const distanceToMarket = Math.abs(updatedRecord.Current - open);
              updatedRecord.distanceToMarket = !updatedRecord.isGroupRow ? distanceToMarket : '';
              if (updatedRecord.isGroupRow) {
                updatedRecord.lowestDTM =
                  distanceToMarket < (updatedRecord.lowestDTM as number) ? distanceToMarket : updatedRecord.lowestDTM;
                updatedRecord.highestDTM =
                  distanceToMarket > (updatedRecord.highestDTM as number) ? distanceToMarket : updatedRecord.highestDTM;
              }
              if (updatedRecord.Current !== updatedRecord.Previous) {
                updates.push({
                  type: 'update',
                  key: updatedRecord.id,
                  data: {
                    Current: parseFloat(updatedRecord.Current).toFixed(marketItem?.decPrec),
                    Previous: parseFloat(updatedRecord.Previous).toFixed(marketItem?.decPrec),
                    distanceToMarket: isNaN(parseFloat(updatedRecord.distanceToMarket))
                      ? ''
                      : parseFloat(updatedRecord.distanceToMarket).toFixed(marketItem?.decPrec),
                    lowestDTM: updatedRecord.lowestDTM,
                    highestDTM: updatedRecord.highestDTM
                  }
                });
              }
            });
          if (updates.length > 0) {
            model.tradeStore.push(updates);
          }
        });
      });
      return model;
    }, [tradingPositions, columnOption, columnOptionExpired, selectedTradingAccountId, quantityType, searchTerm]);

    const showClose = (ev: any) => {
      if (ticketLayout === TicketLayout.Dock) {
        dashboardContext.closeAllOtherTabs();
        dashboardContext.showCancelTicket = true;
      } else {
        dashboardContext.modalToggle = { closePosition: false, closeOrder: true };
      }
      if (ev.rowType === 'header') {
        const allOrders = tradingPositions.filter(
          (open: TradingPosition) =>
            open.state === TradingPositionState.pending && open.aId === activeTradingAccount?.id
        );
        dashboardContext.headerSelected = true;
        dashboardContext.selectedPosition = allOrders;
      } else {
        dashboardContext.headerSelected = false;
      }
    };

    const showCloseIcon = (ev: any) => {
      const iconStyle = isArabic
        ? {
          fontSize: orderIconsSize,
          marginRight: '4px'
        }
        : {
          fontSize: orderIconsSize,
          marginLeft: '4px'
        };
      return (
        <OverlayTrigger
          delay={{ show: 750, hide: 0 }}
          key={'cancelAllOrders'}
          placement="bottom-end"
          overlay={
            <Tooltip className="my-tooltip" id={'cancelAllOrders'}>
              {t('en:TRADES_CANCEL_ALL_ORDERS')}
            </Tooltip>
          }
        >
          <div className={styles.triggerContainer} onClick={() => showClose(ev)}>
            <FontAwesomeIcon icon={['fas', 'times-circle']} style={iconStyle} className={styles.iconTheme}/>
          </div>
        </OverlayTrigger>
      );
    };

    const showOrderCloseIcon = (e: any) => {
      const iconStyle = isArabic
        ? {
          fontSize: orderIconsSize,
          marginRight: '6px'
        }
        : {
          fontSize: orderIconsSize,
          marginLeft: '6px'
        };
      return (
        <OverlayTrigger
          delay={{ show: 750, hide: 0 }}
          key={`cancel${e.data.code}`}
          placement={isArabic ? 'right' : 'left'}
          overlay={
            <Tooltip className="my-tooltip" id={`cancel${e.data.code}`}>
              {t('en:TRADES_CANCEL_ORDER')}
            </Tooltip>
          }
        >
          <div className={styles.triggerContainer} onClick={() => showClose(e)}>
            <FontAwesomeIcon icon={['fas', 'times-circle']} style={iconStyle} className={styles.iconTheme}/>
          </div>
        </OverlayTrigger>
      );
    };
    const showCogIcon = () => {
      const iconStyle = isArabic
        ? {
          fontSize: orderIconsSize,
          marginRight: '5px'
        }
        : {
          fontSize: orderIconsSize,
          marginLeft: '5px'
        };
      return (
        <OverlayTrigger
          delay={{ show: 750, hide: 0 }}
          key={'ordersGridSettings'}
          placement="bottom-end"
          overlay={
            <Tooltip className="my-tooltip" id={'ordersGridSettings'}>
              {t('en:SETTINGS')}
            </Tooltip>
          }
        >
          <div className={styles.triggerContainer} onClick={handleShowColumnChooser}>
            <FontAwesomeIcon icon={['fas', 'cog']} style={iconStyle} className={styles.iconTheme}/>
          </div>
        </OverlayTrigger>
      );
    };

    const searchMatches = useMemo(() => {
      let matches: Optional<string | number>[] = [];
      let visibleColumns: string[] = [];
      if (treeList.current) {
        treeList.current.instance.refresh();
        visibleColumns =
          treeList.current?.instance
            .getVisibleColumns()
            .filter((column) => column.dataField)
            .map((column) => column.dataField as string) ?? [];
      }
      if (visibleColumns.length === 0) {
        const localData = localStorage.getItem('ordersGridColumns') ?? '[]';
        visibleColumns = JSON.parse(localData)
          .filter((column: any) => column.visible)
          .map((column: any) => column.dataField);
      }
      gridModel.records.forEach((record) => {
        visibleColumns.forEach((columnName: string) => {
          matches.push([(record as any)[columnName]][0]);
        });
      });
      return matches;
    }, [gridModel, columnsUpdated]);

    useEffect(() => {
      const matches = searchMatches.map((item) => item?.toString()).filter((item: Optional<string>) => item);
      if (
        matches.length !== 0 &&
        !(matches as string[]).find((match: string) => match.toUpperCase().includes(searchTerm.toUpperCase()))
      ) {
        setEmptyState(true);
      } else if (gridLength !== 0) {
        setEmptyState(false);
      }
    }, [searchMatches, searchTerm, gridLength]);

    const onOptionChanged = async (e: any) => {
      if (e.fullName === 'onCellClick' && searchTerm.length === 0 && highlightChanged) {
        const selectedRows = e.component.getSelectedRowKeys();
        if (selectedRows[0]?.toString() !== highlightPosition) {
          e.component.deselectAll();
          e.component.selectRows([highlightPosition]);
          // e.component.navigateToRow(highlightPosition)
          e.component.option('focusedRowKey', highlightPosition);
          setHighlightChanged(false);
        }
      } else if (e.fullName === 'dataSource') {
        e.component.hideColumnChooser();
        setHighlightChanged(true);
      }
      //When column visibilty changes, they become searchable or unsearchable. Columns updated triggers updated searchability
      else if (e.fullName.includes('.visible') && !e.fullName.includes('.visibleIndex')) {
        setColumnsUpdated(!columnsUpdated);
      }
    };

    const hidePopup = () => {
      const popup = document.getElementsByClassName(`ui top ${isArabic ? 'left' : 'right'} popup transition visible`);
      if (popup && popup.length > 0) popup[0].className = `ui top ${isArabic ? 'left' : 'right'} popup transition`;
    };

    const openTicket = (e: any) => {
      hidePopup();
      dashboardContext.closeAllOtherTabs();

      // change the Trade context state to 'pending' and fill the trade context with the selected position (needed for the TradeTicket.tsx)
      // and show limit fields only (take profit / stop loss)
      let limitsType: TradingPositionLimitType[] = [];
      if (e.column.name === 'StopLoss') {
        limitsType.push(TradingPositionLimitType.StopLoss);
      } else if (e.column.name === 'TakeProfit') {
        limitsType.push(TradingPositionLimitType.TakeProfit);
      }

      fillStoreFromPosition(e.data.position, limitsType, true);

      // TODO: - Check it the code bellow is needed when refactor the grid
      if (ticketLayout === TicketLayout.Dock) {
        dashboardContext.showOrderTicket = true;

        if (e.column && e.column.caption) {
          if (e.column.name === 'StopLoss' || e.column.name === 'TakeProfit') {
            dashboardContext.gridButton = e.column.name === 'StopLoss' ? 'Stop Loss' : 'Take Profit';
          } else if (e.column && e.column.caption) {
            dashboardContext.gridButton = e.column.caption;
          }
        }
        const record: GridRecord = e.data;
        if (record) {
          const positions = tradingPositions.filter(
            (t: TradingPosition) =>
              t.code === record.code &&
              t.state === TradingPositionState.pending &&
              t.aId === activeTradingAccount?.id &&
              (record.isGroupRow || record.posId === t.posId)
          );

          dashboardContext.selectedPosition = positions;
          dashboardContext.selectedInstrument = e.data.position.marketItem;
        }
      } else {
        dashboardContext.newOrderModalToggle = {
          orderTicket: true,
          confirmOrder: false
        };

        // TODO: - Check it the code bellow is needed when refactor the grid

        const record: GridRecord = e.data;
        if (record) {
          const positions = tradingPositions.filter(
            (t: TradingPosition) =>
              t.code === record.code &&
              t.state === TradingPositionState.pending &&
              t.aId === activeTradingAccount?.id &&
              (record.isGroupRow || record.posId === t.posId)
          );

          dashboardContext.selectedPosition = positions;
          dashboardContext.selectedInstrument = e.data.position.marketItem;
        }

        if (e.column.name === 'StopLoss' || e.column.name === 'TakeProfit') {
          dashboardContext.gridButton = e.column.name === 'StopLoss' ? 'Stop Loss' : 'Take Profit';
        } else if (e.column && e.column.caption) {
          dashboardContext.gridButton = e.column.caption;
        }
      }
    };

    const openMarketInformation = (code: string) => {
      hidePopup();
      if (detailedInformation) {
        const instrumentDetail = getInstrumentDetails(
          detailedInformation,
          activeTradingAccount ? [activeTradingAccount] : [],
          code
        );
        if (instrumentDetail) {
          let marketItem = rfpGatewayContext?.getMarketItem(instrumentDetail.code);
          if (marketItem) {
            dashboardContext.selectedInstrument = marketItem;
          }
        }
        dashboardContext.closeAllOtherTabs();
        dashboardContext.toggleAccordionMenu = '0';
        dashboardContext.showOrderInformation = true;
      }
    };

    const openModifyTicket = (e: any) => {
      hidePopup();
      dashboardContext.closeAllOtherTabs();
      dashboardContext.tradeInformation = undefined;

      // change the Trade context state to 'pending' and fill the trade context with the selected position (needed for the TradeTicket.tsx)
      fillStoreFromPosition(e.data.position);

      if (ticketLayout === TicketLayout.Dock) {
        dashboardContext.showOrderTicket = true;
      } else {
        dashboardContext.newOrderModalToggle = {
          orderTicket: true,
          confirmOrder: false
        };
      }
    };

    const openOrderDetails = (code: string) => {
      hidePopup();
      if (detailedInformation) {
        const instrumentDetail = getInstrumentDetails(
          detailedInformation,
          activeTradingAccount ? [activeTradingAccount] : [],
          code
        );
        if (instrumentDetail) {
          let marketItem = rfpGatewayContext?.getMarketItem(instrumentDetail.code);
          if (marketItem) {
            dashboardContext.selectedInstrument = marketItem;
          }
        }
        dashboardContext.closeAllOtherTabs();
        dashboardContext.toggleAccordionMenu = '2';
        dashboardContext.showOrderInformation = true;
      }
    };

    const showEditIcon = (e: any) => {
      const record: GridRecord = e.data;
      if (record && record['TakeProfit'] !== 'Head') {
        const iconStyle = isArabic
          ? {
            fontSize: orderIconsSize,
            marginRight: '13px',
            marginTop: 0
          }
          : {
            fontSize: orderIconsSize,
            marginLeft: '16px',
            marginTop: 0
          };
        return (
          <OverlayTrigger
            delay={{ show: 750, hide: 0 }}
            key={`more${e.data.code}`}
            placement={isArabic ? 'right' : 'left'}
            overlay={
              <Tooltip className="my-tooltip" id={`more${e.data.code}`}>
                {t('en:NAVIGATION_MORE')}
              </Tooltip>
            }
          >
            <div key={`editPopup${e.data.code}`}>
              <WtrPopup
                className={styles.Popup}
                content={
                  <>
                    <div className={styles.PopupContainer} onClick={() => openOrderDetails(record.code)}>
                      <div>
                        <FontAwesomeIcon icon={['fas', 'info-circle']} size="1x"/>
                      </div>
                      <div>{tt('TRADES_ORDER_DETAILS')}</div>
                    </div>
                    <div className={styles.PopupContainer} onClick={() => openMarketInformation(record.code)}>
                      <div>
                        <FontAwesomeIcon icon={['fas', 'info']} size="1x"/>
                      </div>
                      <div>{t('wtr:MARKET_INFO')}</div>
                    </div>
                    <div className={styles.PopupContainer} onClick={() => openModifyTicket(e)}>
                      <div>
                        <FontAwesomeIcon icon={['fas', 'plus-circle']} size="1x"/>
                      </div>
                      <div>{tt('TRADES_MODIFY_ORDER')}</div>
                    </div>
                  </>
                }
                on={['click']}
                pinned
                position={isArabic ? 'top left' : 'top right'}
                trigger={
                  <div className={styles.triggerContainer}>
                    <FontAwesomeIcon icon={['fas', 'ellipsis-v']} style={iconStyle} className={styles.elipsisIcon}/>
                  </div>
                }
              />
            </div>
          </OverlayTrigger>
        );
      }
    };

    const showProfitButton = (e: any) => {
      const record: GridRecord = e.data;
      if (record && record.TakeProfit !== 'Head') {
        return (
          <OverlayTrigger
            delay={{ show: 750, hide: 0 }}
            key={'takeProfitButton'}
            placement="top-end"
            overlay={
              <Tooltip className="my-tooltip" id={'takeProfitButton'}>
                {t('wtr:WTR_SET_TAKE_PROFIT')}
              </Tooltip>
            }
          >
            <div className={styles.plusContainer}>
              <Button className={styles.plusButton} onClick={() => openTicket(e)}>
                <div className={styles.iconContainer}>
                  {isNaN((record.TakeProfit || Number.NaN) as number) ? (
                    <FontAwesomeIcon icon={['fas', 'plus']} size="1x" className={styles.plusTheme}/>
                  ) : (
                    <div className={styles.profitValues}>
                      {formatNumberWithCommas(record.TakeProfit!, record.decPrec ?? 0, languageSettings)}
                    </div>
                  )}
                </div>
              </Button>
            </div>
          </OverlayTrigger>
        );
      }
    };

    const showLossButton = (e: any) => {
      const record: GridRecord = e.data;
      if (record && record.StopLoss !== 'Head') {
        return (
          <OverlayTrigger
            delay={{ show: 750, hide: 0 }}
            key={'stopLossButton'}
            placement="top-end"
            overlay={
              <Tooltip className="my-tooltip" id={'stopLossButton'}>
                {t('wtr:WTR_SET_STOP_LOSS')}
              </Tooltip>
            }
          >
            <div className={styles.plusContainer}>
              <Button className={styles.plusButton} onClick={() => openTicket(e)}>
                <div className={styles.iconContainer}>
                  {isNaN((record.StopLoss || Number.NaN) as number) ? (
                    <FontAwesomeIcon icon={['fas', 'plus']} size="1x" className={styles.plusTheme}/>
                  ) : (
                    <div className={styles.profitValues}>
                      {formatNumberWithCommas(record.StopLoss!, record.decPrec ?? 0, languageSettings)}
                    </div>
                  )}
                </div>
              </Button>
            </div>
          </OverlayTrigger>
        );
      }
    };

    const handleShowColumnChooser = () => {
      setShowSettings(false);
      if (treeList.current) {
        treeList.current.instance.showColumnChooser();
      }
    };

    const handleHideColumnChooser = () => {
      if (treeList.current) {
        treeList.current.instance.hideColumnChooser();
      }
    };

    const handleRowClick = (e: any) => {
      if (!emptyState) {
        dashboardContext.closeAllOtherTabs();
        if (e.event.target.tagName.toLowerCase() !== 'span') {
          const record: GridRecord = e.data;
          if (record) {
            const positions: any = tradingPositions.filter(
              (t: TradingPosition) =>
                t.code === record.code &&
                t.state === TradingPositionState.pending &&
                t.aId === activeTradingAccount?.id &&
                (record.isGroupRow || record.posId === t.posId)
            );
            dashboardContext.selectedPosition = positions;
            dashboardContext.gridChartsChanged = true;
          }
          if (marketItems && marketItems.length > 0 && record) {
            dashboardContext.tradeInformation = undefined;
            dashboardContext.selectedType = 'Grid';
            dashboardContext.isEdit = false;
            dashboardContext.showConfirmTicket = false;
            dashboardContext.showCloseTicket = false;
            dashboardContext.selectedInstrument = rfpGatewayContext?.getMarketItem(record.code);
            setHighlightedInstrument(e.key);
            setHighlightPosition(e.data.id);
            e.component.deselectAll();
            e.component.selectRows([e.data.id]);
            e.component.navigateToRow(e.data.id);
          }
        }
      } else {
        console.log('Empty grid clicked');
      }
    };

    const onContentReady = async (e: any) => {
      const columnChooserView = e.component.getView('columnChooserView');
      if (!columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render();
        columnChooserView._popupContainer.option('height', 200);
        columnChooserView._popupContainer.option('width', 215);
        columnChooserView._popupContainer.option('dragEnabled', false);
        const position = isArabic
          ? {
            my: 'top left',
            at: 'top left',
            offset: '50 5'
          }
          : {
            my: 'top right',
            at: 'top right',
            offset: '-50 5'
          };
        columnChooserView._popupContainer.option('position', position);
      }
      const getColumnOption = e.component.columnOption('Created On Translated').sortOrder;
      const getColumnOptionExpired = e.component.columnOption('ExpiryOnTranslated').sortOrder;
      setColumnOption(getColumnOption);
      setColumnOptionExpired(getColumnOptionExpired);
    };

    const handleStartTrading = () => {
      if (orderTicketAccess()) {
        const code = selectedSymbol?.chart0?.code || 'GBPUSD';
        const instrument =
          selectedInstrument && Object.keys(selectedInstrument).length > 0
            ? selectedInstrument
            : rfpGatewayContext?.getMarketItem(code);
        if (instrument) {
          dashboardContext.selectedInstrument = instrument;
        }
        dashboardContext.closeAllOtherTabs();
        dashboardContext.ticketLayout = TicketLayout.Dock;
        dashboardContext.showOrderTicket = true;
        instrumentContext.focusInstrumentSearchInput();
        dashboardContext.startTradingNow = true;
      }
    };

    const renderClearFilters = () => {
      return searchTerm.length > 0 ? (
        <div className={styles.emptyGridContainer}>
          <FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'search']} size="5x"/>
          <div className={styles.emptyStateMessage}>{t('wtr:NO_NAMED_PENDING_ORDERS')}</div>
          <div className={styles.emptyButtonContainer}>
            <div className={styles.clearFilters} onClick={() => setSearchTerm('')}>
              {t('wtr:CLEAR_FILTERS')}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.emptyGridContainer}>
          <FontAwesomeIcon icon={['fas', 'tasks']} size="5x" className={styles.emptyIcon}/>
          <div className={styles.emptyStateMessage}>{t('wtr:NO_PENDING_ORDERS')}</div>
          <div className={styles.emptyButtonContainer}>
            <a
              className={showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
              onClick={handleStartTrading}
            >
              <FontAwesomeIcon icon={['fas', 'plus-circle']} size="1x"/> {t('en:START_TRADING')}
            </a>
          </div>
        </div>
      );
    };

    const calculateCurrent = (data: any) => {
      const record: GridRecord = data.data;
      const colorValue = record.Current && record.Previous && record.Current >= record.Previous ? 'green' : 'red';
      return (
        <div className={colorValue === 'green' ? styles.greenCurrent : styles.redCurrent}>
          {record.isGroupRow ? '' : addCommas(record.Current)}
        </div>
      );
    };

    const highlightCell = (data: any) => {
      const cssClass = cn(
        headerSelect === data.column.caption.replace(/\s/g, '') ? styles[`${theme}headerCell`] : '',
        data.column.caption === tt('INSTRUMENT') ? styles.headerCellInstrument : ''
      );

      return (
        <div className={cssClass} tabIndex={0}>
          {data.column.caption}
        </div>
      );
    };

    const changeHighlight = (data: any) => {
      if (data.rowType === 'header') {
        setHeaderSelect(data.column.dataField?.replace(/\s/g, ''));
      }
    };

    const rowsExpanded = useMemo(() => {
      const localItem = localStorage.getItem('ordersGridExpand');
      if (localItem) return JSON.parse(localStorage.getItem('ordersGridExpand') as string);
      return [];
    }, []);

    const customSave = useCallback(
      (e) => {
        if (!emptyState) {
          localStorage.setItem(
            'ordersGridColumns',
            JSON.stringify(
              (e.columns as any[])?.map((c) => ({
                dataField: c.dataField,
                dataType: c.dataType,
                name: c.name,
                visible: c.visible,
                visibleIndex: c.visibleIndex,
                width: c.width,
                sortOrder: c.sortOrder,
                sortIndex: c.sortIndex
              }))
            )
          );
          if (e.expandedRowKeys) {
            localStorage.setItem('ordersGridExpand', JSON.stringify(e.expandedRowKeys));
          }
          const createdColumn = e.columns?.find(
            (column: { dataField: string }) => column.dataField === 'Created On Translated'
          );
          const expColumn = e.columns.find(
            (column: { dataField: string }) => column.dataField === 'ExpiryOnTranslated'
          );
          const dtmColumn = e.columns.find((column: { dataField: string }) => column.dataField === 'distanceToMarket');
          localStorage.setItem('createdDateDirection', JSON.stringify(createdColumn.sortOrder));
          localStorage.setItem('expDateDirection', JSON.stringify(expColumn.sortOrder));
          localStorage.setItem('dtmDirection', JSON.stringify(dtmColumn.sortOrder));
        }
      },
      [emptyState]
    );

    const renderDistanceToMarket = useCallback((row) => {
      return row.data.distanceToMarket
        ? formatNumberWithCommas(row.data.distanceToMarket, row.data.decPrec, languageSettings)
        : '';
    }, []);

    const renderExpiryDate = useCallback((data) => {
      const record: GridRecord = data.data;
      return <div style={{ direction: 'ltr' }}>{record.ExpiryOnTranslated}</div>;
    }, []);

    const renderCreatedDate = (data: any) => {
      const record: GridRecord = data.data;
      return <div style={{ direction: 'ltr' }}>{record['Created On Translated']}</div>;
    };

    const sortCreatedDate = useCallback((rowValue: any) => {
      const direction = JSON.parse(localStorage.getItem('createdDateDirection') ?? 'desc');
      return rowValue.isGroupRow
        ? moment(direction === 'asc' ? rowValue.oldestCreatedDate : rowValue.newestCreatedDate).valueOf()
        : moment(rowValue['Created On']).valueOf();
    }, []);

    const sortExpDates = useCallback((rowValue: any) => {
      function isGTC(value: string | number) {
        return value === 'G.T.C' || value === 'GTC' || value === t('wtr:GTC') ? Infinity : moment(value).valueOf();
      }

      const direction = JSON.parse(localStorage.getItem('expDateDirection') ?? 'desc');
      return rowValue.isGroupRow
        ? direction === 'asc'
          ? isGTC(rowValue.nearestExpDate)
          : isGTC(rowValue.farthestExpDate)
        : isGTC(rowValue.ExpiryOn);
    }, []);

    const sortAmountLots = useCallback(
      (rowValue: any) => {
        const value = quantityType === QUANTITY_TYPE.LOTS ? rowValue.Lots : rowValue.Amount;
        return Number(revertToTraditionalNumberFormat(value, languageSettings));
      },
      [quantityType]
    );

    const sortDistanceToMarket = useCallback((rowValue: any) => {
      const direction = JSON.parse(localStorage.getItem('dtmDirection') ?? 'desc');
      return rowValue.isGroupRow
        ? direction === 'asc'
          ? rowValue.highestDTM
          : rowValue.lowestDTM
        : rowValue.distanceToMarket;
    }, []);

    const columnsMap: { [key: string]: { [key: string]: any } } = useMemo(
      () =>
        JSON.parse(localStorage.getItem('ordersGridColumns') ?? '[]').reduce((res: any, current: any) => {
          res[current.dataField] = current;
          return res;
        }, {}),
      []
    );
    const hasColumns = useMemo(() => columnsMap && Object.keys(columnsMap).length > 0, [columnsMap]);

    const getAttribute = useCallback(
      (attribute: string, dataField: string) => {
        if (emptyState && attribute === 'visible') return false;
        if (!hasColumns) return undefined;
        return columnsMap[dataField]?.[attribute];
      },
      [emptyState]
    );

    const quantityTypeString = useMemo(() => (quantityType === QUANTITY_TYPE.LOTS ? 'Lots' : 'Amount'), [quantityType]);

    if (emptyState) {
      return renderClearFilters();
    }

    return (
      <div className={styles.grid}>
        <TreeList
          dataSource={gridModel.dataSource}
          allowColumnResizing={true}
          columnResizingMode="nextColumn"
          showRowLines={true}
          showColumnLines={false}
          showBorders={true}
          rootValue={-1}
          keyExpr="id"
          parentIdExpr="headID"
          className={!maximizeGrid ? styles.treeListLayout : styles.maximizeTreeListLayout}
          ref={treeList}
          onContentReady={onContentReady}
          onRowClick={handleRowClick}
          onOptionChanged={onOptionChanged}
          repaintChangesOnly={true}
          onCellClick={changeHighlight}
          hoverStateEnabled={!emptyState}
          defaultExpandedRowKeys={rowsExpanded}
          autoNavigateToFocusedRow={true}
          rtlEnabled={isArabic}
        >
          <ColumnChooser
            title={t('wtr:COLUMN_CHOOSER')}
            emptyPanelText={t('wtr:DRAG_COLUMN')}
            width={DX_COLUMN_CHOOSER_SIZE.width}
            height={DX_COLUMN_CHOOSER_SIZE.height}
          />
          <LoadPanel enabled={false}/>
          <SearchPanel
            visible={false}
            placeholder={t('wtr:SEARCH_FOR_POSITION')}
            text={searchTerm}
            width={200}
            searchVisibleColumnsOnly={true}
          />
          <Selection mode={emptyState ? 'none' : 'single'}/>
          <StateStoring enabled={true} type="custom" customSave={customSave} savingTimeout={0}/>
          <Column
            dataField="Instrument"
            caption={tt('INSTRUMENT')}
            allowHiding={false}
            fixedPosition={isArabic ? 'right' : 'left'}
            sortIndex={getAttribute('sortIndex', 'Instrument')}
            sortOrder={getAttribute('sortOrder', 'Instrument') ?? 'asc'}
            width={getAttribute('width', 'Instrument') ?? 190}
            minWidth="120"
            headerCellRender={highlightCell}
            visible={!emptyState}
            visibleIndex={0}
            allowSearch={!emptyState}
            alignment={isArabic ? 'right' : ''}
            cellRender={(data: any) => <>{data.data['Instrument']}&lrm;</>} // display grouped brackets properly
            fixed={true}
          />
          <Column
            allowReordering={true}
            caption={tt('SIDE')}
            sortIndex={getAttribute('sortIndex', 'SideTranslated')}
            sortOrder={getAttribute('sortOrder', 'SideTranslated') ?? 'desc'}
            dataField="SideTranslated"
            headerCellRender={highlightCell}
            visible={getAttribute('visible', 'SideTranslated') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'SideTranslated') ?? 1}
            allowSearch={!emptyState}
            width={getAttribute('width', 'SideTranslated')}
            minWidth="100"
            alignment={isArabic ? 'right' : ''}
          />
          <Column
            allowReordering={true}
            caption={tt('Type')}
            sortIndex={getAttribute('sortIndex', 'TypeTranslated')}
            sortOrder={getAttribute('sortOrder', 'TypeTranslated') ?? 'desc'}
            dataField="TypeTranslated"
            width={getAttribute('width', 'TypeTranslated')}
            minWidth="72"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', 'TypeTranslated') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'TypeTranslated') ?? 2}
            allowSearch={!emptyState}
            alignment={isArabic ? 'right' : ''}
          />
          <Column
            allowReordering={true}
            caption={tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
            calculateSortValue={sortAmountLots}
            width={getAttribute('width', quantityTypeString)}
            minWidth="72"
            sortIndex={getAttribute('sortIndex', quantityTypeString)}
            sortOrder={getAttribute('sortOrder', quantityTypeString) ?? 'desc'}
            dataField={quantityTypeString}
            alignment="right"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', quantityTypeString) ?? true}
            visibleIndex={getAttribute('visibleIndex', quantityTypeString) ?? 3}
            allowSearch={!emptyState}
          />
          <Column
            allowReordering={true}
            caption={t('wtr:CREATED_ON')}
            calculateSortValue={sortCreatedDate}
            sortIndex={getAttribute('sortIndex', 'Created On Translated')}
            sortOrder={getAttribute('sortOrder', 'Created On Translated') ?? 'desc'}
            dataField="Created On Translated"
            width={getAttribute('width', 'Created On Translated')}
            minWidth="72"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', 'Created On Translated') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'Created On Translated') ?? 4}
            allowSearch={!emptyState}
            alignment={isArabic ? 'right' : 'left'}
            cellRender={renderCreatedDate}
          />
          <Column
            allowReordering={true}
            caption={tt('OPEN_AT')}
            dataType="datetype"
            sortIndex={getAttribute('sortIndex', 'Open @')}
            sortOrder={getAttribute('sortOrder', 'Open @') ?? 'desc'}
            dataField="Open @"
            alignment="right"
            width={getAttribute('width', 'Open @')}
            minWidth="72"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', 'Open @') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'Open @') ?? 5}
            allowSearch={!emptyState}
          />
          <Column
            allowReordering={true}
            caption={t('wtr:DISTANCE_TO_MARKET')}
            calculateSortValue={sortDistanceToMarket}
            sortIndex={getAttribute('sortIndex', 'distanceToMarket')}
            sortOrder={getAttribute('sortOrder', 'distanceToMarket') ?? 'desc'}
            dataField="distanceToMarket"
            width={getAttribute('width', 'distanceToMarket')}
            minWidth="72"
            alignment="right"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', 'distanceToMarket') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'distanceToMarket') ?? 6}
            allowSearch={!emptyState}
            cellRender={renderDistanceToMarket}
          />
          <Column
            allowReordering={true}
            caption={tt('CURRENT')}
            sortIndex={getAttribute('sortIndex', 'Current')}
            sortOrder={getAttribute('sortOrder', 'Current') ?? 'desc'}
            dataType="number"
            dataField="Current"
            cellRender={calculateCurrent}
            width={getAttribute('width', 'Current')}
            minWidth="72"
            alignment="right"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', 'Current') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'Current') ?? 7}
            allowSearch={!emptyState}
          />
          <Column
            allowReordering={true}
            caption={tt('TAKE_PROFIT')}
            allowSorting={false}
            dataField="TakeProfit"
            cellRender={showProfitButton}
            width={getAttribute('width', 'TakeProfit')}
            visible={getAttribute('visibile', 'TakeProfit') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'TakeProfit') ?? 8}
            allowSearch={!emptyState}
            minWidth="90"
            cssClass={styles.tpslCell}
          />
          <Column
            allowReordering={true}
            caption={tt('STOP_LOSS')}
            allowSorting={false}
            dataField="StopLoss"
            cellRender={showLossButton}
            width={getAttribute('width', 'StopLoss')}
            visible={getAttribute('visibile', 'StopLoss') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'StopLoss') ?? 9}
            allowSearch={!emptyState}
            minWidth="90"
            cssClass={styles.tpslCell}
          />
          <Column
            allowReordering={true}
            caption={tt('EXPIRY_ON')}
            sortIndex={getAttribute('sortIndex', 'ExpiryOnTranslated')}
            sortOrder={getAttribute('sortOrder', 'ExpiryOnTranslated') ?? 'desc'}
            calculateSortValue={sortExpDates}
            dataField="ExpiryOnTranslated"
            alignment={isArabic ? 'right' : 'left'}
            width={getAttribute('width', 'ExpiryOnTranslated')}
            minWidth="72"
            headerCellRender={highlightCell}
            visible={getAttribute('visibile', 'ExpiryOnTranslated') ?? true}
            visibleIndex={getAttribute('visibleIndex', 'ExpiryOnTranslated') ?? 10}
            allowSearch={!emptyState}
            cellRender={renderExpiryDate}
          />
          <Column
            allowReordering={true}
            caption={tt('OPEN_TIME')}
            sortIndex={getAttribute('sortIndex', 'Time')}
            sortOrder={getAttribute('sortOrder', 'Time') ?? 'desc'}
            dataField="Time"
            alignment="right"
            visible={getAttribute('visibile', 'Time') ?? false}
            visibleIndex={getAttribute('visibleIndex', 'Time') ?? 11}
            allowSearch={!emptyState}
            width={getAttribute('width', 'Time')}
            minWidth="72"
            allowSorting={false}
          />
          <Column
            fixedPosition={isArabic ? 'left' : 'right'}
            allowResizing={false}
            allowReordering={false}
            allowHiding={false}
            allowSorting={false}
            headerCellRender={showCogIcon}
            cellRender={showEditIcon}
            width={40}
            visible={!emptyState}
            visibleIndex={12}
            allowSearch={!emptyState}
            cssClass={styles.closeAndMoreOptionsCell}
            fixed={true}
          />
          <Column
            fixedPosition={isArabic ? 'left' : 'right'}
            allowResizing={false}
            allowReordering={false}
            allowHiding={false}
            allowSorting={false}
            headerCellRender={showCloseIcon}
            cellRender={showOrderCloseIcon}
            width={40}
            visible={!emptyState}
            visibleIndex={13}
            allowSearch={!emptyState}
            cssClass={styles.closeAndMoreOptionsCell}
            fixed={true}
          />
          <Column
            allowReordering={true}
            caption={tt('ORDER_ID')}
            sortIndex={getAttribute('sortIndex', 'posId')}
            sortOrder={getAttribute('sortOrder', 'posId') ?? 'desc'}
            dataField="posId"
            alignment="right"
            visible={getAttribute('visible', 'posId') ?? false}
            visibleIndex={getAttribute('visibleIndex', 'posId') ?? 16}
            allowSearch={!emptyState}
            width={getAttribute('width', 'posId')}
            minWidth="72"
          />
          <Scrolling showScrollbar={emptyState ? 'never' : 'always'} mode="standard"/>
        </TreeList>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.searchTerm === nextProps.searchTerm;
  }
);

export default OrdersGrid;
