import { useContext } from 'react';

import AppContext from '../../contexts/AppContext';
import DashboardContext from '../../contexts/DashboardContext';

import authStore from '../../store/authStore';
import pipStore from '../../store/PipsStore/pipStore';

import useSaveUserPreferences from './useSaveUserPreferences';

/**
 * Sets the default pips in the user preferences - local and BE
 */
const useUpdateUserPrefPips = () => {
	const { userPreferences } = useContext(AppContext);
	const { accountType } = useContext(DashboardContext);

	const { mutate: saveUserPreferences } = useSaveUserPreferences();

	const stopLossPips = pipStore.use.stopLoss();
	const takeProfitPips = pipStore.use.takeProfit();
	const { email } = authStore.use.userProfile();

	return () => {
		if (userPreferences && userPreferences.user_prefs.trading.settings[accountType]) {
			userPreferences.user_prefs.trading.settings[accountType]!.pips = {
				takeProfit: takeProfitPips,
				stopLoss: stopLossPips,
			};

			const convertUserPref = JSON.stringify(userPreferences);

			if (convertUserPref !== null && convertUserPref !== 'null') {
				localStorage.setItem(`userPref-${email}`, convertUserPref);
			}
			saveUserPreferences();
		}
	};
};

export default useUpdateUserPrefPips;
