import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SettingTriggerProps {
  handleShowColumnChooser: Dispatch<SetStateAction<boolean>>;
}

const SettingTrigger: FC<SettingTriggerProps> = ({ handleShowColumnChooser }) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      delay={{ show: 750, hide: 0 }}
      key={'marketsGridSettings'}
      placement="bottom-end"
      overlay={
        <Tooltip className="my-tooltip" id={'marketsGridSettings'}>
          {t('en:SETTINGS')}
        </Tooltip>
      }
    >
      <div
        onClick={() => handleShowColumnChooser(state => !state)}
      >
        <FontAwesomeIcon
          icon={['far', 'gear']}
          style={{ fontSize: '20px', marginLeft: '6px' }}
        />
      </div>
    </OverlayTrigger>
  );
};

export default SettingTrigger;