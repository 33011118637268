import { useQuery, useQueryClient } from '@tanstack/react-query';

import axios from 'axios';

import authStore, { AuthStore } from '../../store/authStore';

const useSignalsAccessRestriction = ({ enabled }: { enabled: boolean }) => {
	const queryKey = 'signalsUserRestriction';
	const queryClient = useQueryClient();

	const email = authStore((store: AuthStore) => store.userProfile.email);

	const checkIfSignalsAreAccessible = async () => {
		if (!email) {
			return false;
		}

		const url = `${process.env.REACT_APP_SIGNALS_URL}/check-access/${email}`;

		try {
			const response = await axios(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data?.allowed;
		} catch (error) {
			console.error(error);
			return true;
		}
	};

	return useQuery([queryKey], checkIfSignalsAreAccessible, {
		enabled: enabled,
		initialData: () => {
			return queryClient.getQueryData([queryKey]);
		},
	});
};

export default useSignalsAccessRestriction;
