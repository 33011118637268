import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';

import {AppComponentType} from '../../../../../utils/functions/enums';
import {IStatusConfigEnum} from '../../../../components/Permissions/config.types';

import Routes from '../../../../../setup/routes';

import {
    canSeeSubscriptionDetails,
    checkHasSubscriptionOptions,
    getActiveTier,
    getTierDisplayName,
    SubscriptionModalReason,
} from '../../../../../utils/functions/subscriptionUtils';

import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../../utils/hooks/useObservable';
import useSubscriptionInfo from '../../../../../utils/hooks/useSubscriptionInfo';
import useSubscriptionModal from '../../../../../utils/hooks/useSubscriptionModal';

import AccountStatusCTA from '../../../../../shared/AccountStatusCTA/AccountStatusCTA';

import statusStore, {StatusStore} from '../../../../../store/accountStatusStore';

import authStore, {AuthStore} from '../../../../../store/authStore';

import {useSwitchToLive} from '../../../../../utils/hooks/system/useSwitchMode';

import {closeChildWindows} from '../../../../../utils/functions/closeChildWindows';

import WindowContext from '../../../../../contexts/WindowContext';

import SettingsOptions from './SettingsOptions';

import styles from './AccountMenuPopup.module.scss';
import SubscriptionOptions from './SubscriptionOptions';
import AccountIconAndTier from './AccountIconAndTier';

interface IAccountMenuPopup {
    setAccountPopupIsOpen: (a: boolean) => void;
    setOpenSettings: (a: boolean) => void;
}

const AccountMenuPopup = ({setAccountPopupIsOpen, setOpenSettings,}: IAccountMenuPopup) => {
    const {t} = useTranslation();
    const history = useHistory();
    const forceRerender = useForceRerender();
    const [subscriptionInfo] = useSubscriptionInfo();
    const {setModalStateProps} = useSubscriptionModal();
    const setShowAccountStatus = statusStore((state: any) => state.setShowAccountStatus);
    const appContext = useContext(AppContext);
    const dashboardContext = useContext(DashboardContext);
    const windowContext = useContext(WindowContext);
    const permissions = statusStore((store: StatusStore) => store.permissions);
    const isTraderGymActive = statusStore((store: StatusStore) => store.isTraderGymActive);
    const tradingMode = authStore((store: AuthStore) => store.tradingMode);
    let accountMenuAction = permissions?.accountMenuAction;
    let accountMenuLabel = permissions?.accountMenuLabel;
    const {isJapanAccount, email} = appContext;
    const userProfile = authStore.use.userProfile();

    const switchToLive = useSwitchToLive();

    if (isTraderGymActive && appContext.isJapanAccount) {
        accountMenuAction = 'switchToRealMoney';
        accountMenuLabel = 'en:SWITCH_TO_REAL';
    }

	useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), () => {
		forceRerender();
	});

    const handleTpRedirectButton = (e: any) => {
        document.body.click();
        if (accountMenuAction === IStatusConfigEnum.switchToRealMoney) {
            switchToLive();
        } else if (accountMenuAction === IStatusConfigEnum.fund) {
            dashboardContext.presentComponentType = AppComponentType.Deposits;
            history.push(Routes.trader.funds, {from: window.location.pathname});
        } else if (accountMenuAction === IStatusConfigEnum.accountStatus) {
            setShowAccountStatus();
        }
    };
    // TODO: - Rewrite conditions in order to keep them consistent and don't mix UI button text with Context logic

    const handleTpRedirectReports = (action: string = '') => {
        document.body.click();
        if (action === IStatusConfigEnum.reports) {
            dashboardContext.presentComponentType = AppComponentType.Reports;
            history.push(Routes.trader.reports, {from: window.location.pathname});
        }
        if (action === IStatusConfigEnum.accountStatus) {
            setShowAccountStatus();
        }

        return;
    };

    const handleTpRedirectManageFunds = (action: string = '') => {
        document.body.click();
        if (action === IStatusConfigEnum.fund) {
            dashboardContext.presentComponentType = AppComponentType.Funds;
            history.push(Routes.trader.funds, {from: window.location.pathname});
        }
        if (action === IStatusConfigEnum.accountStatus) {
            setShowAccountStatus();
        }

        return;
    };

    const hasSubscriptionOptions =
        isJapanAccount && tradingMode === 'LIVE' && checkHasSubscriptionOptions(subscriptionInfo.status);

    const handleClickSubscriptionDetails = () => {
        closeChildWindows(windowContext);
        setModalStateProps({
            reason: SubscriptionModalReason.SubscriptionDetails,
            isOpen: true,
        });
        setAccountPopupIsOpen(false);
    };

    const hasTierInfo = isJapanAccount && tradingMode === 'LIVE' && getActiveTier(subscriptionInfo);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.account}>
                    <div className={styles.userIconContainer}>
                        <AccountIconAndTier size="large"/>
                    </div>

					{hasTierInfo && canSeeSubscriptionDetails(subscriptionInfo.status) ? (
						<div className={styles.tierInfo}>
							<div>
								{getTierDisplayName(subscriptionInfo.tier)}

                                <div>
                                    <button className={styles.viewSubscriptionDetailsButton}
                                            onClick={handleClickSubscriptionDetails}>
                                        {t('wtr:VIEW_STATUS')}
                                    </button>
                                </div>
                            </div>

							{subscriptionInfo.tier !== subscriptionInfo.nextTier && (
								<div className={styles.nextTierInfo}>
									{t('wtr:NEXT_MONTH')}: {getTierDisplayName(subscriptionInfo.nextTier)}
								</div>
							)}
						</div>
					) : (
                        <>
                            {userProfile.fullName && <div className={styles.fullName}>{userProfile.fullName}</div>}
                            <div className={styles.email}>{email}</div>
                        </>
					)}
				</div>
				<div className={styles.switchButtonContainer}>
					{accountMenuAction && (
                        <AccountStatusCTA
                            onClick={handleTpRedirectButton}
                            size={'fluid'}
                            variant="primary"
                            isFullHeight={true}
                            label={accountMenuLabel}
                        />
					)}
				</div>
			</div>
			<div className={styles.options}>
				{hasSubscriptionOptions && <SubscriptionOptions setAccountPopupIsOpen={setAccountPopupIsOpen} />}
                {hasSubscriptionOptions && <div className={styles.borderBottom}></div>}
                <SettingsOptions
					setAccountPopupIsOpen={setAccountPopupIsOpen}
					setOpenSettings={setOpenSettings}
					handleTpRedirectReports={handleTpRedirectReports}
					handleTpRedirectManageFunds={handleTpRedirectManageFunds}
				/>
			</div>
		</>
	);
};

export default AccountMenuPopup;
