import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import cn from 'classnames';

import {Loader} from 'semantic-ui-react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useTranslation} from 'react-i18next';

import {IconName} from '@fortawesome/fontawesome-svg-core';

import DashboardContext from '../../../../../contexts/DashboardContext';
import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';
import {RFPDataObjectType} from '../../../../../gateways/RfpGateway/rfp.types';
import accountStatusStore from '../../../../../store/accountStatusStore';
import AppContext, {TTradingMode} from '../../../../../contexts/AppContext';

import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import TradingAccounts from '../components/TradingAccounts';

import authStore from '../../../../../store/authStore';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

import tradingAccountStore from '../../../../../store/tradingAccountStore';
import AccountCurrency from '../components/AccountCurrency';

import styles from './CurrencyStats.module.scss';

const TRADING_ACCOUNTS_TYPES = ['LIVE', 'DEMO'];

const CurrencyStats = () => {
    const {t} = useTranslation();

    const dashboardContext = useContext(DashboardContext);
    const appContext = useContext(AppContext);
    const rfpGateway = useContext(RfpGatewayContext);

    const {isJapanAccount} = appContext;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const hasLiveAccount = accountStatusStore.use.hasLiveAccount();
    const hasDemoAccount = accountStatusStore.use.hasDemoAccount();

    const isLiveMode = authStore.use.isLiveMode();
    const isDemoMode = authStore.use.isDemoMode();

    const tradingMode = authStore.use.tradingMode();

    const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

    const [activeMenuItem, setActiveMenuItem] = useState<TTradingMode | 'live' | 'demo'>(
        tradingMode.toLowerCase() as TTradingMode | 'live' | 'demo'
    );

    const selectedTradingAccount = useSelectedTradingAccount();

    let iconButton: IconName = 'caret-down';
    if (isOpen) {
        iconButton = 'caret-up';
    }

    const accountBaseCurrency = selectedTradingAccount?.baseCurrency;

    const accountTypeStyling = tradingMode === 'LIVE' ? styles.live : styles.demo;
    const subIdRef = useRef<string | undefined>(undefined);
    const [hasTradingAccountsError, setHasTradingAccountsError] = useState<boolean>(false);

    useEffect(() => {
        if (dashboardContext.showOrderTicket) {
            setIsOpen(false);
        }
    }, [dashboardContext.showOrderTicket]);

    useEffect(() => {
        if (rfpGateway) {
            subIdRef.current = rfpGateway.subscribeFor(RFPDataObjectType.TradingAccountsError, (message) => {
                // TODO: handle error
                setHasTradingAccountsError(true);
            });
        }
        return () => {
            if (rfpGateway && subIdRef.current) {
                rfpGateway.unsubscribeFor(subIdRef.current);
                subIdRef.current = undefined;

                setHasTradingAccountsError(false);
            }
        };
    }, []);

    // We need accountFlag conditions because TFBO and RFP get information about selectedTradingAccount at different times
    // and this causes problems with displaying loader and icons for the AccountCurrency component
    const accountFlag = useMemo(() => {
        if (selectedTradingAccount) {
            return <AccountCurrency code={selectedTradingAccount.baseCurrency}/>;
        }

        if (
            hasTradingAccountsError ||
            (isLiveMode && hasLiveAccount === false) ||
            (isDemoMode && hasDemoAccount === false)
        ) {
            return <FontAwesomeIcon className={styles.exclamationIcon} icon={['fas', 'exclamation-circle']}/>;
        }

        return <Loader className={styles.loader} active/>;
    }, [selectedTradingAccount, hasLiveAccount]);

    useEffect(() => {
        if (!isOpen && TRADING_ACCOUNTS_TYPES.includes(tradingMode)) {
            setActiveMenuItem(tradingMode.toLowerCase() as TTradingMode);
        }
    }, [isOpen, tradingMode]);

    return (
        <WtrPopup
            on={['click']}
            position="bottom left"
            open={isOpen}
            basic
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            trigger={
                <div className={cn(styles.container, !isJapanAccount && styles.cursorPointer)}>
                    <div className={cn(styles.currencyIcon, accountTypeStyling)}>{accountFlag}</div>
                    <div className={styles.stats}>
                        <div className={styles.accountIdAndType}>
							<span className={cn(styles.accountTradingMode, accountTypeStyling)}>
								{t(`wtr:${tradingMode}`).toUpperCase()}
							</span>
                            &nbsp;
                            <span>{selectedTradingAccount?.accountNumber || 'N/A'}</span>
                        </div>
                        <span className={styles.accountBaseCurrency}>
							{isSpreadBettingAccount ? t('en:SPREAD_BETTING_PRODUCT') : <>{accountBaseCurrency ?? <>&nbsp;&nbsp;&nbsp;&nbsp;</>} • {t('wtr:CFD')}</>}
						</span>
                    </div>
                    {!isJapanAccount && <FontAwesomeIcon icon={['fas', iconButton]} className={styles.toggleIcon}/>}
                </div>
            }
            content={
                !isJapanAccount && (
                    <div className={styles.popup}>
                        <div className={styles.menuHeader}>
                            <div
                                className={cn(styles.menuItem, activeMenuItem === 'live' && styles.active)}
                                onClick={() => setActiveMenuItem('live')}
                            >
                                {t('en:LOGIN_VIEW_LIVE')}
                            </div>
                            <div
                                className={cn(styles.menuItem, activeMenuItem === 'demo' && styles.active)}
                                onClick={() => setActiveMenuItem('demo')}
                            >
                                {t('en:LOGIN_VIEW_DEMO')}
                            </div>
                        </div>
                        <div className={styles.content}>
                            <TradingAccounts
                                setAccountPopupIsOpen={setIsOpen}
                                activeMenuItem={activeMenuItem}
                                selectedTradingAccount={selectedTradingAccount}
                            />
                        </div>
                    </div>
                )
            }
        />
    );
};

export default CurrencyStats;
