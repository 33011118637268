import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import cn from 'classnames';

import AppContext from '../../../../../contexts/AppContext';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import SettingsModal from './SettingsModal';
import AccountMenuPopup from './AccountMenuPopup';
import AccountIconAndTier from './AccountIconAndTier';

import styles from './AccountMenu.module.scss';

interface accountMenuProps {
	accountPopupIsOpen: boolean;
	setAccountPopupIsOpen: (accountPopup: boolean) => void;
	setResetIdleTimer: Dispatch<SetStateAction<boolean>>;
}

const AccountMenu: React.FC<accountMenuProps> = ({ accountPopupIsOpen, setAccountPopupIsOpen, setResetIdleTimer }) => {
	const appContext = useContext(AppContext);
	const [openSettings, setOpenSettings] = useState(false);

	return (
		<>
			<WtrPopup
				open={accountPopupIsOpen}
				className={styles.popup}
				onOpen={() => setAccountPopupIsOpen(true)}
				onClose={() => setAccountPopupIsOpen(false)}
				on={['click']}
				pinned
				position={appContext.isArabic ? 'bottom left' : 'bottom right'}
				trigger={
					<div
						className={cn({
							[styles.userIconContainer]: true,
							[styles.userIconContainerClicked]: accountPopupIsOpen,
						})}
					>
						{<AccountIconAndTier accountPopupIsOpen={accountPopupIsOpen} />}
					</div>
				}
				basic
				content={
					<AccountMenuPopup
						setAccountPopupIsOpen={setAccountPopupIsOpen}
						setOpenSettings={setOpenSettings}
					/>
				}
			/>
			{openSettings && appContext.userPreferences?.user_prefs && (
				<SettingsModal
					openSettings={openSettings}
					setOpenSettings={setOpenSettings}
					setResetIdleTimer={setResetIdleTimer}
				/>
			)}
		</>
	);
};

export default AccountMenu;
