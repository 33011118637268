import React, {useContext, useEffect, useState} from 'react';

import {Trans, useTranslation} from 'react-i18next';

import {useHistory} from 'react-router-dom';

import AppContext from '../../../contexts/AppContext';
import {NotificationsContext, NotificationType} from '../../../contexts/NotificationsContext';

import WtrPopup from '../WtrPopup/WtrPopup';

import DashboardContext from '../../../contexts/DashboardContext';

import {AppComponentType, ApplicationStatus} from '../../../utils/functions/enums';

import Button from '../../../shared/Button/Button';

import Routes from '../../../setup/routes';

import accountStatusStore from '../../../store/accountStatusStore';

import authStore from '../../../store/authStore';

import UserNotificationsIcon from './components/NotificationsIcon/NotificationsIcon';
import UserNotificationsList from './components/NotificationsList/NotificationsList';
import styles from './Notifications.module.scss';

const Notifications = () => {
    const {notifications, addNotifications} = useContext(NotificationsContext);
    const appContext = useContext(AppContext);
    const dashboardContext = useContext(DashboardContext);
    const {isArabic} = appContext;
    const {t} = useTranslation();
    const history = useHistory();

    const isLiveMode = authStore.use.isLiveMode();
    const isJapanAccount = authStore.use.isJapanAccount();

    const hasLiveAccount = accountStatusStore.use.hasLiveAccount();
    const permissions = accountStatusStore.use.permissions();
    const applicationStatus = accountStatusStore.use.checkApplicationStatus();

    const notificationBell = permissions?.notificationBell;
    const [open, setOpen] = useState(false);

    const shouldDisplayNotifications =
        !isJapanAccount &&
        isLiveMode &&
        hasLiveAccount &&
        applicationStatus !== ApplicationStatus.APPROVED &&
        applicationStatus !== ApplicationStatus.PENDING_REVIEW;

    const handleRedirect = () => {
        setOpen(false);
        dashboardContext.presentComponentType = AppComponentType.OpenAccount;
        history.push(Routes.trader.openAccount, {from: window.location.pathname});
    };
    useEffect(() => {
        if (shouldDisplayNotifications) {
            addNotifications([
                {
                    timestamp: '',
                    type: NotificationType.alert,
                    content: (
                        <div className={styles.containter}>
                            <Trans i18nKey={'wtr:NOTIFICATION_COMPLETE_LIVE_APPLICATION'}/>
                        </div>
                    ),
                    action: (
                        <Button
                            className={styles.completeAppBtn}
                            variant="primary"
                            size="sm"
                            label={t('wtr:COMPLETE')}
                            onClick={handleRedirect}
                        />
                    ),
                },
            ]);
        }
    }, [shouldDisplayNotifications]);

    if (!notificationBell && !isJapanAccount) return null;

    return (
        <>
            <WtrPopup
                on={['click']}
                open={open}
                className={styles.marginRight}
                position={isArabic ? 'bottom right' : 'bottom left'}
                basic
                trigger={
                    <div>
                        <UserNotificationsIcon
                            open={open}
                            messageCount={notifications.length}
                            onClick={() => setOpen((open) => !open)}
                        />
                    </div>
                }
                content={<UserNotificationsList setOpen={setOpen} notifications={notifications}/>}
            />
        </>
    );
};

export default Notifications;
