import {AccountMarketType, TradingInstruments} from '../../../gateways/RfpGateway/rfp.types';

import { GymTradingAccount } from './GymTradingAccount';

export class GymAccountUtils {
	private static defaultLeverage = 0.0025; // Leverage: value:0.0025 code:@"1:400"

	static createAccount(
		emailId: number,
		currency: string,
		deposit: number,
		tradingInstruments: TradingInstruments | undefined,
		accountMarketType: AccountMarketType.CFD | AccountMarketType.SpreadBetting,
		leverage: number = this.defaultLeverage,
	) {

		return new GymTradingAccount(emailId, currency, deposit, leverage, tradingInstruments, accountMarketType);
	}

	static convertEmailToId(email: string) {
		var emailId = 0,
			i,
			chr;
		if (email.length === 0) return emailId;
		for (i = 0; i < email.length; i++) {
			chr = email.charCodeAt(i);
			emailId = (emailId << 5) - emailId + chr;
			emailId |= 0; // Convert to 32bit integer
		}
		return emailId;
	}
}
