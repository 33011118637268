import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import useSubscriptionModal from '../../../../../utils/hooks/useSubscriptionModal';
import { SubscriptionModalReason, SubscriptionStatus } from '../../../../../utils/functions/subscriptionUtils';
import useSubscriptionInfo from '../../../../../utils/hooks/useSubscriptionInfo';

import styles from './AccountMenuPopupSection.module.scss';
// import { faPauseCircle, faPen, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

const SubscriptionOptions = ({ setAccountPopupIsOpen }: { setAccountPopupIsOpen: (a: boolean) => void }) => {
	const { t } = useTranslation();
	const [subscriptionInfo] = useSubscriptionInfo();
	const { setModalStateProps } = useSubscriptionModal();

	const handleManageClick = () => {
		setAccountPopupIsOpen(false);
		setModalStateProps({
			reason: SubscriptionModalReason.ManageSubscription,
			isOpen: true,
		});
	};

	const handleStopClick = () => {
		setAccountPopupIsOpen(false);
		setModalStateProps({
			reason: SubscriptionModalReason.ConfirmStop,
			isOpen: true,
			text: null,
		});
	};

	const handleResumeClick = () => {
		setAccountPopupIsOpen(false);
		setModalStateProps({
			reason: SubscriptionModalReason.ConfirmRestart,
			isOpen: true,
			text: null,
		});
	};

	const handleCancelClick = () => {
		setAccountPopupIsOpen(false);
		setModalStateProps({
			reason: SubscriptionModalReason.ConfirmCancel,
			isOpen: true,
			text: null,
		});
	};

	const hasManageSubscriptionOption = [SubscriptionStatus.Active, SubscriptionStatus.Unpaid].includes(
		subscriptionInfo.status!
	);
	const hasStopSubscriptionOption = subscriptionInfo.status === SubscriptionStatus.Active;
	const hasCancelSubscriptionOption = subscriptionInfo.status === SubscriptionStatus.Active;

	return (
		<div className={styles.section}>
			{hasManageSubscriptionOption && (
				<div className={styles.item} onClick={handleManageClick}>
					<div className={styles.iconContainerWrapper}>
						<div className={styles.iconContainer}>
							<FontAwesomeIcon
								icon={['far', 'pen']}
								className={cn(styles.manageSubscriptionIcon, styles.settingsIcon)}
							/>
						</div>
						<span className={styles.text}>{t('wtr:MANAGE_SUBSCRIPTIONS')}</span>
					</div>
				</div>
			)}
			{hasStopSubscriptionOption && (
				<div className={styles.item} onClick={handleStopClick}>
					<div className={styles.iconContainerWrapper}>
						<div className={styles.iconContainer}>
							<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'pause-circle']} />
						</div>
						<span className={styles.text}>{t('wtr:SUSPEND_SUBSCRIPTION')}</span>
					</div>
				</div>
			)}
			{hasCancelSubscriptionOption && (
				<div className={cn(styles.item, styles.itemCancel)} onClick={handleCancelClick}>
					<div className={styles.iconContainerWrapper}>
						<div className={styles.iconContainer}>
							<FontAwesomeIcon className={styles.settingsIcon} icon={['far', 'times-circle']} />
						</div>
						<span className={styles.text}>{t('wtr:CANCEL_SUBSCRIPTION')}</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default SubscriptionOptions;
