import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../shared/Modal';
import Button from '../../../shared/Button/Button';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import { SubscriptionModalReason } from '../../../utils/functions/subscriptionUtils';

import AppContext from '../../../contexts/AppContext';
import useSubscriptionModal from '../../../utils/hooks/useSubscriptionModal';

import SubscriptionDetails from '../SubscriptionDetails';

import useLogout from '../../../utils/hooks/useLogout';

import useCancelSubscription from '../../../utils/subscriptions/useCancelSubscription';

import useStopSubscription from '../../../utils/subscriptions/useStopSubscription';
import useResumeSubscription from '../../../utils/subscriptions/useResumeSubscription';

import styles from './SubscriptionAlert.module.scss';

interface SubscriptionAlertProps {
	isOpen: boolean;
	reason: SubscriptionModalReason;
	text?: string | null;
	accountId: string;
	onCancel: () => void;
	onConfirm?: () => void;
}
/**
 *
 * @param isOpen if modal is open
 * @param reason subscription-related circumstance that makes modal necessary
 * @param onCancel callback executed on pressing cancel button
 * @param onConfirm callback executed on pressing confirm button
 */

const SubscriptionAlert = ({ isOpen, reason, text, accountId, onCancel, onConfirm }: SubscriptionAlertProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const logOut = useLogout();
	const [hasUserAgreement, setHasUserAgreement] = useState<boolean>(false);

	const { setModalStateProps, resetModalStateProps } = useSubscriptionModal();

	const RESPONSE_TITLE_MAP = useMemo<{ [key: string]: string }>(
		() => ({
			stopped: t('wtr:SUBSCRIPTION_PAUSED_TITLE'),
			notAllowed: t('wtr:OPERATION_NOT_ALLOWED_TITLE'),
			noRecord: t('wtr:OPERATION_NOT_ALLOWED_TITLE'),
			openPositions: t('wtr:OPERATION_NOT_ALLOWED_OPEN_POSITIONS_TITLE'),
			pendingOrders: t('wtr:OPERATION_NOT_ALLOWED_OPEN_ORDERS_TITLE'),
			restarted: t('wtr:SUBSCRIPTION_RESUMED_TITLE'),
			canceled: t('wtr:SUBSCRIPTION_CANCELED_TITLE'),
			remainingBalance: t('wtr:OPERATION_NOT_ALLOWED_REMANING_BALANCE_TITLE'),
		}),
		[]
	);

	const RESPONSE_MESSAGE_MAP = useMemo<{ [key: string]: string }>(
		() => ({
			stopped: t('wtr:SUBSCRIPTION_PAUSED'),
			notAllowed: t('wtr:OPERATION_NOT_ALLOWED'),
			noRecord: t('wtr:OPERATION_NOT_ALLOWED'),
			openPositions: t('wtr:OPERATION_NOT_ALLOWED_OPEN_POSITIONS'),
			pendingOrders: t('wtr:OPERATION_NOT_ALLOWED_OPEN_ORDERS'),
			restarted: t('wtr:SUBSCRIPTION_RESUMED_TITLE'),
			canceled: t('wtr:SUBSCRIPTION_CANCELED'),
			remainingBalance: t('wtr:OPERATION_NOT_ALLOWED_REMANING_BALANCE'),
		}),
		[]
	);

	const STATUS_MESSAGE_MAP = useMemo<Partial<Record<SubscriptionModalReason, string>>>(
		() => ({
			[SubscriptionModalReason.SubscriptionForNewUser]: t('wtr:SUBSCRIPTION_NEW_NEEDED_TEXT'),
			[SubscriptionModalReason.SubscriptionForUnpaidUser]: t('wtr:SUBSCRIPTON_UNPAID_NEEDED_TEXT'),
			[SubscriptionModalReason.Renewal]: t('wtr:SUBSCRIPTION_RENEWAL_NEEDED_MODAL_TEXT'),
			[SubscriptionModalReason.Cancelled]: t('wtr:SUBSCRIPTION_CANCELED_MODAL_TEXT'),
			[SubscriptionModalReason.Terminated]: t('wtr:SUBSCRIPTION_TERMINATED_MODAL_TEXT'),
			[SubscriptionModalReason.ConfirmStop]: t('wtr:SUSPEND_SUBSCRIPTION_MODAL_TEXT'),
			[SubscriptionModalReason.ConfirmRestart]: t('wtr:SUBSCRIPTION_RESUME_CONFIRM_TEXT'),
			[SubscriptionModalReason.ConfirmCancel]: t('wtr:CANCEL_SUBSCRIPTION_MODAL_TEXT'),
			[SubscriptionModalReason.NoSuperiorTiers]: t('wtr:NO_SUPPERIOR_TIERS_MODAL_TEXT'),
			[SubscriptionModalReason.SubscribeResponse]: t('wtr:NEW_SUBSCRIPTION_STATUS_RESPONSE_TEXT'),
			[SubscriptionModalReason.ChangeTierResponse]: t('wtr:TIER_CHANGE_RESPONSE_TEXT'),
			[SubscriptionModalReason.SystemError]: t('wtr:SYSTEM_ERROR_MODAL_TEXT'),
			[SubscriptionModalReason.OrderSizeGlobal]: t('wtr:MAX_ORDER_SIZE_EXCEEDED_MODAL_TEXT'),
			[SubscriptionModalReason.NotAuthorized]: t('wtr:OPERATION_FAILED_NOTAUTHORIZED_MODAL_TEXT'),
			[SubscriptionModalReason.IncorrectParamsForBJP]: t('wtr:INCORRECT_PARAMS_FOR_BJP_MODAL_TEXT'),
			[SubscriptionModalReason.GymNotAccessibleForTier]: t('wtr:UPGRADE_TIER_TO_ACCESS_GYM_TEXT'),
			[SubscriptionModalReason.JapanAccountEmptyApplicationStatus]: t('wtr:OBTAINING_LOGIN_INFORMATION_ACTION_MESSAGE'),
		}),
		[]
	);

	const STATUS_TITLE_MAP = useMemo<Partial<Record<SubscriptionModalReason, string>>>(
		() => ({
			[SubscriptionModalReason.SubscriptionForNewUser]: t('wtr:SUBSCRIPTION_NEW_NEEDED_TITLE'),
			[SubscriptionModalReason.SubscriptionForUnpaidUser]: t('wtr:SUBSCRIPTON_UNPAID_NEEDED_TITLE'),
			[SubscriptionModalReason.Renewal]: t('wtr:SUBSCRIPTION_RENEWAL_NEEDED_MODAL_TITLE'),
			[SubscriptionModalReason.Cancelled]: t('wtr:SUBSCRIPTION_CANCELED_MODAL_TITLE'),
			[SubscriptionModalReason.Terminated]: t('wtr:SUBSCRIPTION_TERMINATED_MODAL_TITLE'),
			[SubscriptionModalReason.ConfirmStop]: t('wtr:SUSPEND_SUBSCRIPTION_MODAL_TITLE'),
			[SubscriptionModalReason.ConfirmRestart]: t('wtr:SUBSCRIPTION_RESUME_CONFIRM_TITLE'),
			[SubscriptionModalReason.ConfirmCancel]: t('wtr:CANCEL_SUBSCRIPTION_MODAL_TITLE'),
			[SubscriptionModalReason.NoSuperiorTiers]: t('wtr:NO_SUPPERIOR_TIERS_MODAL_TITLE'),
			[SubscriptionModalReason.SubscriptionDetails]: t('wtr:SUBSCRIPTION_DETAILS_MODAL_TITLE'),
			[SubscriptionModalReason.SubscribeResponse]: t('wtr:NEW_SUBSCRIPTION_STATUS_RESPONSE_TITLE'),
			[SubscriptionModalReason.ChangeTierResponse]: t('wtr:TIER_CHANGE_RESPONSE_TITLE'),
			[SubscriptionModalReason.SystemError]: t('wtr:SYSTEM_ERROR_MODAL_TITLE'),
			[SubscriptionModalReason.OrderSizeGlobal]: t('wtr:MAX_ORDER_SIZE_EXCEEDED_MODAL_TITLE'),
			[SubscriptionModalReason.NotAuthorized]: t('wtr:OPERATION_FAILED_NOTAUTHORIZED_MODAL_TITLE'),
			[SubscriptionModalReason.IncorrectParamsForBJP]: t('wtr:INCORRECT_PARAMS_FOR_BJP_MODAL_TITLE'),
			[SubscriptionModalReason.GymNotAccessibleForTier]: t('wtr:UPGRADE_TIER_TO_ACCESS_GYM_TITLE'),
			[SubscriptionModalReason.JapanAccountEmptyApplicationStatus]: t(' '),
		}),
		[]
	);

	const [message, setMessage] = useState(STATUS_MESSAGE_MAP[reason]);
	const [title, setTitle] = useState(STATUS_TITLE_MAP[reason] || reason);

	const { mutate: cancelSubscription } = useCancelSubscription(accountId);
	const { mutate: stopSubscription } = useStopSubscription(accountId);
	const { mutate: resumeSubscription } = useResumeSubscription(accountId);

	const handleResumeClick = () => {
		const onSuccess = ({ result }: { result: string }) => {
			setMessage(RESPONSE_MESSAGE_MAP[result]);
			setTitle(RESPONSE_TITLE_MAP[result]);
			setModalStateProps({
				reason: SubscriptionModalReason.RestartSubscriptionResponse,
				isOpen: true,
				text: t(`wtr:SUBSCRIPTION_OP_RESPONSE_${result.toUpperCase()}`),
				instrument: null,
			});
		};

		resumeSubscription({}, { onSuccess });
	};

	const handleConfirm = () => {
		if (reason === SubscriptionModalReason.ConfirmStop) {
			const onSuccess = ({ result }: { result: string }) => {
				setMessage(RESPONSE_MESSAGE_MAP[result]);
				setTitle(RESPONSE_TITLE_MAP[result]);
				setModalStateProps({
					reason: SubscriptionModalReason.StopSubscriptionResponse,
					isOpen: true,
					text: t(`wtr:SUBSCRIPTION_OP_RESPONSE_${result.toUpperCase()}`),
					instrument: null,
				});
			};
			stopSubscription({}, { onSuccess });
		}

		if (reason === SubscriptionModalReason.ConfirmCancel) {
			const onSuccess = ({ result }: { result: string }) => {
				setMessage(RESPONSE_MESSAGE_MAP[result]);
				setTitle(RESPONSE_TITLE_MAP[result]);
				setModalStateProps({
					reason: SubscriptionModalReason.CancelSubscriptionResponse,
					isOpen: true,
					text: t(`wtr:SUBSCRIPTION_OP_RESPONSE_${result.toUpperCase()}`),
					instrument: null,
				});
				//FIXME double check if we need this here
				appContext.canFetchSubscrInfo = true;
			};
			cancelSubscription({}, { onSuccess });
		}

		if (reason === SubscriptionModalReason.ConfirmRestart) {
			handleResumeClick();
		}
	};

	const handleSubscribeClick = (reason: SubscriptionModalReason) => {
		setModalStateProps({
			reason: reason,
			isOpen: true,
			text: null,
			instrument: null,
		});
	};

	const handleResumeConfirm = () => {
		setModalStateProps({ reason: SubscriptionModalReason.ConfirmRestart, isOpen: true });
		setMessage(STATUS_MESSAGE_MAP[SubscriptionModalReason.ConfirmRestart]);
		setTitle(STATUS_TITLE_MAP[SubscriptionModalReason.ConfirmRestart]!);
	};

	const handleLogoutClick = () => {
		resetModalStateProps();
		logOut();
	};

	const handleCancel = () => {
		resetModalStateProps();
		onCancel();
		appContext.canFetchSubscrInfo = true;
	};

	const handleClickAgreementBox = (e: React.ChangeEvent<HTMLInputElement>) => {
		setHasUserAgreement((e.target as HTMLInputElement).checked);
	};

	let buttons = <Button variant="secondary" size="lg" label={t('wtr:CLOSE_TEXT')} onClick={handleCancel} />;

	if (reason === SubscriptionModalReason.SubscriptionForNewUser) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:BTN_LOG_OUT')} onClick={handleLogoutClick} />
				<Button
					variant="primary"
					size="lg"
					label={t('wtr:SUBSCRIBE_NOW')}
					onClick={() => handleSubscribeClick(SubscriptionModalReason.SubscribeFromAlert)}
				/>
			</>
		);
	} else if (reason === SubscriptionModalReason.SubscriptionForUnpaidUser) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:RETURN')} onClick={resetModalStateProps} />
				{/* <Button
					variant="primary"
					size="lg"
					label={t('wtr:SUBSCRIBE_NOW')}
					onClick={() => handleSubscribeClick(SubscriptionModalReason.SubscribeFromAlert)}
				/> */}
			</>
		);
	} else if (reason === SubscriptionModalReason.Terminated) {
		buttons = <Button variant="primary" size="lg" label={t('wtr:LOGOUT')} onClick={handleLogoutClick} />;
	} else if (reason === SubscriptionModalReason.Cancelled) {
		buttons = <Button variant="primary" size="lg" label={t('wtr:LOGOUT')} onClick={handleLogoutClick} />;
	} else if (reason === SubscriptionModalReason.Renewal) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:LOGOUT')} onClick={handleLogoutClick} />
				<Button variant="primary" size="lg" label={t('wtr:RESTART')} onClick={handleResumeConfirm} />
			</>
		);
	} else if (reason === SubscriptionModalReason.SubscriptionDetails) {
		buttons = <Button variant="secondary" size="lg" label={t('wtr:RETURN')} onClick={handleCancel} />;
	}
	if (reason === SubscriptionModalReason.ConfirmStop) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:CANCEL_TEXT')} onClick={handleCancel} />
				<Button variant="primary" size="lg" label={t('wtr:CONFIRM_TEXT')} onClick={handleConfirm} />
			</>
		);
	} else if (reason === SubscriptionModalReason.ConfirmCancel) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:CANCEL_TEXT')} onClick={handleCancel} />
				<Button
					variant="primary"
					size="lg"
					label={t('wtr:CONFIRM_TEXT')}
					disabled={!hasUserAgreement}
					onClick={handleConfirm}
				/>
			</>
		);
	} else if (reason === SubscriptionModalReason.ConfirmRestart) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:CANCEL_TEXT')} onClick={handleCancel} />
				<Button variant="primary" size="lg" label={t('wtr:CONFIRM_TEXT')} onClick={handleConfirm} />
			</>
		);
	} else if (reason === SubscriptionModalReason.GymNotAccessibleForTier) {
		buttons = (
			<>
				<Button variant="secondary" size="lg" label={t('wtr:CANCEL_TEXT')} onClick={handleCancel} />
				<Button
					variant="primary"
					size="lg"
					label={t('wtr:UPGRADE')}
					onClick={() => handleSubscribeClick(SubscriptionModalReason.UpgradeToAccessGym)}
				/>
			</>
		);
	}

	let contentClassName = '';
	if (reason === SubscriptionModalReason.SubscriptionDetails) {
		contentClassName = styles.subscriptionDetailsContent;
	}

	return (
		<Modal show={isOpen} animation centered dialogClassName={styles.modalDialog} contentClassName={contentClassName}>
			<Modal.Header className={styles.modalHeader}>{title}</Modal.Header>
			<Modal.Body className={reason !== SubscriptionModalReason.ConfirmCancel && styles.modalBody}>
				{reason !== SubscriptionModalReason.SubscriptionDetails && message && (
					<p dangerouslySetInnerHTML={{ __html: message }}></p>
				)}
				{reason === SubscriptionModalReason.SubscriptionDetails && <SubscriptionDetails />}
				{text && <p className={styles.detailsText}>{text}</p>}
				{reason === SubscriptionModalReason.ConfirmCancel && (
					<Checkbox
						isChecked={hasUserAgreement}
						onChange={handleClickAgreementBox}
						labelContents={t('wtr:CONFIRM_CANCELLATION')}
						isLabelContentsInteractive
					/>
				)}
			</Modal.Body>
			<Modal.Footer className={styles.modalFooter}>{buttons}</Modal.Footer>
		</Modal>
	);
};

export default SubscriptionAlert;
