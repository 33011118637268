import React, { useContext, useMemo, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { Progress } from 'semantic-ui-react';

import Button from '../../../../../shared/Button';
import AppContext from '../../../../../contexts/AppContext';

import useSubscriptionInfo from '../../../../../utils/hooks/useSubscriptionInfo';
import { totalNumberOfTiers } from '../../../../../setup/subscriptionsConfig';
import useSubscriptionModal from '../../../../../utils/hooks/useSubscriptionModal';
import {
	canSeeSubscriptionDetails,
	getTierDisplayName,
	SubscriptionModalReason,
} from '../../../../../utils/functions/subscriptionUtils';
import { formatNumberWithCommas } from '../../Watchlist/Instrument/formattedQuoteNumber';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import AccountIconAndTier from '../components/AccountIconAndTier';

import styles from './RemainingVolume.module.scss';

interface RemainingVolumeProps {
	baseCurrency?: string;
}

const RemainingVolume: React.FC<RemainingVolumeProps> = ({ baseCurrency }) => {
	const [subscriptionInfo] = useSubscriptionInfo();
	const { setModalStateProps } = useSubscriptionModal();
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const appContext = useContext(AppContext);

	const remainingVolume = subscriptionInfo.remainingVolume
		? formatNumberWithCommas(subscriptionInfo.remainingVolume, 0, appContext.languageSettings)
		: null;

	let iconButton: IconName = 'caret-left';
	if (isOpen) {
		iconButton = 'caret-up';
	} else {
		iconButton = 'caret-down';
	}

	const remainVolPercent =
		subscriptionInfo.remainingVolume && subscriptionInfo.maxVolume
			? Math.round((subscriptionInfo.remainingVolume / subscriptionInfo.maxVolume) * 100)
			: null;

	const colorStatusIndicator = useMemo(() => {
		if (!remainVolPercent && remainVolPercent !== 0) return styles.disabled;

		if (remainVolPercent >= 60) return styles.green;
		if (remainVolPercent >= 20 && remainVolPercent < 60) return styles.orange;
		if (remainVolPercent < 20) return styles.red;
	}, [remainVolPercent]);

	const hasUpgradeButton = subscriptionInfo?.tierNum && subscriptionInfo?.tierNum < totalNumberOfTiers ? true : false;

	const handleShowDetails = () => {
		setModalStateProps({
			reason: SubscriptionModalReason.SubscriptionDetails,
			isOpen: true,
		});
	};

	const handleUpgradeClick = () => {
		setModalStateProps({
			reason: SubscriptionModalReason.UpgradeSubscription,
			isOpen: true,
		});
	};

	return (
		<>
			{appContext.isJapanAccount && (
				<WtrPopup
					on={['click']}
					position="bottom center"
					basic
					onOpen={() => setIsOpen(true)}
					onClose={() => setIsOpen(false)}
					trigger={
						<div className={styles.wrapper} onClick={() => setIsOpen(!isOpen)}>
							<label className={styles.statsLabel}>{`${t('wtr:REMAINING_VOLUME')}:`}</label>
							<div className={styles.valueContainer}>
								<div className={styles.statusIndicator}></div>
								<div className={cn(styles.marginPercent, styles.colorStatusIndicator)}>
									{remainingVolume === '-1' ? '-' : remainingVolume}
								</div>
								<FontAwesomeIcon icon={['fas', iconButton]} className={styles.toggleIcon} />
							</div>
						</div>
					}
					content={
						<div className={styles.popup}>
							<div>
								<AccountIconAndTier outline={true} />
							</div>
							{subscriptionInfo.tier && canSeeSubscriptionDetails(subscriptionInfo.status) && (
								<div className={styles.viewDetailsContainer}>
									<span className={styles.tierName}>{getTierDisplayName(subscriptionInfo.tier)}</span>
									<button className={styles.btnViewDetails} onClick={handleShowDetails}>
										{t('wtr:VIEW_DETAILS')}
									</button>
								</div>
							)}

							<div className={styles.contentContainer}>
								<span>{t('wtr:REMAINING_VOLUME')}:</span>
								<span className={cn(styles.remainingVolume, colorStatusIndicator)}>
									{remainingVolume === '-1' ? '-' : remainingVolume}
								</span>
							</div>

							<div className={cn(styles.progressBarContainer, colorStatusIndicator)}>
								<div className={styles.progressWrapper}>
									<Progress percent={remainVolPercent || undefined} size="tiny" className={styles.progressBar} />
								</div>
							</div>

							<div className={styles.contentContainer}>
								<span>{t('wtr:VOLUME_LIMIT')}:</span>
								<span className={styles.values}>
									{subscriptionInfo.maxVolume &&
										formatNumberWithCommas(
											subscriptionInfo.maxVolume === -1 ? 0 : subscriptionInfo.maxVolume,
											0,
											appContext.languageSettings
										)}
								</span>
							</div>
							<div className={styles.contentContainer}>
								<span>{t('wtr:USED_VOLUME')}:</span>
								<span className={styles.values}>
									{subscriptionInfo.usedVolume &&
										formatNumberWithCommas(subscriptionInfo.usedVolume, 0, appContext.languageSettings)}
								</span>
							</div>

							{hasUpgradeButton && (
								<Button
									className={styles.button}
									variant="primary"
									label={t('wtr:UPGRADE_SUBSCRIPTION')}
									isFullWidth
									onClick={handleUpgradeClick}
								/>
							)}
						</div>
					}
				/>
			)}
		</>
	);
};

export default RemainingVolume;
