import {IconName} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import useMoneyFormatter from '../../../../../utils/hooks/useMoneyFormatter';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import {formatNumberWithCommas} from '../../Watchlist/Instrument/formattedQuoteNumber';

import useObservable from '../../../../../utils/hooks/useObservable';

import {checkIsVolumeUnlimited} from '../../../../../utils/functions/subscriptionUtils';

import authStore, {AuthStore} from '../../../../../store/authStore';

import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';

import {calculateEquity, calculateFreeMargin, calculateMarginLevel} from "../../../../../utils/functions/calculations";

import styles from './AccountPanel.module.scss';
import AccountStats from './AccountStats';
import MarginLevel from './MarginLevel';
import RemainingVolume from './RemainingVolume';
import CurrencyStats from './CurrencyStats';

interface AccountPanelProps {
    isSearchBarOpen: boolean;
}

const AccountPanel = ({isSearchBarOpen}: AccountPanelProps) => {
    const dashboardContext = useContext(DashboardContext);
    const appContext = useContext(AppContext);
    const tt = useShortTranslation('en:');
    const {t} = useTranslation();
    const forceRerender = useForceRerender();

    const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);

    const [isMenuOpen, setIsMenuOpen] = useState(true);

    useObservable(appContext.getPropertyChangeStream('subscriptionInfo'), () => {
        forceRerender();
    });

    const selectedTradingAccount = useSelectedTradingAccount();

    // The variable accountMcLevel is used to determine the margin level for each account.
    // Ideally, this value should be retrieved from the backend service, based on the account settings.
    // However, for the time being, we will hardcode this value as a temporary workaround.
    const accountMcLevel = useMemo(() => appContext.isJapanAccount ? 1.2 : 1.0, [appContext.isJapanAccount]);

    // const selectedAccountStats = tradingAccountStore.use.selectedAccountStats();
    // what follows is potentially one of the worst snippets of code I've written; ever
    // unfortuantely at the moment we don't have a better solution
    // what happens is that every time a tick for an instrument the user has an opened
    // position for the profit/loss is calculated inside websocket.ts using the calculations
    // utils and put on the dashbaordContext.tradingAccount[INDEX].netProfit
    // unfortunately with the observable contexts we are not notified for that change as it relies
    // on some quirks of JS so forceRerender on dashboardCtx.tradingaAccount change won't work
    // for the same reason we chan't use useMemo for selectedTradingAccount - we always need a fresh
    // value as the new data is inside the array;
    //
    // the solutions is: forceRerender every 1.5 seconds (this was used in the previous AccountPanel as well),
    // triggering the setTimeout on mount
    //
    // this is one of the first things that need to go once we have proper AccountContext :)
    //
    // self-invoking forceRerender function
    const rerender = () => {
        forceRerender();
        setTimeout(rerender, 1500);
    };
    // start the force-rerendering
    useEffect(rerender, []);

    const formatAsMoney = useMoneyFormatter(selectedTradingAccount);

    dashboardContext.accountValues.balance = selectedTradingAccount?.balance || 0;
    dashboardContext.accountValues.margin = selectedTradingAccount?.usedMargin || 0;

    const accountProfitLoss = selectedTradingAccount?.netProfit || 0;
    const calculatedEquity = calculateEquity({
        balance: dashboardContext.accountValues?.balance,
        profitLoss: accountProfitLoss
    });
    const calculatedFreeMargin = parseFloat(calculateFreeMargin({
        equity: calculatedEquity,
        accountMcLevel,
        usedMargin: dashboardContext.accountValues.margin
    }).toFixed(2));
    const calculatedMarginLevel = calculateMarginLevel({
        equity: calculatedEquity,
        usedMargin: dashboardContext.accountValues.margin
    })


    dashboardContext.accountValues.pl = +accountProfitLoss.toFixed(2);
    dashboardContext.accountValues.equity = parseFloat(calculatedEquity.toFixed(2));
    dashboardContext.accountValues.freeMargin = calculatedFreeMargin;
    dashboardContext.accountValues.marginLevel = parseFloat(calculatedMarginLevel.toFixed(2));

    if (selectedTradingAccount) {
        selectedTradingAccount.freeMargin = calculatedFreeMargin;
    }

    useEffect(() => {
        const isAccountPanelOpen = localStorage.getItem('isAccountPanelOpen');

        if (isAccountPanelOpen === 'true') {
            setIsMenuOpen(true);
        } else if (isAccountPanelOpen === 'false') {
            setIsMenuOpen(false);
        } else {
            localStorage.setItem('isAccountPanelOpen', 'true');
            setIsMenuOpen(true);
        }
    }, []);

    const balance = formatAsMoney(dashboardContext.accountValues.balance);
    const profitLoss = formatAsMoney(dashboardContext.accountValues.pl);
    const equity = formatAsMoney(dashboardContext.accountValues.equity);
    const marginValue = formatAsMoney(dashboardContext.accountValues.margin);
    const availableMargin = formatAsMoney(dashboardContext.accountValues.freeMargin);

    // TODO: GYM
    const marginLevel = formatNumberWithCommas(
        isNaN(dashboardContext.accountValues.marginLevel) ? 0 : dashboardContext.accountValues.marginLevel,
        2,
        appContext.languageSettings
    );

    const handleToggleAccountPanel = () => {
        localStorage.setItem('isAccountPanelOpen', (!isMenuOpen).toString());
        setIsMenuOpen(!isMenuOpen);
    };

    const mustShowRemainingVolume =
        appContext.isJapanAccount &&
        isLiveMode &&
        appContext.subscriptionInfo?.maxVolume &&
        !checkIsVolumeUnlimited(appContext.subscriptionInfo);

    let triggerAccountBalance = window.innerWidth <= 1680 ? isMenuOpen && !isSearchBarOpen : isMenuOpen;
    let iconButton: IconName = isMenuOpen && triggerAccountBalance ? 'caret-right' : 'caret-left';

    if (appContext.isArabic) {
        iconButton = isMenuOpen && triggerAccountBalance ? 'caret-left' : 'caret-right';
    }

    return (
        <div className={styles.container}>
            <div
                className={styles.accountPanelButton}
                onClick={handleToggleAccountPanel}
            >
                <FontAwesomeIcon icon={['fas', iconButton]} className={styles.buttonToggleIcon}/>
            </div>

            {triggerAccountBalance && (
                <>
                    <AccountStats
                        label={t('wtr:WTR_CASH_BALANCE')}
                        value={balance}
                        hideDivider={!appContext.isArabic}
                        dividerSymbol="+"
                    />

                    <AccountStats
                        label={tt('PROFIT_LOSS')}
                        value={profitLoss}
                        dividerSymbol={appContext.isArabic ? '=' : '+'}
                        valueColoring
                    />

                    <div className={styles.equityContainerWrapper}>
                        <AccountStats label={tt('EQUITY')} value={equity} hideDivider={appContext.isArabic}
                                      dividerSymbol="="/>
                    </div>

                    <MarginLevel
                        formattedValues={{
                            margin: marginValue,
                            marginPercent: `${marginLevel}%`,
                            availableMargin: availableMargin
                        }}
                        marginLevel={dashboardContext.accountValues.marginLevel}
                        equity={dashboardContext.accountValues.equity}
                        margin={dashboardContext.accountValues.margin}
                        isGymPresenter={false}
                    />

                    {mustShowRemainingVolume && <RemainingVolume baseCurrency={selectedTradingAccount?.baseCurrency}/>}
                </>
            )}
            <CurrencyStats/>
        </div>
    );
};

export default AccountPanel;
