import {useContext} from 'react';

import DashboardContext from '../../contexts/DashboardContext';
import tradingAccountStore from '../../store/tradingAccountStore';

const useSelectedTradingAccount = () => {
    const {tradingAccount} = useContext(DashboardContext);
    const selected = tradingAccountStore.use.selected();

    return tradingAccount[selected];
};

export default useSelectedTradingAccount;
